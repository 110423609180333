import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { createEditor, Editor, Transforms, Element as SlateElement } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { CodeIcon } from '@heroicons/react/solid';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const iconCollection = [
  {
    name: 'bold',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M321.1 242.4C340.1 220.1 352 191.6 352 160c0-70.59-57.42-128-128-128L32 32.01c-17.67 0-32 14.31-32 32s14.33 32 32 32h16v320H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h224c70.58 0 128-57.41 128-128C384 305.3 358.6 264.8 321.1 242.4zM112 96.01H224c35.3 0 64 28.72 64 64s-28.7 64-64 64H112V96.01zM256 416H112v-128H256c35.3 0 64 28.71 64 63.1S291.3 416 256 416z"/>
  </svg>
  },
  {
    name: 'italic',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    <path d="M384 64.01c0 17.69-14.31 32-32 32h-58.67l-133.3 320H224c17.69 0 32 14.31 32 32s-14.31 32-32 32H32c-17.69 0-32-14.31-32-32s14.31-32 32-32h58.67l133.3-320H160c-17.69 0-32-14.31-32-32s14.31-32 32-32h192C369.7 32.01 384 46.33 384 64.01z"/>
  </svg>
  },
  {
    name: 'underline',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M416 448H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h384c17.69 0 32-14.31 32-32S433.7 448 416 448zM48 64.01H64v160c0 88.22 71.78 159.1 160 159.1s160-71.78 160-159.1v-160h16c17.69 0 32-14.32 32-32s-14.31-31.1-32-31.1l-96-.0049c-17.69 0-32 14.32-32 32s14.31 32 32 32H320v160c0 52.94-43.06 95.1-96 95.1S128 276.1 128 224v-160h16c17.69 0 32-14.31 32-32s-14.31-32-32-32l-96 .0049c-17.69 0-32 14.31-32 31.1S30.31 64.01 48 64.01z"/>
  </svg>
  },
  {
    name: 'code',
    icon: <CodeIcon className='h-5 fill-inherit' />
  },
  {
    name: 'heading-one',
    icon: <><svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path d="M448 448c0 17.69-14.33 32-32 32h-96c-17.67 0-32-14.31-32-32s14.33-32 32-32h16v-144h-224v144H128c17.67 0 32 14.31 32 32s-14.33 32-32 32H32c-17.67 0-32-14.31-32-32s14.33-32 32-32h16v-320H32c-17.67 0-32-14.31-32-32s14.33-32 32-32h96c17.67 0 32 14.31 32 32s-14.33 32-32 32H112v112h224v-112H320c-17.67 0-32-14.31-32-32s14.33-32 32-32h96c17.67 0 32 14.31 32 32s-14.33 32-32 32h-16v320H416C433.7 416 448 430.3 448 448z" />
    </svg><svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
        <path d="M256 448c0 17.67-14.33 32-32 32H32c-17.67 0-32-14.33-32-32s14.33-32 32-32h64V123.8L49.75 154.6C35.02 164.5 15.19 160.4 5.375 145.8C-4.422 131.1-.4531 111.2 14.25 101.4l96-64c9.828-6.547 22.45-7.187 32.84-1.594C153.5 41.37 160 52.22 160 64.01v352h64C241.7 416 256 430.3 256 448z" />
      </svg></>
  },
  {
    name: 'heading-two',
    icon: <><svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M448 448c0 17.69-14.33 32-32 32h-96c-17.67 0-32-14.31-32-32s14.33-32 32-32h16v-144h-224v144H128c17.67 0 32 14.31 32 32s-14.33 32-32 32H32c-17.67 0-32-14.31-32-32s14.33-32 32-32h16v-320H32c-17.67 0-32-14.31-32-32s14.33-32 32-32h96c17.67 0 32 14.31 32 32s-14.33 32-32 32H112v112h224v-112H320c-17.67 0-32-14.31-32-32s14.33-32 32-32h96c17.67 0 32 14.31 32 32s-14.33 32-32 32h-16v320H416C433.7 416 448 430.3 448 448z"/>
  </svg><svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path d="M320 448c0 17.67-14.33 32-32 32H32c-13.08 0-24.83-7.953-29.7-20.09c-4.859-12.12-1.859-26 7.594-35.03l193.6-185.1c31.36-30.17 33.95-80 5.812-113.4c-14.91-17.69-35.86-28.12-58.97-29.38C127.4 95.83 105.3 103.9 88.53 119.9L53.52 151.7c-13.08 11.91-33.33 10.89-45.2-2.172C-3.563 136.5-2.594 116.2 10.48 104.3l34.45-31.3c28.67-27.34 68.39-42.11 108.9-39.88c40.33 2.188 78.39 21.16 104.4 52.03c49.8 59.05 45.2 147.3-10.45 200.8l-136 130H288C305.7 416 320 430.3 320 448z"/>
  </svg></>
  },
  {
    name: 'block-quote',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M96 96C42.98 96 0 138.1 0 192s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192C192 138.1 149 96 96 96zM448 192c0-53.02-42.98-96-96-96s-96 42.98-96 96s42.98 96 96 96c11.28 0 21.95-2.305 32-5.879V288c0 35.3-28.7 64-64 64c-17.67 0-32 14.33-32 32s14.33 32 32 32c70.58 0 128-57.42 128-128V192z"/>
  </svg>
  },
  {
    name: 'numbered-list',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path d="M55.1 56.04C55.1 42.78 66.74 32.04 79.1 32.04H111.1C125.3 32.04 135.1 42.78 135.1 56.04V176H151.1C165.3 176 175.1 186.8 175.1 200C175.1 213.3 165.3 224 151.1 224H71.1C58.74 224 47.1 213.3 47.1 200C47.1 186.8 58.74 176 71.1 176H87.1V80.04H79.1C66.74 80.04 55.1 69.29 55.1 56.04V56.04zM118.7 341.2C112.1 333.8 100.4 334.3 94.65 342.4L83.53 357.9C75.83 368.7 60.84 371.2 50.05 363.5C39.26 355.8 36.77 340.8 44.47 330.1L55.59 314.5C79.33 281.2 127.9 278.8 154.8 309.6C176.1 333.1 175.6 370.5 153.7 394.3L118.8 432H152C165.3 432 176 442.7 176 456C176 469.3 165.3 480 152 480H64C54.47 480 45.84 474.4 42.02 465.6C38.19 456.9 39.9 446.7 46.36 439.7L118.4 361.7C123.7 355.9 123.8 347.1 118.7 341.2L118.7 341.2zM512 64C529.7 64 544 78.33 544 96C544 113.7 529.7 128 512 128H256C238.3 128 224 113.7 224 96C224 78.33 238.3 64 256 64H512zM512 224C529.7 224 544 238.3 544 256C544 273.7 529.7 288 512 288H256C238.3 288 224 273.7 224 256C224 238.3 238.3 224 256 224H512zM512 384C529.7 384 544 398.3 544 416C544 433.7 529.7 448 512 448H256C238.3 448 224 433.7 224 416C224 398.3 238.3 384 256 384H512z"/>
  </svg>
  },
  {
    name: 'bulleted-list',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M16 96C16 69.49 37.49 48 64 48C90.51 48 112 69.49 112 96C112 122.5 90.51 144 64 144C37.49 144 16 122.5 16 96zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 416C16 389.5 37.49 368 64 368C90.51 368 112 389.5 112 416C112 442.5 90.51 464 64 464C37.49 464 16 442.5 16 416zM112 256C112 282.5 90.51 304 64 304C37.49 304 16 282.5 16 256C16 229.5 37.49 208 64 208C90.51 208 112 229.5 112 256z"/>
  </svg>
  },
  {
    name: 'left',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M256 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H256C273.7 32 288 46.33 288 64C288 81.67 273.7 96 256 96zM256 352H32C14.33 352 0 337.7 0 320C0 302.3 14.33 288 32 288H256C273.7 288 288 302.3 288 320C288 337.7 273.7 352 256 352zM0 192C0 174.3 14.33 160 32 160H416C433.7 160 448 174.3 448 192C448 209.7 433.7 224 416 224H32C14.33 224 0 209.7 0 192zM416 480H32C14.33 480 0 465.7 0 448C0 430.3 14.33 416 32 416H416C433.7 416 448 430.3 448 448C448 465.7 433.7 480 416 480z"/>
  </svg>
  },
  {
    name: 'center',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M320 96H128C110.3 96 96 81.67 96 64C96 46.33 110.3 32 128 32H320C337.7 32 352 46.33 352 64C352 81.67 337.7 96 320 96zM416 224H32C14.33 224 0 209.7 0 192C0 174.3 14.33 160 32 160H416C433.7 160 448 174.3 448 192C448 209.7 433.7 224 416 224zM0 448C0 430.3 14.33 416 32 416H416C433.7 416 448 430.3 448 448C448 465.7 433.7 480 416 480H32C14.33 480 0 465.7 0 448zM320 352H128C110.3 352 96 337.7 96 320C96 302.3 110.3 288 128 288H320C337.7 288 352 302.3 352 320C352 337.7 337.7 352 320 352z"/>
  </svg>
  },
  {
    name: 'right',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M416 96H192C174.3 96 160 81.67 160 64C160 46.33 174.3 32 192 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96zM416 352H192C174.3 352 160 337.7 160 320C160 302.3 174.3 288 192 288H416C433.7 288 448 302.3 448 320C448 337.7 433.7 352 416 352zM0 192C0 174.3 14.33 160 32 160H416C433.7 160 448 174.3 448 192C448 209.7 433.7 224 416 224H32C14.33 224 0 209.7 0 192zM416 480H32C14.33 480 0 465.7 0 448C0 430.3 14.33 416 32 416H416C433.7 416 448 430.3 448 448C448 465.7 433.7 480 416 480z"/>
  </svg>
  },
  {
    name: 'justify',
    icon: <svg className='h-4 fill-inherit' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96zM416 352H32C14.33 352 0 337.7 0 320C0 302.3 14.33 288 32 288H416C433.7 288 448 302.3 448 320C448 337.7 433.7 352 416 352zM0 192C0 174.3 14.33 160 32 160H416C433.7 160 448 174.3 448 192C448 209.7 433.7 224 416 224H32C14.33 224 0 209.7 0 192zM416 480H32C14.33 480 0 465.7 0 448C0 430.3 14.33 416 32 416H416C433.7 416 448 430.3 448 448C448 465.7 433.7 480 416 480z"/>
  </svg>
  },
]

const TextEditorInput = ({defaultValue = null, disabled= false, handleDescripcion, placeholder = 'Describe tu petición...'}) => {
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const [editor] = useState(() => withReact(createEditor()));

    const initialValue = useMemo(
      () => 
          defaultValue ? JSON.parse(defaultValue) : [
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
        ],
    [defaultValue]);

  useEffect(() => {
    if (defaultValue === '') {
      clearEditor(editor);
    }
  }, [defaultValue, editor])

    editor.children = initialValue// <--- This line solves a bug that Slate Js editor has v > 0.65.2 
    return (
        <Slate 
            editor={editor} 
            value={initialValue}
            onChange={value => {
                const isAstChange = editor.operations.some(
                  op => 'set_selection' !== op.type
                )
                if (isAstChange) {
                  // Save the value to Local Storage.
                  const content = JSON.stringify(value);
                  //localStorage.setItem('content', content);
                  handleDescripcion(content);
                }
              }}>
            {!disabled && <>
            <div className='flex flex-wrap w-full gap-1'>
              <MarkButton format="bold" title="Negrita" />
              <MarkButton format="italic" title="Itálica" />
              <MarkButton format="underline" title="Subrayar" />
              <MarkButton format="code" title="Bloque de Código" />
              <BlockButton format="heading-one" title="Encabezado 1" flex={true} />
              <BlockButton format="heading-two" title="Encabezado 2" flex={true} />
              <BlockButton format="block-quote" title="Citar" />
              <BlockButton format="numbered-list" title="Lista Enumerada" />
              <BlockButton format="bulleted-list" title="Lista No Enumerada" />
              <BlockButton format="left" title="Alinear a la izquierda" />
              <BlockButton format="center" title="Alinear al centro" />
              <BlockButton format="right" title="Alinear a la derecha" />
              <BlockButton format="justify" title="Justificar" />
            </div>
            <hr className='mt-4' />
            </>}
            <Editable
                readOnly={disabled}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder}
                spellCheck
                className='text-gray-800 py-4 px-2 mb-8'
                onKeyDown={event => {
                    for (const hotkey in HOTKEYS) {
                      if (isHotkey(hotkey, event)) {
                        event.preventDefault()
                        const mark = HOTKEYS[hotkey]
                        toggleMark(editor, mark)
                      }
                    }
                }}
            />
        </Slate>
    )
}

const clearEditor = (editor) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  });
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
  
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })
    let newProperties = SlateElement;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      }
    } else {
      newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
      }
    }
    Transforms.setNodes(editor, newProperties)
  
    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }
  
  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
  
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }
  
  const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
  
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    )
  
    return !!match
  }
  
  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }
  
  const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote className='border-l-2 pl-[10px] italic text-gray-400 font-normal' style={style} {...attributes}>
            {children}
          </blockquote>
        )
      case 'bulleted-list':
        return (
          <ul className='list-disc' style={style} {...attributes}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <h1 className='text-[32px] font-extrabold' style={style} {...attributes}>
            {children}
          </h1>
        )
      case 'heading-two':
        return (
          <h2 className='text-2xl font-extrabold' style={style} {...attributes}>
            {children}
          </h2>
        )
      case 'list-item':
        return (
          <li className='list-item ml-8' style={style} {...attributes}>
            {children}
          </li>
        )
      case 'numbered-list':
        return (
          <ol className='list-decimal' style={style} {...attributes}>
            {children}
          </ol>
        )
      default:
        return (
          <p style={style} {...attributes}>
            {children}
          </p>
        )
    }
  }
  
  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
  
    if (leaf.code) {
      children = <code>{children}</code>
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>
    }
  
    if (leaf.underline) {
      children = <u>{children}</u>
    }
  
    return <span {...attributes}>{children}</span>
  }
  
  const BlockButton = ({ format, title, flex = false }) => {
    const editor = useSlate();
    let active = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    return (
      <button type='button' title={title} className={`${flex && 'inline-flex items-center gap-1'} p-2 rounded ${active ? 'fill-accent-2 bg-accent-2/40 hover:bg-accent-2/20' : 'bg-gray-100 fill-gray-600 hover:bg-gray-300'}`}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      >
      {iconCollection.find(i => i.name === format).icon}
      </button>
    )
  }
  
  const MarkButton = ({ format, title }) => {
    const editor = useSlate();
    let active = isMarkActive(editor, format);
    return (
      <button type='button' title={title} className={`p-2 rounded ${active ? 'fill-accent-2 bg-accent-2/40 hover:bg-accent-2/20' : 'bg-gray-100 fill-gray-600 hover:bg-gray-300'}`}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
      >
      {iconCollection.find(i => i.name === format).icon}
      </button>
    )
  }

export default TextEditorInput