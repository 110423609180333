import { LockClosedIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import AppUrl from '../../RestAPI/AppUrl';

class ActivacionPasoUno extends Component {

    constructor(props) {
        super(props);
        this.state = {
            foto_perfil: undefined,
            password: '',
            security_result: '',
            security_color: '#9A54A7',
            errors: [{
                password: '', password_confirmation: '', foto_perfil: ''
            }]
        }
    }

    handleFotoPerfil = () => {
        const input = document.getElementById('foto_perfil');
        input.click();
    }

    handleChange = (e) => {
        this.setState({foto_perfil: e.target.files[0]});
        this.props.handleFotoPerfil(e.target.files[0]);
    }

    checkPasswordSecurity = (password) => {
        let res_text = '';
        const numbersOnly = /^\d+$/;
        const lowercaseOnly = /^[a-z]*$/;
        const upperAndLowercase = /^[A-z]*$/;
        const numbersUpperAndLowercase = /^[A-Za-z0-9]*$/;
        // eslint-disable-next-line
        const specialChars = /^[A-Za-z0-9]+[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        /* PURPLE STATE */
        if ((password.length < 11 && numbersOnly.test(password)) || (password.length < 8 && lowercaseOnly.test(password)) || 
        (password.length < 7 && upperAndLowercase.test(password)) || password.length < 6) {
            this.setState({security_result: 'Instantáneamente', security_color: '#9A54A7'});
        }
        /* RED STATE */ 
        else if(password.length < 16 && numbersOnly.test(password)) {
            res_text = 
                password.length === 15 ? '6 horas' : 
                password.length === 14 ? '41 minutos' : 
                password.length === 13 ? '4 minutos' : 
                password.length === 12 ? '25 segundos' : 
                password.length === 11 ? '2 segundos' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#EE0033'
            });
        } else if(password.length < 11 && lowercaseOnly.test(password)) {
            res_text = 
                password.length === 10 ? '58 minutos' : 
                password.length === 9 ? '2 minutos' : 
                password.length === 8 ? '5 segundos' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#EE0033'
            });
        } else if(password.length < 10 && upperAndLowercase.test(password)) {
            res_text = 
                password.length === 9 ? '19 horas' : 
                password.length === 8 ? '22 minutos' : 
                password.length === 7 ? '25 segundos' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#EE0033'
            });
        } else if(password.length < 9 && numbersUpperAndLowercase.test(password)) {
            res_text = 
                password.length === 8 ? '1 hora' : 
                password.length === 7 ? '1 minuto' : 
                password.length === 6 ? '1 segundo' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#EE0033'
            });
        } else if(password.length < 9 && specialChars.test(password)) {
            res_text = 
                password.length === 8 ? '8 horas' : 
                password.length === 7 ? '6 minutos' : 
                password.length === 6 ? '5 segundos' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#EE0033'
            });
        }
        /* ORANGE STATE */
        else if (password.length < 14 && lowercaseOnly.test(password)) {
            res_text = 
                password.length === 11 ? '1 día' : 
                password.length === 12 ? '3 semanas' : 
                password.length === 13 ? '1 año' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#FF8600'
            });
        } else if (password.length < 12 && upperAndLowercase.test(password)) {
            res_text = 
                password.length === 10 ? '1 mes' : 
                password.length === 11 ? '5 años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#FF8600'
            });
        } else if (password.length < 11 && numbersUpperAndLowercase.test(password)) {
            res_text = 
                password.length === 9 ? '3 días' : 
                password.length === 10 ? '7 meses' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#FF8600'
            });
        } else if (password.length < 11 && specialChars.test(password)) {
            res_text = 
                password.length === 9 ? '3 semanas' : 
                password.length === 10 ? '5 años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#FF8600'
            });
        } else if (numbersOnly.test(password)) {
            res_text = 
                password.length === 16 ? '2 días' : 
                password.length === 17 ? '4 semanas' : 
                password.length === 18 ? '9 meses' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#FF8600'
            });
        }
        /* YELLOW STATES */
        else if (password.length < 18 && lowercaseOnly.test(password)) {
            res_text = 
                password.length === 14 ? '51 años' : 
                password.length === 15 ? '1k años' : 
                password.length === 16 ? '34k años' :
                password.length === 17 ? '800k años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#F9B200'
            });
        } else if (password.length < 15 && upperAndLowercase.test(password)) {
            res_text = 
                password.length === 12 ? '300 años' : 
                password.length === 13 ? '16k años' : 
                password.length === 14 ? '800k años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#F9B200'
            });
        } else if (password.length < 14 && numbersUpperAndLowercase.test(password)) {
            res_text = 
                password.length === 11 ? '41 años' : 
                password.length === 12 ? '2k años' : 
                password.length === 13 ? '100k años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#F9B200'
            });
        } else if (password.length < 13 && specialChars.test(password)) {
            res_text = 
                password.length === 11 ? '400 años' : 
                password.length === 12 ? '34k años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#F9B200'
            });
        }
        /* GREEN STATES */
        else if (password.length > 17 && lowercaseOnly.test(password)) {
            res_text = '23m años';
            this.setState({
                security_result: res_text, 
                security_color: '#00AD46'
            });
        } else if (password.length > 14 && upperAndLowercase.test(password)) {
            res_text = 
                password.length === 15 ? '43m años' : 
                password.length === 16 ? '2bn años' : 
                password.length === 17 ? '100bn años' : 
                password.length >= 18 ? '6tn años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#00AD46'
            });
        } else if (password.length > 13 && numbersUpperAndLowercase.test(password)) {
            res_text = 
                password.length === 14 ? '9m años' : 
                password.length === 15 ? '600m años' : 
                password.length === 16 ? '37bn años' : 
                password.length === 17 ? '2tn años' : 
                password.length >= 18 ? '100tn años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#00AD46'
            });
        } else if (password.length > 12 && specialChars.test(password)) {
            res_text = 
                password.length === 13 ? '2m años' : 
                password.length === 14 ? '200m años' : 
                password.length === 15 ? '15bn años' : 
                password.length === 16 ? '1tn años' : 
                password.length === 17 ? '93tn años' : 
                password.length >= 18 ? '7qd años' : '';
            this.setState({
                security_result: res_text, 
                security_color: '#00AD46'
            });
        }
        this.setState({password: password});
        this.props.handlePassword(password);
    }

    render() {
        return (
            <div className='max-w-2xl mx-auto bg-white rounded-lg shadow-lg px-8 py-6'>
                <h1 className='text-3xl text-slate-800 font-bold mb-6'>Hola {this.props.nombre},</h1>
                <p className='text-slate-600 font-semibold mb-6'>Estas por activar tu cuenta en el nuevo <span className='font-bold text-slate-800'>Sistema {process.env.REACT_APP_CLIENT_SHORT_NAME}</span>. Solo necesitamos confirmar unos breves detalles de tu cuenta para que puedas comenzar a usarla.</p>
                <p className='text-xl text-slate-800 font-semibold mb-6'>¡Comencemos!</p>
                <hr />
                <div className='p-6 space-y-6'>
                    <h2 className='text-xl tracking-[-.01em] text-slate-800 font-bold mb-6'>Foto de Perfil <span className='italic text-base text-slate-600 font-normal'>(Opcional)</span></h2>
                    {/* Picture */}
                    <section>
                        <div className='flex items-center'>
                            <div className='mr-4'>
                                <img className='w-20 h-20 rounded-full' src={
                                    (this.props.foto_perfil === undefined && this.state.foto_perfil === undefined) ? AppUrl.ProfilePics + 'default_demo.png' : 
                                    this.state.foto_perfil !== undefined ? URL.createObjectURL(this.state.foto_perfil) :
                                    this.props.foto_perfil !== undefined ? AppUrl.ProfilePics + this.props.foto_perfil : null
                                    } alt="Foto Perfil" />
                            </div>
                            <button type='button' onClick={this.handleFotoPerfil} className='rounded-md px-2 py-1.5 bg-accent-1 hover:bg-accent-2 text-white text-sm font-medium'>Cambiar</button>
                            <input id='foto_perfil' onChange={this.handleChange} name='foto_perfil' type='file' className="hidden" accept='.jpg,.jpeg,.png,.gif,.bmp,.webp' />
                        </div>
                        <span className='text-sm text-red-500'>{this.state.errors.foto_perfil}</span>
                    </section>
                    {/* Password */}
                    <section className='md:w-11/12'>
                        <h3 className='text-xl tracking-[-.01em] text-slate-800 font-bold mb-1'>Crear Contraseña</h3>
                        <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                            <div className='md:w-1/2'>
                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                    <div className="w-[12%] pt-2 flex justify-center">
                                        <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <input onChange={(e) => this.checkPasswordSecurity(e.target.value)} id='txtNewPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                        <label htmlFor="txtNewPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nueva Contraseña</label>
                                    </div>
                                </div>
                                <span className='text-sm text-red-500'>{this.state.errors.password}</span>
                            </div>
                            <div className='md:w-1/2 md:-translate-y-8 -translate-y-0'>
                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                    <div className="w-[12%] pt-2 flex justify-center">
                                        <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <input onChange={(e) => this.setState({password_confirmation: e.target.value})} id='txtConfirmPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                        <label htmlFor="txtConfirmPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Confirmar Contraseña</label>
                                    </div>
                                </div>
                                <span className='text-sm text-red-500'>{this.state.errors.password}</span>
                            </div>
                        </div>
                        <span className='text-sm' hidden={this.state.password === ''}>Tiempo que le tomaria a un hacker romper tu contraseña:</span>
                        <div hidden={this.state.password === ''} className={`font-semibold mx-auto text-white ${this.state.password !== '' && 'flex'} w-full justify-center rounded`} style={{backgroundColor: this.state.security_color}}>{this.state.security_result}</div>
                        <span className='text-xs text-red-500' hidden={this.state.password.length >= 8 || this.state.password === ''}>*Crea una contraseña con mínimo 8 caracteres</span>
                    </section>
                </div>
                <hr  className='mb-8'/>
                <div className='flex items-center justify-between'>
                    <button type='button' disabled={this.state.password !== this.state.password_confirmation || this.state.password.length < 8} onClick={() => this.props.handleStep(2)} className='disabled:bg-accent-1/70 px-3 py-2 bg-accent-1 hover:bg-accent-2 text-white font-semibold ml-auto rounded-md'>Siguiente</button>
                </div>
            </div>
        );
    }
}

export default ActivacionPasoUno;