import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import OriginalLogoFull from '../../resources/images/Logo404x394.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
var CryptoJS = require("crypto-js");

class Login extends Component {

    state = { email: '', password: '', statusResult: 0, processingRequest: false, firstScreen: '' }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed
        this.setState({ processingRequest: true});

        const data = {
            email: this.state.email,
            password: this.state.password
        }
        RestClient.PostRequest(AppUrl.Login, data).then((result)=>{
            this.setState({ statusResult: result.status, processingRequest: false });
            if (result.status === 200) {
                toast.success(result.message);
                localStorage.setItem('token', result.token);
                var accessos = result.accessos.sort((a, b) => { return a.modulo - b.modulo });
                localStorage.setItem('usersAccess', CryptoJS.AES.encrypt(JSON.stringify(result.accessos), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu').toString());
                this.setState({ firstScreen: '/' + (accessos[0].modulo_name === 'dashboard' ? '' : accessos[0].modulo_name) })
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.statusResult === 200) {
            return <Navigate to={this.state.firstScreen} />
        }
        return (
            <div className='w-full py-6 z-20'>
                <h1 className="hidden lg:contents">
                    <span className='text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-accent-2 via-accent-2 to-accent-1'>¡Bienvenido!</span>
                </h1>
                <h1 className="lg:hidden my-auto">
                    <div className='flex justify-center items-center px-4'>
                        <img className="w-auto sm:h-44 h-28 inline-flex mt-8 mb-4" src={OriginalLogoFull} alt="Logo" />
                        <p className="sm:text-7xl text-5xl font-bold text-center text-white">TaskFlow360</p>
                    </div>
                    <p className="uppercase text-xl font-bold text-center text-white">The perfect flow for your business tasks</p>
                    <hr className='sm:mx-32 mx-16 my-3' />
                </h1>
                <p className="lg:text-slate-500 text-slate-200 font-semibold mb-6">Ingresa tu correo y contraseña para acceder</p>
                {/* :Login Form */}
                <form onSubmit={this.onFormSubmit} className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto">
                    {/* ::Email */}
                    <div className="flex items-center border-2 border-gray-300 my-8 py-2 px-3 rounded-2xl mb-4 lg:focus-within:border-accent-1 focus-within:border-accent-2 bg-white group">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                        </svg>
                        <input onChange={(e) => this.setState({ email: e.target.value })} className="w-full pl-2 outline-none border-none bg-transparent font-bold text-accent-2 lg:text-accent-1 placeholder:text-gray-400 placeholder:font-normal peer" type="email" name="email" id="email" placeholder="Correo Electronico" required />
                    </div>
                    {/* ::Password */}
                    <div className="flex items-center border-2 border-gray-300 my-8 py-2 px-3 rounded-2xl lg:focus-within:border-accent-1 focus-within:border-accent-2 bg-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                        </svg>
                        <input onChange={(e) => this.setState({ password: e.target.value })} className="w-full pl-2 outline-none border-none bg-transparent font-bold text-accent-2 lg:text-accent-1 placeholder:text-gray-400 placeholder:font-normal" type="password" name="password" id="password" placeholder="Contraseña" required />
                    </div>
                    {/* ::Forget Password */}
                    <div className="text-right">
                        <ReactTooltip className='tooltip-default' anchorId='btnForgotPassword' content='Función Proximamente' place="top" />
                        <button id='btnForgotPassword' type='button' className='lg:text-slate-500 text-slate-200 font-semibold hover:underline hover:text-primary focus:outline focus:outline-white lg:focus:outline-accent-2'>¿Olvidaste tu contraseña?</button>
                    </div>
                    {/* ::Submit Button */}
                    <div className="px-4 pb-2 pt-4">
                        <button hidden={!this.state.processingRequest} type='button' disabled className={`${this.state.processingRequest && 'flex'} w-full justify-center p-4 rounded-full text-white bg-gradient-to-b from-accent-2  to-accent-1 focus:outline-none`}>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button type='submit' hidden={this.state.processingRequest} className="uppercase w-full p-4 text-lg rounded-full text-white bg-gradient-to-b from-accent-2  to-accent-1 focus:outline-none focus:shadow-xl focus:font-semibold focus:-translate-y-1 hover:shadow-xl hover:font-semibold hover:-translate-y-1 transition">acceder</button>
                    </div>
                    {/* Mobile Screen: Footer */}
                    <div className="p-4 text-center text-white right-0 left-0 flex justify-center space-x-4 mt-16 lg:hidden ">
                        <span>Copyright &copy; 2023 TASKFLOW360</span>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;