import React, { Component } from 'react';
import { ArrowLeftIcon, ChartBarIcon, ChatAltIcon, CogIcon, FolderOpenIcon, HomeIcon, LoginIcon, SearchIcon, TemplateIcon, TicketIcon, UserGroupIcon, UserIcon } from '@heroicons/react/solid';
import { BellIcon, ChevronDownIcon, MailIcon } from '@heroicons/react/outline';
import Logo from '../resources/images/OriginalLogo.png';
import { Link, Navigate, useMatch, useResolvedPath } from 'react-router-dom';
import { toast } from 'react-toastify';
import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';
import NoAlarm from '../../src/resources/images/no-alarm.png';
import Echo from 'laravel-echo';
// eslint-disable-next-line
import Pusher from 'pusher-js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
var CryptoJS = require("crypto-js");

const Menus = [
    { title: "Dashboard", key: '/', accessValues: ['dashboard'], icon: <HomeIcon className='h-5' /> },
    { title: "Peticiones", key: '/peticiones', accessValues: ['peticiones'], icon: <TicketIcon className='h-5' /> },
    {
        title: "Automatización",
        key: '/automatizacion',
        accessValues: ['SLA', 'notificaciones correo'],
        icon: <CogIcon className='h-5' />,
        spacing: true,
        submenu: true,
        submenuItems: [
            { title: "SLA", key: '/slas', accessValues: ['SLA'], isSubmenu: true },
            { title: "Notificaciones Correo", key: '/notificaciones-correo', accessValues: ['notificaciones correo'], isSubmenu: true },
        ],
    },
    {
        title: "Personalización",
        key: '/personalizacion',
        accessValues: ['categorias', 'subcategorias', 'estatus', 'prioridades', 'departamentos'],
        icon: <TemplateIcon className='h-5' />,
        submenu: true,
        submenuItems: [
            { title: "Categorías y Subcategorías", key: '/categorias-subcategorias', accessValues: ['categorias', 'subcategorias'], isSubmenu: true },
            { title: "Estatus", key: '/estatus', accessValues: ['estatus'], isSubmenu: true },
            { title: "Prioridades", key: '/prioridades', accessValues: ['prioridades'], isSubmenu: true },
            { title: "Departamentos", key: '/departamentos', accessValues: ['departamentos'], isSubmenu: true },
        ],
    },
    { title: "Reportes", key: '/reportes', accessValues: ['reportes'], icon: <ChartBarIcon className='h-5' /> },
    {
        title: "Admin",
        key: '/admin',
        accessValues: ['usuarios', 'roles', 'puestos'],
        icon: <FolderOpenIcon className='h-5' />,
        spacing: true,
        submenu: true,
        submenuItems: [
            { title: "Usuarios", key: '/usuarios', accessValues: ['usuarios'], isSubmenu: true },
            { title: "Roles", key: '/roles', accessValues: ['roles'], isSubmenu: true },
            { title: "Puestos", key: '/puestos', accessValues: ['puestos'], isSubmenu: true },
        ],
    },
    {
        title: "Personal",
        key: '/personal',
        accessValues: ['tutores', 'administrativos', 'estudiantes'],
        icon: <UserGroupIcon className='h-5' />,
        submenu: true,
        submenuItems: [
            { title: "Tutores", key: '/tutores', accessValues: ['tutores'], isSubmenu: true },
            { title: "Administrativos", key: '/administrativos', accessValues: ['administrativos'], isSubmenu: true },
            { title: "Estudiantes", key: '/estudiantes', accessValues: ['estudiantes'], isSubmenu: true },
        ],
    },
    {
        title: "Escuelas",
        key: '/escuelas',
        accessValues: ['planteles', 'ciclos', 'turnos', 'grupos'],
        icon: <svg className='h-5 fill-gray-300' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M288 0H400C408.8 0 416 7.164 416 16V64C416 72.84 408.8 80 400 80H320V95.53L410.3 160H512C547.3 160 576 188.7 576 224V448C576 483.3 547.3 512 512 512H336V400C336 373.5 314.5 352 288 352C261.5 352 240 373.5 240 400V512H64C28.65 512 0 483.3 0 448V224C0 188.7 28.65 160 64 160H165.7L256 95.53V32C256 14.33 270.3 0 288 0V0zM288 192C261.5 192 240 213.5 240 240C240 266.5 261.5 288 288 288C314.5 288 336 266.5 336 240C336 213.5 314.5 192 288 192zM80 224C71.16 224 64 231.2 64 240V304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V240C128 231.2 120.8 224 112 224H80zM448 304C448 312.8 455.2 320 464 320H496C504.8 320 512 312.8 512 304V240C512 231.2 504.8 224 496 224H464C455.2 224 448 231.2 448 240V304zM80 352C71.16 352 64 359.2 64 368V432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V368C128 359.2 120.8 352 112 352H80zM464 352C455.2 352 448 359.2 448 368V432C448 440.8 455.2 448 464 448H496C504.8 448 512 440.8 512 432V368C512 359.2 504.8 352 496 352H464z"/>
        </svg>,
        spacing: true,
        submenu: true,
        submenuItems: [
            { title: "Planteles", key: '/planteles', accessValues: ['planteles'], isSubmenu: true },
            { title: "Ciclos", key: '/ciclos', accessValues: ['ciclos'], isSubmenu: true },
            { title: "Turnos", key: '/turnos', accessValues: ['turnos'], isSubmenu: true },
            { title: "Grupos", key: '/grupos', accessValues: ['grupos'], isSubmenu: true },
        ],
    },
    {
        title: "Finanzas",
        key: '/finanzas',
        accessValues: ['becas', 'descuentos', 'tesoreria', 'inscripciones', 'colegiaturas'],
        icon: <svg xmlns="http://www.w3.org/2000/svg" className='h-5 fill-gray-300' viewBox="0 0 512 512">
            <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/>
        </svg>,
        spacing: false,
        submenu: true,
        submenuItems: [
            { title: "Becas y Descuentos", key: '/becas-descuentos', accessValues: ['becas', 'descuentos'], isSubmenu: true },
            { title: "Tesorería", key: '/tesoreria', accessValues: ['tesoreria'], isSubmenu: true },
            { title: "Inscripciones y Colegiaturas", key: '/inscripciones-colegiaturas', accessValues: ['inscripciones', 'colegiaturas'], isSubmenu: true },
        ],
    },
];

function CustomLink({ children, to, sidebar, submenu, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    if (children.submenu) {
        return (
            <div {...props} className={`text-sm flex items-center gap-4 cursor-pointer p-2 text-gray-100 ${match && 'bg-accent-1'} hover:bg-accent-2 rounded-md ${children.spacing ? 'mt-9' : 'mt-2'}`}>
                <span className='text-2xl block float-left'>
                    {children.icon}
                </span>
                <span className={`text-base font-medium flex-1 duration-200 ${!sidebar && 'hidden'}`}>
                    {children.title}
                </span>
                {children.submenu && sidebar && (
                    <ChevronDownIcon className={`${submenu && 'rotate-180'} duration-300 h-5`} />
                )}
            </div>
        );
    }
    if (children.isSubmenu) {
        return (
            <Link
                to={to}
                {...props}
            >
                <div key={children.title} className={`text-sm flex items-center gap-4 cursor-pointer p-2 px-5 text-gray-100 ${match && 'bg-accent-1'} hover:bg-accent-2 rounded-md mt-2`}>
                    <span className={`duration-200 ${!sidebar && 'hidden'}`}>
                        {children.title}
                    </span>
                </div>
            </Link>
        );
    }
    return (
        <Link
            to={to}
            {...props}
        >
            <div className={`text-sm flex items-center gap-4 cursor-pointer p-2 text-gray-100 ${match && 'bg-accent-1'} hover:bg-accent-2 rounded-md ${children.spacing ? 'mt-9' : 'mt-2'}`}>
                <span className='text-2xl block float-left'>
                    {children.icon}
                </span>
                <span className={`text-base font-medium flex-1 duration-200 ${!sidebar && 'hidden'}`}>
                    {children.title}
                </span>
            </div>
        </Link>
    );
}

class SidebarHeader extends Component {

    constructor(props) {
        super(props);
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            pathName: window.location.pathname,
            showMenu: 'hidden',
            profileMenuOpen: false,
            notificationMenuOpen: false,
            user: {
                id: 0,
                email: 'Cargando...',
                foto_perfil: '',
                user_type: ''
            },
            notificaciones: [],
            notificacionesCount: 0,
            notificacionesLoaded: false,
            sidebarFull: window.innerWidth > 640,
            automatizacionMenuOpen: false,
            personalizacionMenuOpen: false,
            adminMenuOpen: false,
            personalMenuOpen: false,
            escuelasMenuOpen: false,
            finanzasMenuOpen: false,
            usersAccess: _usersAccess,
            loggedOut: false,
            processingRequest: false,
            overflown: false,
            statusResult: 0,
            firstScreen: '/' + (_usersAccess[0].modulo_name === 'dashboard' ? '' : _usersAccess[0].modulo_name)
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.CurrentUser).then((result) => {
            this.setState({ statusResult: result.status })
            if (result.status === 401) {
                toast.info('Favor de Iniciar Sesion de nuevo');
            }
            else {
                this.setState({ user: result });
                this.handleUserNotifications(result.id);
            }
            
        });
        const profileMenu = document.getElementById("divProfileMenu");
        document.addEventListener("click", (event) => {
            const isClickInside = profileMenu.contains(event.target);
            if (!isClickInside) {
                this.setState({ profileMenuOpen: false });
            }
        });
        const notificationMenu = document.getElementById("divNotificationMenu");
        document.addEventListener("click", (event) => {
            const isClickInside = notificationMenu.contains(event.target);
            if (!isClickInside) {
                this.setState({ notificationMenuOpen: false });
            }
        });
        const updatedNotificationsEcho = new Echo({
            broadcaster: 'pusher',
            key: '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu',
            wsHost: process.env.REACT_APP_WEBSOCKET_URL,
            transports: ['websocket', 'polling', 'flashsocket']
        });
        updatedNotificationsEcho
            .channel('updated.notifications')
            .subscribed(() => {
                console.log('notifications up to date');
            })
            .listen('.updated.notifications', (data) => {
                this.setState({ notificaciones: data[0].slice(0, 4), notificacionesCount: data[0].length });
            })
        ;
        const newNotificationEcho = new Echo({
            broadcaster: 'pusher',
            key: '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu',
            wsHost: process.env.REACT_APP_WEBSOCKET_URL,
            transports: ['websocket', 'polling', 'flashsocket']
        });
        newNotificationEcho
            .channel('notification.new')
            .subscribed(() => {
                console.log('listening for new notifications');
            })
            .listen('.notification.new', (data) => {
                if (parseInt(data.usuario_id) === this.state.user.id && !this.state.notificaciones.some(n => n.id === data.id)) {
                    this.setState(prevState => ({
                        notificaciones: prevState.notificaciones.slice(0, 3).concat(data),
                        notificacionesCount: this.state.notificacionesCount + 1
                    }));
                    toast.info('Tienes una nueva notificación', {toastId: data.id, position: "bottom-right"});
                }
            })
        ;
        
    }

    renderToast = () => {
        const toastId = React.useRef(null);
        if(! toast.isActive(toastId.current)) {
            toastId.current = toast("I cannot be duplicated!");
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.user !== this.state.user || prevState.sidebarFull !== this.state.sidebarFull) {
            this.props.sidebar(this.state.sidebarFull);
            this.props.authUser(this.state.user);
        }
        if (prevProps.userUpdated !== this.props.userUpdated) {
            RestClient.GetRequest(AppUrl.CurrentUser).then((result) => {
                this.setState({ user: result });
            });
        }
        if (prevState.overflown !== this.isOverflown(document.getElementById('divSidebar'))) {
            this.setState({ overflown: this.isOverflown(document.getElementById('divSidebar')) });
        }

    }

    isOverflown = (element) => {
        if (element) {
            return element.scrollHeight > element.clientHeight;
        }
        else return false;
    }

    handleSidebar = () => {
        this.setState(prevState => ({ sidebarFull: !prevState.sidebarFull }));
    }

    handleLogout = () => {
        //Disable controls while request is processed
        this.setState({ processingRequest: true });

        RestClient.GetRequest(AppUrl.Logout + this.state.user.id).then((result) => {
            this.setState({ processingRequest: false });
            if (result.status === 200) {
                localStorage.removeItem('token');
                localStorage.removeItem('usersAccess');
                this.setState({ loggedOut: true });
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    handleUserNotifications = (usuario_id) => {
        RestClient.GetRequest(AppUrl.UserUnreadNotifications + usuario_id).then((result) => {
            this.setState({ notificaciones: result.slice(0, 4), notificacionesCount: result.length, notificacionesLoaded: true });
        });
    }

    formatTimeHistory = (date) => {
        var newDate = new Date(date);
        return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString();
    }

    handleAllRead = () => {
        //Disable controls while request is processed
        this.setState({ processingRequest: true });
        RestClient.GetRequest(AppUrl.MarkAllRead + this.state.user.id).then((result) => {
            this.setState({ processingRequest: false });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.loggedOut || this.state.statusResult === 401) {
            return <Navigate to='/login' />
        }
        return (
            <>
                <header className={`bg-white ${this.state.sidebarFull ? 'xl:pl-80 lg:pl-72 md:pl-64' : 'pl-20'} duration-300 w-full h-16 fixed shadow-lg z-20`}>
                    <div className='flex p-4 items-center justify-between'>
                        <span className='sm:block hidden xl:text-2xl lg:text-xl md:text-base font-bold text-black truncate'>
                            <span className='xl:text-2xl lg:text-xl md:text-base font-normal'>Bienvenido al Sistema</span> {process.env.REACT_APP_CLIENT_FULL_NAME}
                        </span>
                        <div className='min-w-[303px] sm:w-auto w-full flex justify-end md:pr-0 sm:pr-2 pr-6'>
                            <div className='inline-flex gap-4 sm:border-l sm:px-6 px-3'>
                                <ReactTooltip className='tooltip-default' anchorId='btnSearch' content='Función próximamente' place="bottom" />
                                <SearchIcon id='btnSearch' className='h-9 w-9 text-gray-400 bg-gray-100 rounded-full p-1 cursor-pointer'/>
                            </div>
                            <div className='inline-flex gap-4 border-l px-6'>
                                <ReactTooltip className='tooltip-default' anchorId='btnMail' content='Función próximamente' place="bottom" />
                                <MailIcon id='btnMail' className='h-9 w-9 text-gray-400 bg-gray-100 rounded-full p-1 cursor-pointer' />
                                <div id='divNotificationMenu' className="relative inline-block text-left">
                                    <span className="rounded-md shadow-sm">
                                        <button onClick={() => this.setState({ notificationMenuOpen: !this.state.notificationMenuOpen })} className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-accent-1 focus:ring-white group" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                            <span className="sr-only">Open notification menu</span>
                                            {this.state.notificacionesLoaded && this.state.notificaciones.length !== 0 && <span className="absolute -top-2 -right-2 rounded-full bg-accent-2 flex justify-center items-center items px-1.5"><span className='text-white font-semibold text-sm'>{this.state.notificacionesCount}</span></span>}
                                            <BellIcon className='h-9 w-9 text-gray-400 bg-gray-100 rounded-full p-1 group-focus:text-accent-1' />
                                        </button>
                                    </span>
                                    <div className={`dropdown-menu ${!this.state.notificationMenuOpen && 'invisible opacity-0 -translate-y-2 scale-95'} sm:origin-top-right origin-top-left transform transition-all duration-300`}>
                                        <div className="absolute sm:right-0 -right-20 mt-2 w-60 sm:origin-top-right origin-top-left divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                            <div className="px-4 py-3">
                                                <p className="text-sm leading-6 font-semibold text-slate-400 uppercase">Notificaciones</p>
                                            </div>
                                            <div className="flex-col w-full justify-center items-center">
                                                {!this.state.notificacionesLoaded ? <div className='flex w-full justify-center bg-white py-4 pb-6'>
                                                <svg className="animate-spin h-7 w-7 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                </div> 
                                                : this.state.notificaciones.length === 0 ? <div className='py-4'>
                                                    <img className="mx-auto h-9 w-9 rounded-full" src={NoAlarm} alt="Sin Notificaciones" />
                                                    <p className='text-center text-xs font-semibold text-slate-400'>No tienes nuevas notificaciones</p>
                                                </div>
                                                : this.state.notificaciones.map(notificacion => (
                                                <div key={notificacion.id} className='hover:bg-gray-200 cursor-pointer w-full border-b border-gray-200 p-3 group'>
                                                    <Link to={'/peticiones/editar/' + notificacion.peticion_id} onClick={() => this.setState({ notificationMenuOpen: !this.state.notificationMenuOpen })} className=''>
                                                        <div className='group-hover:bg-gray-200 flex items-center gap-3 w-full'>
                                                            {notificacion.tipo === 'response' ? <ChatAltIcon className='h-16 text-accent-2' /> : <TicketIcon className='h-12' style={{color: notificacion.color}} />}
                                                            <p className='text-sm leading-6 text-gray-800 font-medium'>{notificacion.mensaje + ' Petición ID #' + notificacion.peticion_id}</p>   
                                                        </div>
                                                        <div className='group-hover:bg-gray-200'>
                                                            <span className='ml-3 text-xs leading-6 font-semibold text-slate-400'>{this.formatTimeHistory(notificacion.created_at)}</span>
                                                        </div> 
                                                    </Link>
                                                </div>
                                                ))
                                                }
                                            </div> 
                                            {this.state.notificacionesLoaded && <div className={`flex ${this.state.processingRequest ? 'justify-center' : 'justify-between'} px-4 py-3`}>
                                                <button type='button' disabled hidden={!this.state.processingRequest}>
                                                    <svg className="animate-spin h-5 w-5 text-dark-purple" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                </button>
                                                <Link to='/notificaciones' onClick={() => this.setState({ notificationMenuOpen: !this.state.notificationMenuOpen })} hidden={this.state.processingRequest} className='text-sm leading-6 font-semibold text-gray-800 hover:underline hover:text-accent-1'>Ver todas</Link>
                                                {this.state.notificaciones.length !== 0 && !this.state.processingRequest && <button type='button' onClick={this.handleAllRead} className='text-sm leading-6 font-semibold text-gray-800 hover:underline hover:text-accent-1'>Leídas todas</button>}
                                            </div>} 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='divProfileMenu' className='inline-flex gap-4 border-l px-6'>
                                <div className="relative inline-block text-left">
                                    <span className="rounded-md shadow-sm">
                                        <button onClick={() => this.setState({ profileMenuOpen: !this.state.profileMenuOpen })} className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-accent-1 focus:ring-white" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                            <span className="sr-only">Open user menu</span>
                                            <img className="h-8 w-8 rounded-full max-w-none" src={AppUrl.ProfilePics + this.state.user.foto_perfil} alt="" />
                                        </button>
                                    </span>
                                    <div className={`dropdown-menu ${!this.state.profileMenuOpen && 'invisible opacity-0 -translate-y-2 scale-95'} origin-top-right transform transition-all duration-300`}>
                                        <div className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                            <div className="px-4 py-3">
                                                <p className="text-sm leading-5">Bienvenid@,</p>
                                                <p className="truncate text-sm font-medium leading-5 text-gray-900">{this.state.user.nombre}</p>
                                                <span id='userId' className='hidden'>{this.state.user.id}</span>
                                                <span id='user_type' className='hidden'>{this.state.user.user_type}</span>
                                            </div>
                                            <div className="py-1">
                                                <Link to='/perfil' onClick={() => this.setState({ profileMenuOpen: !this.state.profileMenuOpen })} tabIndex="0" className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-dark-purple group" role="menuitem">
                                                    <UserIcon className='h-5 w-5 text-gray-400 group-hover:text-dark-purple' />
                                                    Tu Perfil
                                                </Link>
                                                <Link to='/perfil/configuraciones' onClick={() => this.setState({ profileMenuOpen: !this.state.profileMenuOpen })} tabIndex="1" className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-200  hover:text-dark-purple group" role="menuitem">
                                                    <CogIcon className='h-5 w-5 text-gray-400 group-hover:text-dark-purple' />
                                                    Configuraciones
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <div className='flex w-full justify-center'>
                                                    <button hidden={!this.state.processingRequest} type='button' disabled className='text-white w-auto px-5 py-2.5 text-center'>
                                                        <svg className="animate-spin h-5 w-5 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                    </button>
                                                </div>                       
                                                <button type='button' onClick={this.handleLogout} tabIndex="2" hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} w-full items-center gap-2 px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-dark-purple group`} role="menuitem">
                                                    <LoginIcon className='h-5 w-5 text-gray-400 group-hover:text-dark-purple' />
                                                    Cerrar Sessión
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <nav className={`inline-flex ${this.state.sidebarFull ? 'xl:w-80 lg:w-72 md:w-64 sm:w-60 w-full' : 'w-20'} duration-300 fixed z-20`}>
                    <div id='divSidebar' className={`bg-dark-purple h-screen p-5 pt-8 flex flex-col w-full relative ${this.state.overflown && 'overflow-y-auto overflow-x-hidden'}`}>
                        <ArrowLeftIcon className={`bg-white text-dark-purple sm:w-7 sm:h-7 w-9 h-9 p-1 rounded-full absolute ${this.state.overflown ? 'right-0' : this.state.sidebarFull ? 'sm:-right-3 right-0' : '-right-3'} top-9 border border-dark-purple cursor-pointer ${!this.state.sidebarFull && 'rotate-180'}`} onClick={() => this.handleSidebar()} />
                        <Link to={this.state.firstScreen} className='inline-flex items-center'>
                            <img src={Logo} alt='Logo' className={`h-12 mr-2 rounded cursor-pointer block float-left duration-500 ${this.state.sidebarFull && 'rotate-[360deg]'} `} />
                            <h1 className={`text-white origin-left text-2xl font-medium duration-300 ${!this.state.sidebarFull && 'scale-0'}`}>{process.env.REACT_APP_CLIENT_SHORT_NAME}</h1>
                        </Link>

                        <ul className='pt-2'>
                            {Menus.map((menu) => {
                                if (this.state.usersAccess.some(ua => menu.accessValues.includes(ua.modulo_name))) {
                                    return (
                                    <li key={menu.title}>
                                        <CustomLink to={menu.key} sidebar={this.state.sidebarFull}
                                            submenu={
                                                menu.title === 'Automatización' ? this.state.automatizacionMenuOpen :
                                                menu.title === 'Personalización' ? this.state.personalizacionMenuOpen :
                                                menu.title === 'Admin' ? this.state.adminMenuOpen : 
                                                menu.title === 'Personal' ? this.state.personalMenuOpen : 
                                                menu.title === 'Escuelas' ? this.state.escuelasMenuOpen :
                                                menu.title === 'Finanzas' ? this.state.finanzasMenuOpen : false}
                                            onClick={() => menu.submenu ? (this.setState(prevState => (
                                                menu.title === 'Automatización' ? { automatizacionMenuOpen: !prevState.automatizacionMenuOpen, sidebarFull: true } :
                                                menu.title === 'Personalización' ? { personalizacionMenuOpen: !prevState.personalizacionMenuOpen, sidebarFull: true } :
                                                menu.title === 'Admin' ? { adminMenuOpen: !prevState.adminMenuOpen, sidebarFull: true } :
                                                menu.title === 'Personal' ? { personalMenuOpen: !prevState.personalMenuOpen, sidebarFull: true } :
                                                menu.title === 'Escuelas' ? { escuelasMenuOpen: !prevState.escuelasMenuOpen, sidebarFull: true } :
                                                menu.title === 'Finanzas' ? { finanzasMenuOpen: !prevState.finanzasMenuOpen, sidebarFull: true } : {})))
                                                : window.innerWidth <= 640 ? this.setState({sidebarFull: false}) : {}}>
                                            {menu}
                                        </CustomLink>
                                        {menu.submenu && this.state.sidebarFull &&
                                            (menu.title === 'Automatización' ? this.state.automatizacionMenuOpen :
                                             menu.title === 'Personalización' ? this.state.personalizacionMenuOpen :
                                             menu.title === 'Admin' ? this.state.adminMenuOpen :
                                             menu.title === 'Personal' ? this.state.personalMenuOpen :
                                             menu.title === 'Escuelas' ? this.state.escuelasMenuOpen :
                                             menu.title === 'Finanzas' ? this.state.finanzasMenuOpen : false) &&
                                            (
                                                <ul>
                                                    {menu.submenuItems.map((submenuItem) => {
                                                        if (this.state.usersAccess.some(ua => submenuItem.accessValues.includes(ua.modulo_name))) {
                                                            return (
                                                                <CustomLink key={submenuItem.title} to={submenuItem.key} sidebar={this.state.sidebarFull} submenu={false} onClick={() => {window.innerWidth <= 640 && this.setState({sidebarFull: false})}}>
                                                                    {submenuItem}
                                                                </CustomLink>
                                                            )
                                                        } else return ''
                                                    })}
                                                </ul>
                                            )}
                                    </li>)
                                } else return ''
                            })}
                        </ul>
                        <footer className='w-full mt-auto pt-8 text-center' hidden={!this.state.sidebarFull}>
                            <span className='text-gray-300 text-sm'>Versión (dev) Pre-Alfa 1.0.0</span>
                        </footer>
                    </div>
                    
                </nav>
            </>
        );
    }
}

export default SidebarHeader;