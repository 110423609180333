import './App.css';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Layout from './components/Layout';
import DashboardPage from './pages/DashboardPage';
import PeticionesPage from './pages/peticiones/PeticionesPage';
import MainPage from './pages/categorias-subcategorias/CategoriaSubcategoriaPage';
import EstatusPage from './pages/estatus/EstatusPage';
import PrioridadesPage from './pages/prioridades/PrioridadesPage';
import CrearCategoriaPage from './pages/categorias-subcategorias/CrearCategoriaPage';
import EditarCategoriaPage from './pages/categorias-subcategorias/EditarCategoriaPage';
import CrearSubcategoriaPage from './pages/categorias-subcategorias/CrearSubcategoriaPage';
import EditarSubcategoriaPage from './pages/categorias-subcategorias/EditarSubcategoriaPage';
import CrearEstatusPage from './pages/estatus/CrearEstatusPage';
import EditarEstatusPage from './pages/estatus/EditarEstatusPage';
import CrearPrioridadPage from './pages/prioridades/CrearPrioridadPage';
import DepartamentosPage from './pages/departamentos/DepartamentosPage';
import CrearDepartamentoPage from './pages/departamentos/CrearDepartamentoPage';
import EditarDepartamentoPage from './pages/departamentos/EditarDepartamentoPage';
import LoginPage from './pages/login/LoginPage';
import RolesPage from './pages/roles/RolesPage';
import CrearRolPage from './pages/roles/CrearRolPage';
import EditarPrioridadPage from './pages/prioridades/EditarPrioridadPage';
import EditarRolPage from './pages/roles/EditarRolPage';
import AccessDeniedPage from './pages/AccessDeniedPage';
import UsuariosPage from './pages/usuarios/UsuariosPage';
import CrearUsuarioPage from './pages/usuarios/CrearUsuarioPage';
import TutoresPage from './pages/tutores/TutoresPage';
import CrearTutorPage from './pages/tutores/CrearTutorPage';
import EditarTutorPage from './pages/tutores/EditarTutorPage';
import EditarUsuarioPage from './pages/usuarios/EditarUsuarioPage';
import CrearUsuarioWithTutorPage from './pages/usuarios/CrearUsuarioWithTutorPage';
import AdministrativosPage from './pages/administrativos/AdministrativosPage';
import PuestosPage from './pages/puestos/PuestosPage';
import CrearPuestoPage from './pages/puestos/CrearPuestoPage';
import EditarPuestoPage from './pages/puestos/EditarPuestoPage';
import PlantelesPage from './pages/planteles/PlantelesPage';
import CrearPlantelPage from './pages/planteles/CrearPlantelPage';
import EditarPlantelPage from './pages/planteles/EditarPlantelPage';
import CrearAdministrativoPage from './pages/administrativos/CrearAdministrativoPage';
import EditarAdministrativoPage from './pages/administrativos/EditarAdministrativoPage';
import CrearUsuarioWithAdminPage from './pages/usuarios/CrearUsuarioWithAdminPage';
import CrearPeticionPage from './pages/peticiones/CrearPeticionPage';
import EditarPeticionPage from './pages/peticiones/EditarPeticionPage';
import NotificacionesPage from './pages/notificaciones/NotificacionesPage';
import PerfilPage from './pages/perfil/PerfilPage';
import SLAPage from './pages/sla/SLAPage';
import CrearSLAPage from './pages/sla/CrearSLAPage';
import NotificacionesCorreoPage from './pages/notificaciones-correo/NotificacionesCorreoPage';
import CrearNotificacionCorreoPage from './pages/notificaciones-correo/CrearNotificacionCorreoPage';
import EditarNotificacionCorreoPage from './pages/notificaciones-correo/EditarNotificacionCorreoPage';
import EditarSLAPage from './pages/sla/EditarSLAPage';
import CrearPeticionPanelPage from './pages/peticiones/CrearPeticionPanelPage';
import EstudiantesPage from './pages/estudiantes/EstudiantesPage';
import CrearEstudiantePage from './pages/estudiantes/CrearEstudiantePage';
import EditarEstudiantePage from './pages/estudiantes/EditarEstudiantePage';
import CrearUsuarioWithEstudiantePage from './pages/usuarios/CrearUsuarioWithEstudiantePage';
import ActivacionPage from './pages/activacion/ActivacionPage';
import ReportesPage from './pages/reportes/ReportesPage';
import CiclosPage from './pages/ciclos/CiclosPage';
import CrearCicloPage from './pages/ciclos/CrearCicloPage';
import EditarCicloPage from './pages/ciclos/EditarCicloPage';
import BecasDescuentosPage from './pages/becas-descuentos/BecasDescuentosPage';
import CrearBecaPage from './pages/becas-descuentos/CrearBecaPage';
import EditarBecaPage from './pages/becas-descuentos/EditarBecaPage';
import CrearDescuentoPage from './pages/becas-descuentos/CrearDescuentoPage';
import EditarDescuentoPage from './pages/becas-descuentos/EditarDescuentoPage';
import TesoreriaPage from './pages/tesoreria/TesoreriaPage';
import CrearTesoreriaPage from './pages/tesoreria/CrearTesoreriaPage';
import EditarTesoreriaPage from './pages/tesoreria/EditarTesoreriaPage';
import InscripcionesColegiaturasPage from './pages/inscripciones-colegiaturas/InscripcionesColegiaturasPage';
import CrearInscripcionPage from './pages/inscripciones-colegiaturas/CrearInscripcionPage';
import CrearTurnoPage from './pages/turnos/CrearTurnoPage';
import TurnosPage from './pages/turnos/TurnosPage';
import EditarTurnoPage from './pages/turnos/EditarTurnoPage';
import GruposPage from './pages/grupos/GruposPage';
import CrearGrupoPage from './pages/grupos/CrearGrupoPage';
import EditarGrupoPage from './pages/grupos/EditarGrupoPage';

const App = () => {
  const [open, setOpen] = useState(true);
  
  return (
    <div id='mainApp'>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout sidebar={setOpen} ></Layout>} >
            <Route path='/' element={<DashboardPage sidebar={open}  />} />
            <Route path='/peticiones' element={<PeticionesPage sidebar={open} />} />
            <Route path='/peticiones/crear-panel' element={<CrearPeticionPanelPage sidebar={open} />} />
            <Route path='/peticiones/crear' element={<CrearPeticionPage sidebar={open} />} />
            <Route path='/peticiones/editar/:id' element={<EditarPeticionPage sidebar={open} />} />
            <Route path='/categorias-subcategorias' element={<MainPage sidebar={open}  />} />
            <Route path='/categorias-subcategorias/crear-categoria' element={<CrearCategoriaPage sidebar={open}  />} />
            <Route path='/categorias-subcategorias/crear-subcategoria' element={<CrearSubcategoriaPage sidebar={open}  />} />
            <Route path='/categorias-subcategorias/editar-categoria/:id' element={<EditarCategoriaPage sidebar={open}  />} />
            <Route path='/categorias-subcategorias/editar-subcategoria/:id' element={<EditarSubcategoriaPage sidebar={open}  />} />
            <Route path='/estatus' element={<EstatusPage sidebar={open}  />} />
            <Route path='/estatus/crear' element={<CrearEstatusPage sidebar={open}  />} />
            <Route path='/estatus/editar/:id' element={<EditarEstatusPage sidebar={open}  />} />
            <Route path='/prioridades' element={<PrioridadesPage sidebar={open}  />} />
            <Route path='/prioridades/crear' element={<CrearPrioridadPage sidebar={open}  />} />
            <Route path='/prioridades/editar/:id' element={<EditarPrioridadPage sidebar={open}  />} />
            <Route path='/departamentos' element={<DepartamentosPage sidebar={open}  />} />
            <Route path='/departamentos/crear' element={<CrearDepartamentoPage sidebar={open}  />} />
            <Route path='/departamentos/editar/:id' element={<EditarDepartamentoPage sidebar={open}  />} />
            <Route path='/roles' element={<RolesPage sidebar={open}  />} />
            <Route path='/roles/crear' element={<CrearRolPage sidebar={open}  />} />
            <Route path='/roles/editar/:id' element={<EditarRolPage sidebar={open}  />} />
            <Route path='/usuarios' element={<UsuariosPage sidebar={open} />} />
            <Route path='/usuarios/crear' element={<CrearUsuarioPage sidebar={open} />} />
            <Route path='/usuarios/crear-con-tutor/:id' element={<CrearUsuarioWithTutorPage sidebar={open} />} />
            <Route path='/usuarios/crear-con-admin/:id' element={<CrearUsuarioWithAdminPage sidebar={open} />} />
            <Route path='/usuarios/crear-con-estudiante/:id' element={<CrearUsuarioWithEstudiantePage sidebar={open} />} />
            <Route path='/usuarios/editar/:id' element={<EditarUsuarioPage sidebar={open} />} />
            <Route path='/tutores' element={<TutoresPage sidebar={open} />} />
            <Route path='/tutores/crear' element={<CrearTutorPage sidebar={open} />} />
            <Route path='/tutores/editar/:id' element={<EditarTutorPage sidebar={open}  />} />
            <Route path='/puestos' element={<PuestosPage sidebar={open}  />} />
            <Route path='/puestos/crear' element={<CrearPuestoPage sidebar={open} />} />
            <Route path='/puestos/editar/:id' element={<EditarPuestoPage sidebar={open} />} />
            <Route path='/planteles' element={<PlantelesPage sidebar={open}  />} />
            <Route path='/planteles/crear' element={<CrearPlantelPage sidebar={open}  />} />
            <Route path='/planteles/editar/:id' element={<EditarPlantelPage sidebar={open} />} />
            <Route path='/administrativos' element={<AdministrativosPage sidebar={open} />} />
            <Route path='/administrativos/crear' element={<CrearAdministrativoPage sidebar={open}  />} />
            <Route path='/administrativos/editar/:id' element={<EditarAdministrativoPage sidebar={open} />} />
            <Route path='/estudiantes' element={<EstudiantesPage sidebar={open} />} />
            <Route path='/estudiantes/crear' element={<CrearEstudiantePage sidebar={open}  />} />
            <Route path='/estudiantes/editar/:id' element={<EditarEstudiantePage sidebar={open}  />} />
            <Route path='/slas' element={<SLAPage sidebar={open} />} />
            <Route path='/slas/crear' element={<CrearSLAPage sidebar={open} />} />
            <Route path='/slas/editar/:id' element={<EditarSLAPage sidebar={open} />} />
            <Route path='/notificaciones-correo' element={<NotificacionesCorreoPage sidebar={open} />} />
            <Route path='/notificaciones-correo/crear' element={<CrearNotificacionCorreoPage sidebar={open} />} />
            <Route path='/notificaciones-correo/editar/:id' element={<EditarNotificacionCorreoPage sidebar={open} />} />
            <Route path='/reportes' element={<ReportesPage sidebar={open} />} />
            <Route path='/ciclos' element={<CiclosPage sidebar={open} />} />
            <Route path='/ciclos/crear' element={<CrearCicloPage sidebar={open} />} />
            <Route path='/ciclos/editar/:id' element={<EditarCicloPage sidebar={open} />} />
            <Route path='/becas-descuentos' element={<BecasDescuentosPage sidebar={open} />} />
            <Route path='/becas-descuentos/crear-beca' element={<CrearBecaPage sidebar={open} />} />
            <Route path='/becas-descuentos/editar-beca/:id' element={<EditarBecaPage sidebar={open} />} />
            <Route path='/becas-descuentos/crear-descuento' element={<CrearDescuentoPage sidebar={open} />} />
            <Route path='/becas-descuentos/editar-descuento/:id' element={<EditarDescuentoPage sidebar={open} />} />
            <Route path='/tesoreria' element={<TesoreriaPage sidebar={open} />} />
            <Route path='/tesoreria/crear' element={<CrearTesoreriaPage sidebar={open} />} />
            <Route path='/tesoreria/editar/:id' element={<EditarTesoreriaPage sidebar={open} />} />
            <Route path='/inscripciones-colegiaturas' element={<InscripcionesColegiaturasPage sidebar={open} />} />
            <Route path='/inscripciones-colegiaturas/crear-inscripcion' element={<CrearInscripcionPage sidebar={open} />} />
            <Route path='/turnos' element={<TurnosPage sidebar={open} />} />
            <Route path='/turnos/crear' element={<CrearTurnoPage sidebar={open} />} />
            <Route path='/turnos/editar/:id' element={<EditarTurnoPage sidebar={open} />} />
            <Route path='/grupos' element={<GruposPage sidebar={open} />} />
            <Route path='/grupos/crear' element={<CrearGrupoPage sidebar={open} />} />
            <Route path='/grupos/editar/:id' element={<EditarGrupoPage sidebar={open} />} />

            <Route path='/perfil' element={<PerfilPage sidebar={open} />} />
            <Route path='/perfil/:configuraciones' element={<PerfilPage sidebar={open} />} />
            <Route path='/notificaciones' element={<NotificacionesPage sidebar={open} />} />
            <Route path='/access-denied' element={<AccessDeniedPage sidebar={open} />} />
          </Route>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/activacion/:token' element={<ActivacionPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position='top-center' theme='colored' hideProgressBar />
    </div>

  );
}

export default App;
