import { CalendarIcon, ChevronRightIcon, ExclamationCircleIcon, FlagIcon, TagIcon, TicketIcon, UserGroupIcon, UserIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import { Link, Navigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/solid';
import ArchivosAdjuntosInput from '../../components/form-controls/ArchivosAdjuntosInput';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import Loading from '../../components/Loading';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Collapse } from 'react-collapse';
import TareasComponent from '../../components/tareas/TareasComponent';
import { colorAccent2 } from '../../components/CustomTableComponents';
var CryptoJS = require("crypto-js");

const selectStyle = {
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? colorAccent2 : state.isFocused ? '#DEEBFF' : 'white',
    }),
}

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});
// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}
// Warn user about reloading site
window.onbeforeunload = function () {
    if (window.location.pathname === '/peticiones/crear') {
        return "";
    }
};

const CrearPeticionPage = (props) => {
    // Get Auth user and Peticion Context
    const context = useOutletContext();
    const user = context.user;
    const peticionContext = context.peticionContext;
    const [usersAccess, setUsersAccess] = useState([]);
    // Peticion states
    const [titulo, setTitulo] = useState(peticionContext?.titulo || '');
    const [descripcion, setDescripcion] = useState(peticionContext?.descripcion || '');
    const [usuario_id, setUsuarioId] = useState(user?.user_type === 'tutores' ? user?.id : '');
    const [categoria_id, setCategoriaId] = useState(peticionContext?.categoria_id || '');
    const [subcategoria_id, setSubcategoriaId] = useState(peticionContext?.subcategoria_id || '');
    const [estatus_id, setEstatusId] = useState(peticionContext?.estatus_id || '');
    const [departamento_id, setDepartamentoId] = useState(peticionContext?.departamento_id || '');
    const [asignado_id, setAsignadoId] = useState('');
    const [prioridad_id, setPrioridadId] = useState(peticionContext?.prioridad_id || '');
    const [vence_en, setVenceEn] = useState(peticionContext?.vence_en || '');
    const [tiempo_invertido, setTiempoInvertido] = useState(0);
    const [archivos_adjuntos, setArchivosAdjuntos] = useState('');
    const [tareas, setTareas] = useState([]);
    // Request/Form states
    const [statusResult, setStatusResult] = useState(0);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [categoriasSelectLoading, setCategoriasSelectLoading] = useState(true);
    const [subcategoriasSelectLoading, setSubcategoriasSelectLoading] = useState(false);
    const [subcategoriasSelectDisabled, setSubcategoriasSelectDisabled] = useState(true);
    const [hasEdited, setHasEdited] = useState(false);
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);
    const [errors, setErrors] = useState([{ titulo: '', descripcion: '', categoria_id: '', subcategoria_id: '', estatus_id: '', usuario_id: '', departamento_id: '', asignado_id: '', prioridad_id: '', archivos_adjuntos: '', selected_colaboradores: '', vence_en: '', tiempo_invertido: '' }]);
    const [usuarios, setUsuarios] = useState([]);
    const [administrativos, setAdministrativos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [estatus, setEstatus] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [prioridades, setPrioridades] = useState([]);
    const [colaboradores, setColaboradores] = useState(false);
    const [selected_colaboradores, setSelectedColaboradores] = useState([]);
    const [today, setToday] = useState(null);
    const [tareas_collapsed, setTareasCollapsed] = useState(false);
    const [tiHoras, setTiHoras] = useState(0);
    const [tiMinutos, setTiMinutos] = useState(0);
    const [tiTareasMessage, setTiTareasMessage] = useState('');

    useConstructor(() => {
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
        let today_str = new Date().toLocaleString("en-US", { timeZone: "America/Phoenix" });
        let today = new Date(today_str);
        today = today.setHours(today.getHours() - 7);
        setToday(today);
    });

    useEffect(() => {
        // Get Usuarios
        RestClient.GetRequest(AppUrl.UsersNameType).then((result) => {
            var options = [];
            var groupedOptions = [];
            result.forEach((element, index) => {
                var group = element.user_type;
                if ((index + 1) !== result.length && group === result[index + 1].user_type) {
                    element.usuario_id !== null && options.push({ value: element.usuario_id, label: element.nombre + ' ' + element.apellido });
                }
                else {
                    element.usuario_id !== null && options.push({ value: element.usuario_id, label: element.nombre + ' ' + element.apellido });
                    groupedOptions.push({ label: group, options: options });
                    options = [];
                }
            });
            setUsuarios(groupedOptions);
        });
        // Get Usuarios Administrativos
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({ label: depto, options: [{ value: element.usuario_id, label: element.nombre + ' ' + element.apellido }] });
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({ value: element.usuario_id, label: element.nombre + ' ' + element.apellido });
                }
            });
            setAdministrativos(groupedOptions);
        });
        // Get Categorias
        RestClient.GetRequest(AppUrl.Categorias).then((result) => {
            setCategorias([]);
            result.forEach(element => {
                setCategorias(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
            });
        });
        // Get Estatus
        RestClient.GetRequest(AppUrl.Estatus).then((result) => {
            var options = [];
            var groupedOptions = [];
            result.forEach((element, index) => {
                var group = element.tipo;
                if ((index + 1) !== result.length && group === result[index + 1].tipo) {
                    options.push({ value: element.id, label: element.nombre, color: element.color });
                }
                else {
                    options.push({ value: element.id, label: element.nombre, color: element.color });
                    groupedOptions.push({ label: 'Tipo: ' + group, options: options });
                    options = [];
                }
            });
            setEstatus(groupedOptions);
            setCategoriasSelectLoading(false);
        });
        // Get Departamentos
        RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
            setDepartamentos([]);
            result.forEach(element => {
                setDepartamentos(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
            });
        });
        // Get Prioridades
        RestClient.GetRequest(AppUrl.Prioridades).then((result) => {
            setPrioridades([]);
            result.forEach(element => {
                setPrioridades(prevState => ([...prevState, { value: element.id, label: element.nombre, color: element.color }]));
            });
        });
    }, [user]);
    // Set Descripcion
    useEffect(() => {
        if (peticionContext?.descripcion !== undefined) {
            handleDescripcionText(peticionContext.descripcion)
        }
    }, [peticionContext?.descripcion]);
    // Check if user has edited
    useEffect(() => {
        if (titulo !== '' || descripcion !== '' || archivos_adjuntos !== '' || tareas.length > 0) {
            setHasEdited(true);
        }
    }, [titulo, descripcion, archivos_adjuntos, tareas]);
    // Update Tiempo Invertido
    useEffect(() => {
        const tiPeticion = (parseInt(tiHoras, 10) * 60) + parseInt(tiMinutos, 10);
        const tiTareas = tareas.reduce((sum, tarea) => sum + (parseInt(tarea.ti_horas, 10) * 60) + parseInt(tarea.ti_minutos, 10), 0);
        const tiTareasHours = Math.floor(tiTareas / 60);
        const tiTareasMinutes = tiTareas % 60;
        const hourString = (tiTareasHours === 1) ? 'hora' : 'horas';
        const minuteString = (tiTareasMinutes === 1) ? 'minuto' : 'minutos';

        if (!isNaN(tiPeticion) && tiPeticion >= 0) {
            const tiPeticionRemainder = Math.abs(tiPeticion - tiTareas);
            setTiempoInvertido(tiTareas + tiPeticionRemainder);
        }

        if (tiTareas) {
            setTiTareasMessage(`Tiempo invertido en Tareas: ${tiTareasHours} ${hourString}, ${tiTareasMinutes} ${minuteString}`);
        } else {
            setTiTareasMessage('');
        }

        if (tiHoras < tiTareasHours) {
            setTiHoras(tiTareasHours);
        }
        if (tiMinutos < tiTareasMinutes) {
            setTiMinutos(tiTareasMinutes);
        }
    }, [tiHoras, tiMinutos, tareas]);


    function showConfirmation() {
        if (window.confirm('¿Estás seguro de que quieres salir? Tu petición no se guardará') === true) {
            setHasConfirmed(true);
        }
    }

    function handleCategoriaSelected(categoria_id) {
        // Get Subcategorias
        setSubcategoriasSelectLoading(true);
        RestClient.GetRequest(AppUrl.Subcategorias).then((result) => {
            setSubcategorias([]);
            result.forEach(element => {
                if (element.categorias.some(c => c.id === categoria_id)) {
                    setSubcategorias(prevState => ([...prevState, { value: element.id, label: element.nombre }]));
                }
            });
            setSubcategoriasSelectLoading(false);
            setSubcategoriasSelectDisabled(false);
        });
    }

    function handleDescripcionText(value) {
        let count = 0;
        const jsonValue = JSON.parse(value);
        const flatten = (members) => {
            let children = [];
            return members.map(mem => {
                const m = { ...mem }; // use spread operator
                if (m.children && m.children.length) {
                    children = [...children, ...m.children];
                }
                delete m.children; // this will not affect the original array object
                return m;
            }).concat(children.length ? flatten(children) : children);
        };
        let flattenedArray = flatten(jsonValue).forEach(element => {
            if (Object.hasOwn(element, 'text')) {
                count += element.text.length;
            }
        });
        if (flattenedArray && jsonValue.some(j => j.type === 'bulleted-list' || j.type === 'numbered-list')) {
            var jsonChild = {
                type: '',
                children: []
            };
            flattenedArray.forEach(child => {
                if (Object.hasOwn(child, 'type') && (child.type === 'bulleted-list' || child.type === 'numbered-list')) {
                    jsonChild.type = child.type;
                }
                else {
                    if (Object.hasOwn(child, 'type') && child.type === 'list-item') {
                        jsonChild.children.push({ type: child.type, children: [] });
                    } else {
                        jsonChild.children.find(c => c.type === 'list-item' && c.children.length === 0).children.push(child);
                    }
                }
            });
            value = JSON.stringify([jsonChild]);
        }
        setDescripcion(value);
        setCharacterCount(count);
    }

    function handleArchivosAdjuntos(archivos) {
        let files = Object.keys(archivos).map(function (key) { return archivos[key] });
        setArchivosAdjuntos(files);
    }

    function renderMinutos() {
        var values = [];
        for (let index = 0; index < 60; index++) {
            values.push(<option key={index} value={index}>{index}</option>);
        }
        return values;
    }

    function handleTIHoras(e) {
        const horas = e.target.value;
        const tiTareas = tareas.reduce((sum, tarea) => sum + (parseInt(tarea.ti_horas, 10) * 60) + parseInt(tarea.ti_minutos, 10), 0);
        const tareaHours = Math.floor(tiTareas / 60);

        setTiHoras(Math.max(horas, tareaHours));
    }

    function handleTIMinutos(e) {
        const minutos = e.target.value;
        const tiTareas = tareas.reduce((sum, tarea) => sum + (parseInt(tarea.ti_horas, 10) * 60) + parseInt(tarea.ti_minutos, 10), 0);
        const tareaMinutes = tiTareas % 60;

        setTiMinutos(Math.max(minutos, tareaMinutes));
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ titulo: '', descripcion: '', categoria_id: '', subcategoria_id: '', estatus_id: '', usuario_id: '', departamento_id: '', asignado_id: '', prioridad_id: '', archivos_adjuntos: '', selected_colaboradores: '', vence_en: '', tiempo_invertido: '' }])

        const data = new FormData();
        data.append('titulo', titulo);
        data.append('descripcion', descripcion);
        data.append('categoria_id', categoria_id);
        data.append('subcategoria_id', subcategoria_id);
        data.append('estatus_id', estatus_id);
        data.append('solicitante_id', usuario_id);
        data.append('usuario_id', user.id);
        data.append('user_name', user.nombre);
        data.append('departamento_id', departamento_id);
        data.append('asignado_id', asignado_id);
        data.append('prioridad_id', prioridad_id);
        data.append('vence_en', vence_en);
        data.append('tiempo_invertido', tiempo_invertido);
        for (let i = 0; i < archivos_adjuntos.length; i++) {
            data.append('archivos_adjuntos[]', archivos_adjuntos[i]);
        };
        data.append('colaboradores', colaboradores ? 1 : 0);
        if (selected_colaboradores.length > 0) {
            data.append('selected_colaboradores', JSON.stringify(selected_colaboradores));
        }
        if (tareas.length > 0) {
            data.append('tareas', JSON.stringify(tareas));
        }

        RestClient.PostRequest(AppUrl.CreatePeticion, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    if (usersAccess.find(ua => ua.modulo_name === 'peticiones' && ua.permiso_name === 'create') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (user !== null && user?.user_type !== 'administrativos' && peticionContext === null) {
        return <Navigate to='/peticiones/crear-panel' />
    }
    if (user !== null && user?.user_type !== 'administrativos' && hasConfirmed) {
        return <Navigate to='/peticiones/crear-panel' />
    }
    if (statusResult === 200 || hasConfirmed) {
        return <Navigate to='/peticiones' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Crear Petición</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full sm:text-sm text-xs text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <Link to='/peticiones' className='font-semibold hover:underline'>Peticiones</Link>
                    <span className='text-xs'>/</span>
                    {user?.user_type === 'tutores' &&
                        <>
                            <Link to='/peticiones/crear-panel' className='font-semibold hover:underline'>Tipo de Petición</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-dark-purple font-semibold'>Crear Petición</p>
                </div>
            </div>

            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8 my-2'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Registrar Petición <span className='ml-2 font-bold capitalize'>{titulo !== '' && `'${titulo}'`}</span></h1>
                                </div>
                                {!user ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4' encType="multipart/form-data">
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-start mb-4 gap-8">
                                            {/* First Column */}
                                            <div className="md:w-3/4 mx-auto lg:max-w-lg max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 bg-white group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <TicketIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setTitulo(e.target.value)} defaultValue={titulo} id='txtTitulo' type="text" name="titulo" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtTitulo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Título</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.titulo}</span>
                                                </div>
                                                <div className=''>
                                                    <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Descripción</h5>
                                                    <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-1 peer'>
                                                        <TextEditorInput defaultValue={descripcion} handleDescripcion={(descripcionValue) => handleDescripcionText(descripcionValue)} />
                                                    </div>
                                                    <p className={`flex w-full justify-end text-xs ${characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{characterCount}/500</p>
                                                    <span hidden={characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
                                                    <span className='text-sm text-red-500'>{errors.descripcion}</span>
                                                </div>
                                                <div className='-translate-y-2'>
                                                    <h5 className='mb-2 text-sm text-gray-400'>Archivos Adjuntos (Opcional)</h5>
                                                    <ArchivosAdjuntosInput handleFiles={handleArchivosAdjuntos} />
                                                    <span className='text-sm text-red-500'>{errors.archivos_adjuntos}</span>
                                                </div>
                                            </div>
                                            {/* Second Column */}
                                            {user.user_type === "administrativos" &&
                                                <div className="md:w-full mx-auto max-w-sm space-y-8">
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Usuario--'
                                                                        options={usuarios}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(usuario) => setUsuarioId(usuario.value)}
                                                                        isLoading={categoriasSelectLoading}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectUsuarios" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Solicitante</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <Link to='/usuarios/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.usuario_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <TagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Categoría--'
                                                                        options={categorias}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(categoria) => { setCategoriaId(categoria.value); handleCategoriaSelected(categoria.value) }}
                                                                        isLoading={categoriasSelectLoading}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectCategorias" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Categoría</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'categorias') !== undefined &&
                                                                <Link to='/categorias-subcategorias/crear-categoria' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.categoria_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <TagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Subcategoría--'
                                                                        isDisabled={subcategoriasSelectDisabled}
                                                                        options={subcategorias}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(subcategoria) => setSubcategoriaId(subcategoria.value)}
                                                                        isLoading={subcategoriasSelectLoading}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectSubcategorias" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Subcategoría</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'subcategorias') !== undefined &&
                                                                <Link to='/categorias-subcategorias/crear-subcategoria' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.subcategoria_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <FlagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Estatus--'
                                                                        options={estatus}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(estatus) => setEstatusId(estatus.value)}
                                                                        isLoading={categoriasSelectLoading}
                                                                        styles={{
                                                                            ...selectStyle,
                                                                            input: (styles) => ({
                                                                                ...styles,
                                                                                ...dot()
                                                                            }),
                                                                            singleValue: (styles, { data }) => ({
                                                                                ...styles,
                                                                                ...dot(data.color)
                                                                            }),
                                                                        }}
                                                                    />
                                                                    <label htmlFor="selectEstatus" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estatus</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'estatus') !== undefined &&
                                                                <Link to='/estatus/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.estatus_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Departamento--'
                                                                        options={departamentos}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(departamento) => setDepartamentoId(departamento.value)}
                                                                        isLoading={categoriasSelectLoading}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectDepartamentos" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Departamento</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'departamentos') !== undefined &&
                                                                <Link to='/departamentos/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.departamento_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Usuario--'
                                                                        options={administrativos}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(admin) => setAsignadoId(admin.value)}
                                                                        isLoading={administrativos.length === 0}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectAsignado" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Asignar Petición a Usuario</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <Link to='/usuarios/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.asignado_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <ExclamationCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Prioridad--'
                                                                        options={prioridades}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        onChange={(prioridad) => setPrioridadId(prioridad.value)}
                                                                        isLoading={categoriasSelectLoading}
                                                                        styles={{
                                                                            ...selectStyle,
                                                                            input: (styles) => ({
                                                                                ...styles,
                                                                                ...dot()
                                                                            }),
                                                                            singleValue: (styles, { data }) => ({
                                                                                ...styles,
                                                                                ...dot(data.color)
                                                                            }),
                                                                        }}
                                                                    />
                                                                    <label htmlFor="selectPrioridades" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Prioridad</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'prioridades') !== undefined &&
                                                                <Link to='/prioridades/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.prioridad_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 bg-white group">
                                                            <div className="w-1/12 pt-2 flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={(e) => setVenceEn(e.target.value)} id='dateVenceEn' type="datetime-local" name="vence_en" min={new Date(today).toISOString().slice(0, new Date(today).toISOString().lastIndexOf(":"))} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                                                <label htmlFor="dateVenceEn" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Vence En <em>(Opcional)</em></label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.vence_en}</span>
                                                    </div>
                                                    <div className='relative border border-gray-400 rounded p-5'>
                                                        <span className='absolute text-sm left-2 -top-3 bg-gray-50 px-1 text-gray-400'>Tiempo Invertido</span>
                                                        <div className='flex justify-between items-center w-full mb-2'>
                                                            <div className='w-full group'>
                                                                <label htmlFor="ti_horas" className='group-focus-within:text-accent-1 transition-colors mr-3'>Horas</label>
                                                                <input type="number" name='ti_horas' id='ti_horas' value={tiHoras} onChange={handleTIHoras} className='border-2 border-gray-400 rounded p-1 text-center text-slate-800 w-20 focus:outline-none focus:ring-0 focus:border-accent-1 transition-colors' min={0} />
                                                            </div>
                                                            <div className='w-full group'>
                                                                <label htmlFor="ti_minutos" className='group-focus-within:text-accent-1 transition-colors mr-3'>Minutos</label>
                                                                <select name="ti_minutos" id="ti_minutos" value={tiMinutos} onChange={handleTIMinutos} className='border-2 border-gray-400 text-slate-800 rounded p-1 px-4 w-20 focus:outline-none focus:ring-0 focus:border-accent-1 transition-colors'>
                                                                    {renderMinutos()}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm font-semibold italic text-accent-1'>{tiTareasMessage}</span>
                                                        <span className='text-sm text-red-500'>{errors.tiempo_invertido}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className='inline-flex gap-3 px-2 items-center'>
                                                            <input id='checkColaboradores' onChange={() => setColaboradores(!colaboradores)} className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                            <label htmlFor='checkColaboradores' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>Habilitar Colaboradores</label>
                                                            <ReactTooltip className='tooltip-default' anchorId='tooltipColaboradores' content='Solo los usuarios que estén dentro de colaboradores podrán dar respuestas a esta petición' place="top" />
                                                            <InformationCircleIcon id='tooltipColaboradores' className='h-5 text-accent-1' />
                                                        </div>
                                                    </div>
                                                    <div className='' hidden={!colaboradores}>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Usuarios--'
                                                                        isMulti
                                                                        options={administrativos}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        closeMenuOnSelect={false}
                                                                        onChange={(admins) => setSelectedColaboradores(admins.map((a) => a.value))}
                                                                        isLoading={administrativos.length === 0}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectColaboradores" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Colaboradores</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <div className='flex items-center'>
                                                                    <Link to='/usuarios/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                        <PlusIcon className='h-5' />
                                                                    </Link>
                                                                </div>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.selected_colaboradores}</span>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className='bg-white border flex justify-between items-center hover:bg-blue-50 hover:cursor-pointer' onClick={() => setTareasCollapsed(!tareas_collapsed)}>
                                            <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Tareas</h1>
                                            <ChevronRightIcon className={`w-9 h-9 text-accent-1 ${tareas_collapsed && 'rotate-90'} duration-300 mr-4`} />
                                        </div>
                                        <Collapse isOpened={tareas_collapsed}>
                                            <TareasComponent tareas={tareas} handleTareas={setTareas} controls={['all']} />
                                        </Collapse>
                                        {tareas_collapsed && <hr />}
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" disabled={characterCount > 500} hidden={processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            {!hasEdited ?
                                                <Link to={user?.user_type === 'administrativos' ? '/peticiones' : '/peticiones/crear-panel'} hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                                : <button type='button' onClick={showConfirmation} hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</button>}
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CrearPeticionPage;