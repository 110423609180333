import React, { Component } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SidebarHeader from './SidebarHeader';

class Layout extends Component {

    constructor({ children }) {
        super();
        this.state = {
            children: children,
            currentUser: null,
            userWasUpdated: false,
            sidebar: true,
            peticionContext: null
        }
    }

    sidebar = (value) => {
        this.props.sidebar(value);
    }

    updatedUser = () => {
        this.setState({ userWasUpdated: !this.state.userWasUpdated })
    }

    setPeticionContext = (value) => {
        this.setState({ peticionContext: value });
    }


    render() {
        if (!localStorage.getItem('token')) {
            return <Navigate to='/login' />
        }
        return (
            <div className='overflow-hidden'>
                <SidebarHeader sidebar={this.sidebar} authUser={(user) => this.setState({ currentUser: user })} userUpdated={this.state.userWasUpdated} />
                <div className='contents'>
                    {this.state.children}
                </div>
                <Outlet 
                    context={{
                        user: this.state.currentUser, updateUser: this.updatedUser, 
                        peticionContext: this.state.peticionContext, setPeticionContext: this.setPeticionContext,
                    }} 
                />
            </div>
        );
    }
}

export default Layout;