import { CurrencyDollarIcon, DocumentTextIcon, StarIcon, TagIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import ReactModal from 'react-modal';
import { CogIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
var CryptoJS = require("crypto-js");

ReactModal.setAppElement('#root');

class CrearBecaPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            // Beca states
            tipo: 'porcentaje', valor: '', concepto_id: 0,
            // Concepto states 
            conceptos: [], nombre: '', descripcion: '', 
            // Request/Form states
            activeTab: 'Agregar', showModal: false, statusResult: 0, processingRequest: false, errors: [{tipo: '', valor: '', concepto_id: '', nombre: '', descripcion: ''}]
        }
    }

    componentDidMount() {
        // Get Conceptos
        RestClient.GetRequest(AppUrl.Conceptos).then((result) => {
            this.setState({ conceptos: result });
        });
    }

    componentDidUpdate(_prevProps, prevState){
        if (prevState.showModal !== this.state.showModal) {
            // Get Conceptos
            RestClient.GetRequest(AppUrl.Conceptos).then((result) => {
                this.setState({ conceptos: result });
            });
        }
    }

    handleOpenModal = () => {
        if (this.state.concepto_id === 0) {
            this.setState({ activeTab: 'Agregar', showModal: true });
        }
        else {
            this.setState({ activeTab: 'Editar', nombre: this.state.conceptos.find((c) => c.id === parseInt(this.state.concepto_id))['nombre'], descripcion: this.state.conceptos.find((c) => c.id === parseInt(this.state.concepto_id))['descripcion'], showModal: true });
        }
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleConceptos = () => {
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{tipo: '', valor: '', concepto_id: '', nombre: '', descripcion: ''}] });

        const data = new FormData();
        data.append('nombre', this.state.nombre);
        data.append('descripcion', this.state.descripcion);

        RestClient.PostRequest(this.state.activeTab === 'Agregar' ? AppUrl.CreateConcepto : AppUrl.UpdateConcepto + this.state.concepto_id, data).then((result) => {
            this.setState({ processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
                this.setState({ concepto_id: result.id });
                this.handleCloseModal();
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    handleDeleteConcepto = () => {
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{tipo: '', valor: '', concepto_id: '', nombre: '', descripcion: ''}] });

        RestClient.GetRequest(AppUrl.DeleteConcepto + this.state.concepto_id).then((result) => {
            this.setState({ processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else if (result.status === 204) {
                toast.warn(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
            this.handleCloseModal();
            //TO DO: Refresh page upon delete
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{tipo: '', valor: '', concepto_id: '', nombre: '', descripcion: ''}] });

        const data = new FormData();
        data.append('tipo', this.state.tipo);
        data.append('valor', this.state.valor);
        data.append('concepto_id', this.state.concepto_id);

        RestClient.PostRequest(AppUrl.CreateBeca, data).then((result) => {
            this.setState({ statusResult: result.status, processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'becas' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/becas-descuentos' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                <div className='sm:px-0 px-3'>
                    {/* Page Title */}
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Crear Beca</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                        <p className='text-gray-400'>Finanzas</p>
                        <span className='text-xs'>/</span>
                        <Link to='/becas-descuentos' className='font-semibold hover:underline'>Becas y Descuentos</Link>
                        <span className='text-xs'>/</span>
                        <p className='text-dark-purple font-semibold'>Crear Beca</p>
                    </div>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='sm:w-11/12 w-full sm:my-8 my-2'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Registrar Beca</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Beca</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => this.setState({ concepto_id: e.target.value })} id='selectConcepto' name="concepto_id" value={this.state.concepto_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Concepto --</option>
                                                                    {this.state.conceptos.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectConcepto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Concepto</label>
                                                            </div>
                                                        </div>
                                                        <button type='button' onClick={this.handleOpenModal} title='Conceptos' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                            <CogIcon className='h-5' />
                                                        </button>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors?.concepto_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <TagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => this.setState({ tipo: e.target.value })} id='selectTipo' name="tipo" defaultValue='porcentaje' className='block py-2.5 px-4 w-full text-sm bg-transparent border-l-2 border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer' required>
                                                                <option disabled className='text-gray-400'>-- Seleccione Tipo --</option>
                                                                <option value='porcentaje'>Porcentaje</option>
                                                                <option value='cantidad'>Cantidad</option>
                                                            </select>
                                                            <label htmlFor="selectTipo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors?.tipo}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            {this.state.tipo === 'cantidad' ? <CurrencyDollarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' /> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-2' viewBox="0 0 384 512">
                                                                <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128c0-35.3-28.7-64-64-64S0 92.7 0 128s28.7 64 64 64s64-28.7 64-64zM384 384c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z"/>
                                                            </svg>}
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ valor: parseFloat(e.target.value).toFixed(2) })} onBlur={(e) => e.target.value = parseFloat(e.target.value).toFixed(2)}  id='txtValor' type="number" step={0.01} min={0.01} name="valor" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtValor" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Valor</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors?.valor}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={this.state.processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/becas-descuentos' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReactModal
                    closeTimeoutMS={800}
                    isOpen={this.state.showModal}
                    contentLabel="Default Confirmation Modal"
                    className='Modal'
                    overlayClassName="Overlay"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="flex flex-col items-start">
                        <div className="flex items-center w-full border-b-2">
                            <div className="bg-white">
                                <nav className="flex flex-col sm:flex-row">
                                    <button onClick={() => this.setState({activeTab: 'Agregar', nombre: '', descripcion: '' })} className={`py-4 px-6 block hover:text-accent-1 focus:outline-none ${this.state.activeTab === 'Agregar' ? 'text-accent-1 border-b-2 font-medium border-accent-2' : 'text-gray-600'}`}>
                                        Agregar Concepto
                                    </button><button onClick={() => this.setState({activeTab: 'Editar', nombre: this.state.conceptos.find((c) => c.id === parseInt(this.state.concepto_id))['nombre'], descripcion: this.state.conceptos.find((c) => c.id === parseInt(this.state.concepto_id))['descripcion']})} className={`${this.state.concepto_id === 0 ? 'hidden' : ''} py-4 px-6 block hover:text-accent-1 focus:outline-none ${this.state.activeTab === 'Editar' ? 'text-accent-1 border-b-2 font-medium border-accent-2' : 'text-gray-600'}`}>
                                        Editar Concepto
                                    </button>
                                </nav>
                            </div>
                            <button onClick={this.handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                                <XIcon className='w-5 h-5' />
                            </button>
                        </div>
                        <div className="border-b-2 w-full py-4">
                            <form className='mt-4 mx-8 space-y-7'>
                                <div className='mb-4'>
                                    <div className="w-full inline-flex gap-4">
                                        <div className='w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group'>
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <StarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ nombre: e.target.value })} value={this.state.nombre} id='txtConceptoNombre' type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                <label htmlFor="txtConceptoNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                            </div>
                                        </div>
                                        <button type='button' onClick={this.handleDeleteConcepto}
                                            id='btnEliminarConcepto' title='Eliminar Concepto' className={`${(this.state.activeTab === 'Agregar' || this.state.processingRequest) ? 'hidden' : ''} p-3 bg-red-500 flex gap-1 items-center rounded-full shadow-lg hover:bg-red-600 hover:-translate-y-1 transition duration-300`}>
                                            <TrashIcon className='h-5 w-5 text-white' />
                                        </button>
                                    </div>
                                    <span className='text-sm text-red-500'>{this.state.errors?.nombre}</span>
                                </div>
                                <div className=''>
                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                        <div className="w-1/12 flex justify-center items-center">
                                            <DocumentTextIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                        </div>
                                        <div className="relative z-0 w-full group">
                                            <textarea onChange={(e) => this.setState({ descripcion: e.target.value })} value={this.state.descripcion} id='taDescripcion' type="text" name="descripcion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                            <label htmlFor="taDescripcion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Descripcion</label>
                                        </div>
                                    </div>
                                    <span className='text-sm text-red-500'>{this.state.errors?.descripcion}</span>
                                </div>
                            </form>
                        </div>
                        <div className="flex justify-end w-full mt-4 gap-4">
                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-1 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </button>
                            <button onClick={this.handleConceptos} type='button' hidden={this.state.processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">
                                Guardar
                            </button>
                            <button onClick={this.handleCloseModal} type='button' hidden={this.state.processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

export default CrearBecaPage;