import { CalendarIcon, ExclamationCircleIcon, FlagIcon, TagIcon, TicketIcon, UserGroupIcon, UserIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { XIcon, PencilAltIcon, PlusIcon, InformationCircleIcon } from '@heroicons/react/solid';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactModal from 'react-modal';
import DetailsComponent from '../../components/details/DetailsComponent';
import { colorAccent2 } from '../../components/CustomTableComponents';
var CryptoJS = require("crypto-js");

const selectStyle = {
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? colorAccent2 : state.isFocused ? '#DEEBFF' : 'white',
    }),
}

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

// Warn user about reloading site
window.onbeforeunload = function () {
    if (window.location.pathname.includes('/peticiones/editar/')) {
        return "";
    }
};

export const ResponseContext = React.createContext();

ReactModal.setAppElement('#root');

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarPeticionPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Peticion states
    const [titulo, setTitulo] = useState('Cargando...');
    const [descripcion, setDescripcion] = useState('');
    const [categoria_id, setCategoriaId] = useState(0);
    const [subcategoria_id, setSubcategoriaId] = useState(0);
    const [estatus_obj, setEstatusObj] = useState({ id: 0, nombre: '', color: '' });
    const [usuario_id, setUsuarioId] = useState(0);
    const [persona, setPersona] = useState(null);
    const [departamento_id, setDepartamentoId] = useState(0);
    const [asignado_id, setAsignadoId] = useState(0);
    const [prioridad_id, setPrioridadId] = useState(0);
    const [vence_en, setVenceEn] = useState('');
    const [tiempo_invertido, setTiempoInvertido] = useState(0);
    const [tareas_tiempo_invertido, setTareasTiempoInvertido] = useState(0);
    const [archivos_adjuntos, setArchivosAdjuntos] = useState('');
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [estatus, setEstatus] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [prioridades, setPrioridades] = useState([]);
    const [mensaje, setMensaje] = useState("[{\"type\":\"paragraph\",\"children\":[{\"text\":\"\"}]}]");
    const [respuesta_adjuntos, setRespuestaAdjuntos] = useState('');
    const [usuarios_tareas, setUsuariosTareas] = useState([]);

    // Request/Form states
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{ titulo: '', descripcion: '', categoria_id: '', subcategoria_id: '', estatus_id: '', usuario_id: '', departamento_id: '', asignado_id: '', prioridad_id: '', archivos_adjuntos: '', selected_colaboradores: '', vence_en: '', tiempo_invertido: '' }]);
    const [usersAccess, setUsersAccess] = useState([{}]);
    const [showReopenModal, setShowReopenModal] = useState(false);
    const [reopen_estatus, setReopenEstatus] = useState('');
    const [hasEdited, setHasEdited] = useState(false);
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [colaboradores, setColaboradores] = useState(false);
    const [selected_colaboradores, setSelectedColaboradores] = useState([]);
    const [ownerWithColaboradores, setOwnerWithColaboradores] = useState(null);
    const [today, setToday] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [tiHoras, setTiHoras] = useState(0);
    const [tiMinutos, setTiMinutos] = useState(0);
    const [tiTareasMessage, setTiTareasMessage] = useState('');
    const [updaterCount, setUpdaterCount] = useState(0);

    //Get the passed :id from parent and pass it to the component to render it
    const peticion = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
        let today_str = new Date().toLocaleString("en-US", { timeZone: "America/Phoenix" });
        let today = new Date(today_str);
        today = today.setHours(today.getHours() - 7);
        setToday(today);
    });

    // Get Petición by Id
    useEffect(() => {
        if (user || updaterCount) {
            RestClient.GetRequest(AppUrl.PeticionById + peticion.id).then((result) => {
                setTitulo(result.titulo);
                setDescripcion(result.descripcion);
                setCategoriaId(parseInt(result.categoria_id));
                handleCategoriaSelected(parseInt(result.categoria_id));
                setSubcategoriaId(parseInt(result.subcategoria_id));
                setEstatusObj({ id: parseInt(result.estatus_id), nombre: result.estatus, color: result.color, tipo: result.tipo });
                setUsuarioId(parseInt(result.usuario_id));
                setPersona({ id: parseInt(result.persona_id), nombre: result.nombre, apellido: result.apellido, type: result.user_type, type_id: parseInt(result.user_type_id) });
                setDepartamentoId(parseInt(result.departamento_id));
                setAsignadoId(parseInt(result.asignado_id));
                setPrioridadId(parseInt(result.prioridad_id));
                setVenceEn(result.vence_en);
                setTiempoInvertido(result.tiempo_invertido);
                setTiHoras(Math.floor(result.tiempo_invertido / 60));
                setTiMinutos(result.tiempo_invertido % 60)
                setTareasTiempoInvertido(result.tareas_tiempo_invertido);
                setSelectedColaboradores(result.colaboradores[0] !== "" ? result.colaboradores.map(c => parseInt(c)) : []);
                setArchivosAdjuntos(result.adjuntos);
                setOwnerWithColaboradores(result.colaboradores[0] !== "" && user.id !== parseInt(result.asignado_id) && user?.user_type === 'administrativos');
                setLoaded(true);
            });
        }
        // Get colaboradores that are in Tareas and set them as Fixed
        RestClient.GetRequest(AppUrl.TareasByPeticionId + peticion.id).then((result) => {
            setUsuariosTareas(result.map(t => parseInt(t.usuario_id)));
        });
    }, [peticion.id, user, updaterCount]);

    // Handle Colaboradores Check
    useEffect(() => {
        setColaboradores(selected_colaboradores.length > 0 || usuarios_tareas.length > 0);
    }, [selected_colaboradores.length, usuarios_tareas.length])

    // Load Categorias, Subcategorias, Estatus, Departamentos, Prioridades
    useEffect(() => {
        // Get Categorias
        RestClient.GetRequest(AppUrl.Categorias).then((result) => {
            setCategorias([]);
            result.forEach(element => {
                setCategorias(prevState => [...prevState, { value: element.id, label: element.nombre }]);
            });
        });
        // Get Estatus
        RestClient.GetRequest(AppUrl.Estatus).then((result) => {
            var options = [];
            var groupedOptions = [];
            result.forEach((element, index) => {
                var group = element.tipo;
                if ((index + 1) !== result.length && group === result[index + 1].tipo) {
                    options.push({ value: element.id, label: element.nombre, color: element.color });
                }
                else {
                    options.push({ value: element.id, label: element.nombre, color: element.color });
                    groupedOptions.push({ label: 'Tipo: ' + group, options: options });
                    options = [];
                }
            });
            setEstatus(groupedOptions);
        });
        // Get Departamentos
        RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
            setDepartamentos([]);
            result.forEach(element => {
                setDepartamentos(prevState => [...prevState, { value: element.id, label: element.nombre }]);
            });
        });
        // Get Usuarios Administrativos
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({ label: depto, options: [{ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido }] });
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido });
                }
            });
            setUsuarios(groupedOptions);
        });
        // Get Prioridades
        RestClient.GetRequest(AppUrl.Prioridades).then((result) => {
            setPrioridades([]);
            result.forEach(element => {
                setPrioridades(prevState => [...prevState, { value: element.id, label: element.nombre, color: element.color }]);
            });
        });
    }, []);
    // Prevent scroll when Reopen Modal is open
    useEffect(() => {
        let html = document.querySelector('html');
        showReopenModal && (html.style.overflowY = 'hidden');
        !showReopenModal && (html.style.overflowY = 'unset');
    }, [showReopenModal])
    // Check if user has edited
    useEffect(() => {
        if (mensaje !== "[{\"type\":\"paragraph\",\"children\":[{\"text\":\"\"}]}]" || respuesta_adjuntos !== '') {
            setHasEdited(true);
        }
    }, [mensaje, respuesta_adjuntos]);

    // Update Tiempo Invertido
    useEffect(() => {
        const tiPeticion = (parseInt(tiHoras, 10) * 60) + parseInt(tiMinutos, 10);
        const tiTareasHours = Math.floor(tareas_tiempo_invertido / 60);
        const tiTareasMinutes = tareas_tiempo_invertido % 60;
        const hourString = (tiTareasHours === 1) ? 'hora' : 'horas';
        const minuteString = (tiTareasMinutes === 1) ? 'minuto' : 'minutos';

        if (!isNaN(tiPeticion) && tiPeticion >= 0) {
            const tiPeticionRemainder = Math.abs(tiPeticion - tareas_tiempo_invertido);
            setTiempoInvertido(tareas_tiempo_invertido + tiPeticionRemainder);
        }

        if (tareas_tiempo_invertido) {
            setTiTareasMessage(`Tiempo invertido en Tareas: ${tiTareasHours} ${hourString}, ${tiTareasMinutes} ${minuteString}`);
        } else {
            setTiTareasMessage('');
        }

        if (tiHoras < tiTareasHours) {
            setTiHoras(tiTareasHours);
        }
        if (tiMinutos < tiTareasMinutes) {
            setTiMinutos(tiTareasMinutes);
        }
    }, [tiHoras, tiMinutos, tareas_tiempo_invertido]);

    function showConfirmation() {
        if (window.confirm('¿Estás seguro de que quieres salir? Tu respuesta no se guardará') === true) {
            setHasConfirmed(true);
        }
    }

    function handleReopenModal() {
        setShowReopenModal(true);
    }

    function handleReopenModalClose() {
        setShowReopenModal(false);
    }

    function handleCategoriaSelected(categoria_id) {
        // Get Subcategorias
        setSubcategoriaId(0);
        RestClient.GetRequest(AppUrl.Subcategorias).then((result) => {
            setSubcategorias([]);
            result.forEach(element => {
                if (element.categorias.some(c => c.id === categoria_id)) {
                    setSubcategorias(prevState => [...prevState, { value: element.id, label: element.nombre }]);
                }
            });
        });
    }

    function reopenPeticion() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('estatus_id', reopen_estatus);
        data.append('usuario_id', user.id);
        data.append('user_name', user.nombre);

        RestClient.PostRequest(AppUrl.ReopenPeticion + peticion.id, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                handleReopenModalClose();
                reloadPeticion();
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function reloadPeticion() {
        setLoaded(false);
        RestClient.GetRequest(AppUrl.PeticionById + peticion.id).then((result) => {
            setTitulo(result.titulo);
            setDescripcion(result.descripcion);
            setCategoriaId(result.categoria_id);
            handleCategoriaSelected(result.categoria_id);
            setSubcategoriaId(result.subcategoria_id);
            setEstatusObj({ id: result.estatus_id, nombre: result.estatus, color: result.color, tipo: result.tipo });
            setUsuarioId(result.usuario_id);
            setPersona({ id: result.persona_id, nombre: result.nombre, apellido: result.apellido, type: result.user_type, type_id: result.user_type_id });
            setDepartamentoId(result.departamento_id);
            setPrioridadId(result.prioridad_id);
            setArchivosAdjuntos(result.adjuntos);
        });
    }

    function handleColaboradores(newValue, actionMeta) {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (usuarios_tareas.some(t => actionMeta.removedValue.value === t)) {
                    return;
                }
                break;
            case 'clear':
                newValue = usuarios.map(u => u.options).flat().filter(u => usuarios_tareas.some(t => u.value === t));
                break;
            default:
                break;
        }
        setSelectedColaboradores(newValue.map(v => v.value));
    }

    function updateColaboradores(updatingFlag, newColaborador = null, removingColaborador = null) {
        let newColaboradores = [];
        let index = 0;
        switch (updatingFlag) {
            // Keeps user in colaboradores and adds 'newColaborador'
            case 'ADD_USER':
            case 'KEEP_USER':
                if (!selected_colaboradores.some(sc => sc === newColaborador)) {
                    setSelectedColaboradores(prevState => [...prevState, newColaborador]);
                    newColaboradores = [...selected_colaboradores, newColaborador];
                } else {
                    newColaboradores = selected_colaboradores;
                }
                break;
            // Removes user from colaboradores and adds 'newColaborador'
            case 'REMOVE_USER':
                index = selected_colaboradores.findIndex(sc => sc === removingColaborador);
                newColaboradores = [...selected_colaboradores];
                newColaboradores.splice(index, 1, newColaborador);
                setSelectedColaboradores(newColaboradores);
                break;
            // Removes user from colaboradores
            case 'DELETE_USER':
                index = selected_colaboradores.findIndex(sc => sc === removingColaborador);
                newColaboradores = [...selected_colaboradores];
                newColaboradores.splice(index, 1);
                setSelectedColaboradores(newColaboradores);
                break;
            default:
                break;
        }
        if (selected_colaboradores !== newColaboradores) {
            setProcessingRequest(true);

            const data = new FormData();
            data.append('usuario_id', user.id);
            if (selected_colaboradores.length > 0 && colaboradores) {
                data.append('selected_colaboradores', JSON.stringify(newColaboradores));
            }

            RestClient.PostRequest(AppUrl.UpdateColaboradores + peticion.id, data).then((result) => {
                setProcessingRequest(false);
                if (result.status === 200) {
                    toast.success(result.message);
                    RestClient.GetRequest(AppUrl.TareasByPeticionId + peticion.id).then((result) => {
                        setUsuariosTareas(result.map(t => parseInt(t.usuario_id)));
                    });
                }
                else if (result.status === 204) {
                    toast.info(result.message);
                }
                else {
                    console.log(result);
                    toast.error(result.message);
                }
            });
            // Else, fetch users in Tareas
        } else {
            RestClient.GetRequest(AppUrl.TareasByPeticionId + peticion.id).then((result) => {
                setUsuariosTareas(result.map(t => parseInt(t.usuario_id)));
            });
        }
    }

    function handleTooltip() {
        setIsOpen(true);
        setTimeout(() => setIsOpen(false), 5000);
    }

    function renderMinutos() {
        var values = [];
        for (let index = 0; index < 60; index++) {
            values.push(<option key={index} value={index}>{index}</option>);
        }
        return values;
    }

    function handleTIHoras(e) {
        const horas = e.target.value;
        const tareaHours = Math.floor(tareas_tiempo_invertido / 60);

        setTiHoras(Math.max(horas, tareaHours));
    }

    function handleTIMinutos(e) {
        const minutos = e.target.value;
        const tareaMinutes = tareas_tiempo_invertido % 60;

        setTiMinutos(Math.max(minutos, tareaMinutes));
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        setProcessingRequest(true);
        setErrors([{ titulo: '', descripcion: '', categoria_id: '', subcategoria_id: '', estatus_id: '', usuario_id: '', departamento_id: '', asignado_id: '', prioridad_id: '', archivos_adjuntos: '', selected_colaboradores: '', vence_en: '', tiempo_invertido: '' }]);

        const data = new FormData();
        data.append('titulo', titulo);
        data.append('descripcion', descripcion);
        data.append('categoria_id', categoria_id);
        data.append('subcategoria_id', subcategoria_id);
        data.append('estatus_id', estatus_obj.id);
        data.append('usuario_id', user.id);
        data.append('solicitante_id', usuario_id);
        data.append('user_name', user.nombre);
        data.append('departamento_id', departamento_id === null ? '' : departamento_id);
        data.append('asignado_id', asignado_id === null ? '' : asignado_id);
        data.append('prioridad_id', prioridad_id === null ? '' : prioridad_id);
        data.append('vence_en', vence_en === null ? '' : vence_en);
        data.append('tiempo_invertido', tiempo_invertido);
        for (let i = 0; i < respuesta_adjuntos.length; i++) {
            data.append('archivos_adjuntos[]', respuesta_adjuntos[i]);
        };
        if (mensaje !== "[{\"type\":\"paragraph\",\"children\":[{\"text\":\"\"}]}]") {
            data.append('respuesta', mensaje);
        }
        data.append('colaboradores', colaboradores ? 1 : 0);
        if (selected_colaboradores.length > 0 && colaboradores) {
            data.append('selected_colaboradores', JSON.stringify(selected_colaboradores));
        }

        RestClient.PostRequest(AppUrl.UpdatePeticion + peticion.id, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else if (result.status === 204) {
                toast.info(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    /* Validate permisos de roles */
    if (usersAccess.find(ua => ua.modulo_name === 'peticiones' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    /* Validate if user is Tutor, can only see their Peticiones */
    if (usuario_id !== '' && (user?.user_type === 'tutores' && usuario_id !== user.id)) {
        console.log('Denied by user type = NOT ADMIN');
        return <Navigate to='/access-denied' />
    }
    /* Validate if user is Admin and has clearence for the Department on their Role or is colaborator */
    if (departamento_id !== '' && (user?.user_type === 'administrativos' && ownerWithColaboradores && !selected_colaboradores.some(sc => user.id === parseInt(sc)) && !Object.values(usersAccess.find(ua => ua.deptos)).flat().includes(parseInt(departamento_id)))) {
        console.log('Denied by department clearence and not colaborator');
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200 || statusResult === 204 || hasConfirmed) {
        return <Navigate to='/peticiones' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
            <div className='sm:px-0 px-3'>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Editar Petición</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <Link to='/peticiones' className='font-semibold hover:underline'>Peticiones</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Editar Petición</p>
                </div>
            </div>

            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='sm:w-11/12 w-full sm:my-8 my-2'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border flex w-full p-4 justify-between items-center'>
                                    <h1 className='text-accent-1 sm:text-2xl text-xl'>Editar Petición <span className='ml-2 font-bold capitalize'>{titulo !== '' && `'${titulo}'`}</span></h1>
                                    {user && user.user_type === "administrativos" && estatus_obj.tipo === 'completado' && loaded && <button type="button" onClick={handleReopenModal} className="inline-flex items-center gap-1 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">
                                        <PencilAltIcon className='h-5' />
                                        Reabrir Petición
                                    </button>}
                                </div>
                                {!user || !loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4' encType="multipart/form-data">
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-start mb-4 gap-8">
                                            {/* First Column */}
                                            <div className="md:w-3/4 mx-auto lg:max-w-lg max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <TicketIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input disabled id='txtTitulo' defaultValue={titulo} type="text" name="titulo" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtTitulo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Título</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.titulo}</span>
                                                </div>
                                                <div className=''>
                                                    <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Descripción</h5>
                                                    <div className='cursor-not-allowed px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-1 peer'>
                                                        <TextEditorInput defaultValue={descripcion} disabled={true} />
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.descripcion}</span>
                                                </div>
                                                <div className='-translate-y-2'>
                                                    <h5 className='mb-2 text-sm text-gray-400'>Archivos Adjuntos</h5>
                                                    <main className="container mx-auto max-w-screen-lg h-full">
                                                        <article aria-label="File Upload Modal" className="relative h-full flex flex-col bg-white shadow-lg rounded-md">
                                                            <section className="h-full overflow-hidden px-8 py-6 w-full flex flex-col">
                                                                <h1 hidden={archivos_adjuntos.length === 0} className="font-semibold sm:text-lg text-gray-900">
                                                                    Adjuntos
                                                                </h1>
                                                                <ul id="gallery" className="flex flex-1 flex-wrap -m-1 mt-2">
                                                                    {archivos_adjuntos.length === 0 ? <li id="empty" className="h-full w-full text-center flex flex-col items-center justify-center">
                                                                        <img className="mx-auto w-32" src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png" alt="no data" />
                                                                        <span className="text-small text-gray-500">No se adjuntaron archivos</span>
                                                                    </li>
                                                                        : archivos_adjuntos.map((adj, index) => (
                                                                            <a key={index} href={AppUrl.ArchivosAdjuntos + adj.key} target="_blank" rel='noreferrer' download={adj.file_name} className='container w-1/2 cursor-pointer'>
                                                                                <li id={adj.id} className="flex p-1 w-full h-20">
                                                                                    <article tabIndex="0" className={`group ${adj.is_image === "1" && 'hasImage text-transparent hover:text-white'} w-full h-full rounded-md focus:outline-none focus:shadow-outline relative bg-gray-100 shadow-sm`}>
                                                                                        {adj.is_image === "1" && <img alt="upload preview" src={AppUrl.ArchivosAdjuntos + adj.key} className="w-full h-full sticky object-cover rounded-md bg-fixed" />}
                                                                                        <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                                                                            <h1 className={`flex-1 ${adj.is_image !== "1" && 'group-hover:text-blue-800'} `}>{adj.file_name}</h1>
                                                                                            <div className="flex justify-start items-center">
                                                                                                <p className={`p-1 text-xs ${!adj.is_image === "1" && 'text-gray-700'}`}>Tamaño: {adj.size}</p>
                                                                                            </div>
                                                                                        </section>
                                                                                    </article>
                                                                                </li>
                                                                            </a>
                                                                        ))}
                                                                </ul>
                                                            </section>
                                                        </article>
                                                    </main>
                                                    <span className='text-sm text-red-500'>{errors.archivos_adjuntos}</span>
                                                </div>
                                            </div>
                                            {/* Second Column */}
                                            {user.user_type === "administrativos" &&
                                                <div className="md:w-full mx-auto max-w-sm space-y-8">
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 pt-2 flex justify-center mx-0.5">
                                                                    <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <input disabled id='txtPersona' defaultValue={persona.nombre + ' ' + persona.apellido} type="text" name="persona" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                                    <label htmlFor="txtPersona" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Solicitante</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === persona.type) !== undefined &&
                                                                <Link to={`/${persona.type}/editar/` + persona.type_id} title='Editar' className='p-3 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.usuario_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <TagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Categoría--'
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores}
                                                                        options={categorias}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        value={categorias.find(c => c.value === categoria_id)}
                                                                        onChange={(categoria) => { setCategoriaId(categoria.value); handleCategoriaSelected(categoria.value) }}
                                                                        isLoading={categorias.length === 0}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectCategorias" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Categoría</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'categorias') !== undefined &&
                                                                <Link to={'/categorias-subcategorias/editar-categoria/' + categoria_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.categoria_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <TagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Subcategoría--'
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores}
                                                                        options={subcategorias}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        value={subcategoria_id > 0 ? subcategorias.find(sub => sub.value === subcategoria_id) : null}
                                                                        onChange={(subcategoria) => setSubcategoriaId(subcategoria.value)}
                                                                        isLoading={subcategorias.length === 0}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectSubcategorias" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Subcategoría</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'subcategorias') !== undefined &&
                                                                <Link to={'/categorias-subcategorias/editar-subcategoria/' + subcategoria_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.subcategoria_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <FlagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Estatus--'
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores}
                                                                        options={estatus}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        value={estatus.map(u => u.options).flat().find(u => u.value === estatus_obj.id)}
                                                                        onChange={(estatus) => setEstatusObj({ id: estatus.value })}
                                                                        isLoading={estatus.length === 0}
                                                                        styles={{
                                                                            ...selectStyle,
                                                                            singleValue: (styles, { data }) => ({
                                                                                ...styles,
                                                                                ...dot(data.color)
                                                                            }),
                                                                        }}
                                                                    />
                                                                    <label htmlFor="selectEstatus" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estatus</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'estatus') !== undefined &&
                                                                <Link to={'/estatus/editar/' + estatus_obj.id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PencilAltIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.estatus_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Departamento--'
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores}
                                                                        options={departamentos}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        value={departamentos.find(d => d.value === departamento_id)}
                                                                        onChange={(departamento) => setDepartamentoId(departamento.value)}
                                                                        isLoading={departamentos.length === 0}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectDepartamentos" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Departamento</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'departamentos') !== undefined &&
                                                                <Link to='/departamentos/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.departamento_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Usuario--'
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores || (asignado_id !== null && vence_en !== null && asignado_id !== user.id)}
                                                                        options={usuarios}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        value={asignado_id !== null && usuarios.map(u => u.options).flat().find(u => u.value === asignado_id)}
                                                                        onChange={(usuario) => setAsignadoId(usuario.value)}
                                                                        isLoading={usuarios.length === 0}
                                                                        styles={selectStyle}
                                                                    />
                                                                    <label htmlFor="selectAsignado" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Asignar Petición a Usuario</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <Link to='/usuarios/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.asignado_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <ExclamationCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Prioridad--'
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores}
                                                                        options={prioridades}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        value={prioridades.find(p => p.value === prioridad_id)}
                                                                        onChange={(prioridad) => setPrioridadId(prioridad.value)}
                                                                        isLoading={prioridades.length === 0}
                                                                        styles={{
                                                                            ...selectStyle,
                                                                            singleValue: (styles, { data }) => ({
                                                                                ...styles,
                                                                                ...dot(data.color)
                                                                            }),
                                                                        }}
                                                                    />
                                                                    <label htmlFor="selectPrioridades" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Prioridad</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'prioridades') !== undefined &&
                                                                <Link to='/prioridades/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                    <PlusIcon className='h-5' />
                                                                </Link>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.prioridad_id}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                            <div className="w-1/12 pt-2 flex justify-center">
                                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input onChange={(e) => { let [yyyy, mm, dd, hh, mi] = e.target.value.split(/[/:\-T]/); setVenceEn(`${yyyy}-${mm}-${dd} ${hh}:${mi}`) }} defaultValue={vence_en} disabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores || (asignado_id !== null && parseInt(asignado_id) !== user.id)} id='dateVenceEn' type="datetime-local" name="vence_en" min={new Date(today).toISOString().slice(0, new Date(today).toISOString().lastIndexOf(":"))} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                                                <label htmlFor="dateVenceEn" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Vence En <em>(Opcional)</em></label>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.vence_en}</span>
                                                    </div>
                                                    <div className='relative border border-gray-400 rounded p-5'>
                                                        <span className='absolute text-sm left-2 -top-3 bg-gray-50 px-1 text-gray-400'>Tiempo Invertido</span>
                                                        <div className='flex justify-between items-center w-full mb-2'>
                                                            <div className='w-full group'>
                                                                <label htmlFor="ti_horas" className='group-focus-within:text-accent-1 transition-colors mr-3'>Horas</label>
                                                                <input type="number" name='ti_horas' id='ti_horas' value={tiHoras} onChange={handleTIHoras} className='border-2 border-gray-400 rounded p-1 text-center text-slate-800 w-20 focus:outline-none focus:ring-0 focus:border-accent-1 transition-colors' min={0} />
                                                            </div>
                                                            <div className='w-full group'>
                                                                <label htmlFor="ti_minutos" className='group-focus-within:text-accent-1 transition-colors mr-3'>Minutos</label>
                                                                <select name="ti_minutos" id="ti_minutos" value={tiMinutos} onChange={handleTIMinutos} className='border-2 border-gray-400 text-slate-800 rounded p-1 px-4 w-20 focus:outline-none focus:ring-0 focus:border-accent-1 transition-colors'>
                                                                    {renderMinutos()}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <span className='text-sm font-semibold italic text-accent-1'>{tiTareasMessage}</span>
                                                        <span className='text-sm text-red-500'>{errors.tiempo_invertido}</span>
                                                    </div>
                                                    <div className=''>
                                                        <div className='inline-flex gap-3 px-2 items-center'>
                                                            <ReactTooltip isOpen={isOpen} className='tooltip-default' anchorId='checkColaboradores' content='Esta petición cuenta con tareas. Debes eliminar las tareas primero antes de deshabilitar colaboradores.' place="top" />
                                                            <input id='checkColaboradores' onChange={() => usuarios_tareas.length > 0 ? handleTooltip() : setColaboradores(!colaboradores)} checked={colaboradores} disabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores} className='accent-accent-2 peer' type="checkbox" style={{ transform: 'scale(1.5)' }} />
                                                            <label htmlFor='checkColaboradores' className='text-base peer-disabled:text-gray-400 text-gray-800 text-light font-normal'>Habilitar Colaboradores</label>
                                                            <ReactTooltip className='tooltip-default' anchorId='tooltipColaboradores' content='Solo los usuarios que estén dentro de colaboradores podrán dar respuestas a esta petición' place="top" />
                                                            <InformationCircleIcon id='tooltipColaboradores' className='h-5 text-accent-1' />
                                                        </div>
                                                    </div>
                                                    <div className='' hidden={!colaboradores}>
                                                        <div className="w-full inline-flex gap-4">
                                                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                    <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                                </div>
                                                                <div className="relative z-0 w-full group">
                                                                    <Select placeholder='--Seleccionar Usuarios--'
                                                                        isMulti
                                                                        isClearable={selected_colaboradores.some(sc => !usuarios_tareas.some(t => t === sc))}
                                                                        isDisabled={estatus_obj.tipo === 'completado' || ownerWithColaboradores}
                                                                        options={usuarios}
                                                                        menuPortalTarget={document.body}
                                                                        className='p-1'
                                                                        closeMenuOnSelect={false}
                                                                        value={usuarios.map(u => u.options).flat().filter(u => selected_colaboradores.some(sc => u.value === sc))}
                                                                        onChange={handleColaboradores}
                                                                        isLoading={usuarios.length === 0}
                                                                        styles={{
                                                                            ...selectStyle,
                                                                            multiValue: (base, state) => {
                                                                                return usuarios_tareas.some(t => state.data.value === t) ? {
                                                                                    ...base,
                                                                                    backgroundColor: '#3730A3',
                                                                                    minWidth: '167px',
                                                                                    ":hover div": {
                                                                                        display: 'none'
                                                                                    },
                                                                                    ":hover:before": {
                                                                                        content: '"En Tareas"',
                                                                                        textAlign: 'center',
                                                                                        fontWeight: 'bold',
                                                                                        color: 'white',
                                                                                        fontSize: '85%',
                                                                                        fontStyle: 'italic',
                                                                                        padding: 3,
                                                                                        paddingLeft: 6,
                                                                                        paddingRight: 6,
                                                                                        width: '100%'
                                                                                    }
                                                                                } : base;
                                                                            },
                                                                            multiValueLabel: (base, state) => {
                                                                                return usuarios_tareas.some(t => state.data.value === t) ? {
                                                                                    ...base,
                                                                                    fontWeight: 'bold', color: 'white', paddingRight: 6,
                                                                                }
                                                                                    : base;
                                                                            },
                                                                            multiValueRemove: (base, state) => {
                                                                                return usuarios_tareas.some(t => state.data.value === t) ? { ...base, display: 'none' } : base;
                                                                            },
                                                                        }}
                                                                    />
                                                                    <label htmlFor="selectColaboradores" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Colaboradores</label>
                                                                </div>
                                                            </div>
                                                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined &&
                                                                <div className='flex items-center'>
                                                                    <Link to='/usuarios/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                        <PlusIcon className='h-5' />
                                                                    </Link>
                                                                </div>}
                                                        </div>
                                                        <span className='text-sm text-red-500'>{errors.selected_colaboradores}</span>
                                                    </div>
                                                </div>}
                                        </div>
                                        <hr />
                                        <ResponseContext.Provider
                                            value={{
                                                peticionClosed: estatus_obj.tipo === 'completado', peticion_id: peticion.id, isPeticionOwner: !ownerWithColaboradores,
                                                setMensaje: setMensaje, setRespuestaAdjuntos: setRespuestaAdjuntos, setColaboradores: updateColaboradores, setUpdaterCount: setUpdaterCount,
                                                errors: errors.archivos_adjuntos
                                            }}
                                        >
                                            <DetailsComponent peticion_id={peticion.id} permissions={usersAccess} disabled={ownerWithColaboradores && !selected_colaboradores.some(sc => sc === user?.id)} />
                                        </ResponseContext.Provider>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest || showReopenModal} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest || estatus_obj.tipo === 'completado'} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            {!hasEdited ?
                                                <Link to='/peticiones' hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>{estatus_obj.tipo === 'completado' ? 'Regresar' : 'Cancelar'}</Link>
                                                : <button type='button' onClick={showConfirmation} hidden={processingRequest} className={`${!processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</button>}
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactModal
                preventScroll={true}
                closeTimeoutMS={800}
                isOpen={showReopenModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={handleReopenModalClose}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Reabrir Petición</div>
                        <button onClick={handleReopenModalClose} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4 px-4 space-y-6">
                        <p>Nota: Al reabrir la petición se le notificará al dueño que se abrió de nuevo. Seleccione un estatus para reabrir la petición.</p>
                        <div className="w-full inline-flex gap-4 my-4">
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 items-center flex justify-center mx-0.5">
                                    <FlagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <Select placeholder='--Seleccionar Estatus--'
                                        options={estatus.filter(e => e.label === "Tipo: en progreso")}
                                        menuPortalTarget={document.body}
                                        className='p-1'
                                        onChange={(estatus) => setReopenEstatus(estatus.value)}
                                        isLoading={estatus.length === 0}
                                        styles={{
                                            ...selectStyle,
                                            singleValue: (styles, { data }) => ({
                                                ...styles,
                                                ...dot(data.color)
                                            }),
                                            menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999
                                            }),
                                        }}
                                    />
                                    <label htmlFor="selectEstatus" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estatus</label>
                                </div>
                            </div>
                            {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'estatus') !== undefined &&
                                <Link to='/estatus/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                    <PlusIcon className='h-5' />
                                </Link>}
                        </div>
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={reopenPeticion} type='button' hidden={processingRequest} className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Guardar
                        </button>
                        <button onClick={handleReopenModalClose} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}

export default EditarPeticionPage