import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import CategoriasTable from '../../components/categorias-subcategorias/CategoriasTable';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import SubcategoriasTable from '../../components/categorias-subcategorias/SubcategoriasTable';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Collapse } from 'react-collapse';
var CryptoJS = require("crypto-js");

class CategoriaSubcategoriaPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloAccess = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'categorias') !== undefined) {
            _moduloAccess.push('categorias');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'subcategorias') !== undefined) {
            _moduloAccess.push('subcategorias');
        }
        var _categoriasPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'categorias') !== undefined) {
            _categoriasPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'categorias') !== undefined) {
            _categoriasPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'categorias') !== undefined) {
            _categoriasPermissions.push('delete');
        }
        var _subcategoriasPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'subcategorias') !== undefined) {
            _subcategoriasPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'subcategorias') !== undefined) {
            _subcategoriasPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'subcategorias') !== undefined) {
            _subcategoriasPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloAccess: _moduloAccess,
            categoriasPermissions: _categoriasPermissions,
            subcategoriasPermissions: _subcategoriasPermissions,
            dataCategorias: null, dataSubcategorias: null, filter: _moduloAccess[0], collapsed: window.innerWidth > 640
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Categorias).then((result) => {
            this.setState({ dataCategorias: result });
        });
        RestClient.GetRequest(AppUrl.Subcategorias).then((result) => {
            this.setState({ dataSubcategorias: result });
        });
    }

    render() {
        if (!this.state.moduloAccess.includes('categorias') && !this.state.moduloAccess.includes('subcategorias')) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                {/* Page Title */}
                <div className='sm:px-0 px-3'>
                    <div className='flex w-full'>
                        <h1 className='text-xl font-medium'>Categorías y Subcategorias</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                        <p className='text-gray-400'>Personalización</p>
                        <span className='text-xs'>/</span>
                        <Link to='/categorias-subcategorias' className='text-dark-purple font-semibold hover:underline'>Categorías y Subcategorías</Link>
                    </div>
                </div>
                {/* Data Table And Filter */}
                <div className='w-full flex justify-center sm:mt-6 mt-3'>
                    <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                        <div hidden={this.state.moduloAccess.length < 2} className='mx-12 mt-8 mb-8 bg-gray-100 rounded shadow-lg'>
                            <fieldset className="flex flex-col lg:flex-row w-full lg:items-center sm:p-5 py-2.5 px-5 sm:space-y-3 lg:space-y-0">
                                <div onClick={() => window.innerWidth <= 640 && this.setState({ collapsed: !this.state.collapsed })} className="xl:w-2/5 md:w-1/4 w-full h-full sm:flex-none inline-flex items-center justify-between">
                                    <h1 className="sm:text-base text-lg font-semibold">Filtrar por:</h1>
                                    <ChevronDownIcon className={`sm:hidden w-7 h-7 text-slate-500 ${this.state.collapsed && 'rotate-180'} duration-300`} />
                                </div>
                                <Collapse isOpened={this.state.collapsed}>                            
                                    <div className='flex flex-col md:flex-row w-full justify-start md:gap-8 gap-3 sm:mt-0 mt-3'>
                                        <div className="flex items-center gap-2">
                                            <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioCategorias" value="categorias" defaultChecked />
                                            <label className="font-semibold text-gray-600" htmlFor="radioCategorias">
                                                Categorías
                                            </label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioSubcategorias" value="subcategorias" />
                                            <label className="font-semibold text-gray-600" htmlFor="radioSubcategorias">
                                                Subcategorías
                                            </label>
                                        </div>
                                    </div>
                                </Collapse>
                            </fieldset>
                        </div>
                        <div className='sm:mx-12 mx-0 sm:my-8 my-2'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                {this.state.filter === 'categorias' ? 
                                <CategoriasTable data={this.state.dataCategorias} controls={this.state.categoriasPermissions} expandable={this.state.moduloAccess.includes('subcategorias') && this.state.subcategoriasPermissions.includes('update')} /> : 
                                <SubcategoriasTable data={this.state.dataSubcategorias} controls={this.state.subcategoriasPermissions} expandable={this.state.moduloAccess.includes('categorias') && this.state.categoriasPermissions.includes('update')} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoriaSubcategoriaPage;