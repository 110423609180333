import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { ChevronRightIcon} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import NoMessages from '../../resources/images/no-messages.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactModal from 'react-modal';
import RespuestaComponent from '../form-controls/RespuestaComponent';
import TextEditorInput from '../form-controls/TextEditorInput';
import { Node } from 'slate';
import ArchivosAdjuntosInput from '../form-controls/ArchivosAdjuntosInput';
import { Collapse } from 'react-collapse';
import { ResponseContext } from '../../pages/peticiones/EditarPeticionPage';
import TareasComponent from '../tareas/TareasComponent';

const ResponseInput = ({ hidden = false }) => {
    const [mensaje, setMensaje] = useState("[{\"type\":\"paragraph\",\"children\":[{\"text\":\"\"}]}]");
    const [characterCount, setCharacterCount] = useState(0);
    const [showAdjuntos, setShowAdjuntos] = useState(false);

    const context = React.useContext(ResponseContext);  

    function handleRespuestaText(value) {
        let mensajePlainText = '';
        let plainTextConcat = "";
        let plainTextArray = JSON.parse(value).map(n => Node.string(n));

        if (plainTextArray.length > 1) {
            plainTextArray.forEach(element => {
                plainTextConcat += element;
            });
            mensajePlainText += plainTextConcat;
        } else {
            mensajePlainText += plainTextArray[0];
        }
        context.setMensaje(value);
        setMensaje(value);
        setCharacterCount(mensajePlainText.length);
    }

    function handleArchivosAdjuntos(archivos) {
        let files = Object.keys(archivos).map(function (key) { return archivos[key] });
        context.setRespuestaAdjuntos(files)
    }

    return (
        !hidden && !context.peticionClosed && <>
            <hr className='translate-y-3' style={{ border: 'none', height: '2px', color: 'rgb(226 232 240)', backgroundColor: 'rgb(226 232 240)' }} />
            <div className='flex-col sm:w-11/12 w-full sm:ml-12 sm:p-4 py-4 sm:px-6 sm:pt-8'>
                <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Respuesta</h5>
                <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-2 peer'>
                    <TextEditorInput handleDescripcion={handleRespuestaText} defaultValue={mensaje} placeholder='Da una respuesta a esta petición...' />
                </div>
                <p className={`flex w-full justify-end text-xs ${characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{characterCount}/500</p>
                <span hidden={characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
            </div>
            <div className='flex-col -translate-y-6 sm:ml-12 sm:p-4 py-4 sm:px-6 sm:w-11/12 w-full'>
                <button type='button' onClick={() => setShowAdjuntos(!showAdjuntos)} className='inline-flex items-center mb-4 gap-1 hover:underline'>
                    <h5 className='text-sm text-gray-400'>Archivos Adjuntos (Opcional)</h5>
                    <ChevronRightIcon className={`${showAdjuntos ? 'rotate-90' : 'rotate-0'} h-4 duration-300`} />
                </button>
                <Collapse isOpened={showAdjuntos}>
                    <div className={'flex mx-auto w-full'}>
                        <div className='w-full'>
                            <ArchivosAdjuntosInput handleFiles={handleArchivosAdjuntos} />
                        </div>
                    </div>
                </Collapse>
                <span className='text-sm text-red-500'>{context.errors}</span>
            </div>
        </>
    )
}

const DetailsBody = (props) => {
    let {
        _data = null,
        activeTab = '',
        canEditUser = false,
        renderPlaceholder = false
    } = props

    const [data, setData] = useState(_data);
    const [showModal, setShowModal] = useState(false);
    const [messageLoaded, setMessageLoaded] = useState(false);
    const [modal_message, setModalMessage] = useState("[{\"type\":\"paragraph\",\"children\":[{\"text\":\"Cargando...\"}]}]");

    const context = React.useContext(ResponseContext);

    function tareasUpdated(tareas) {
        if (tareas !== data) {
            RestClient.GetRequest(AppUrl.TareasByPeticionId + context.peticion_id).then((result) => {
                setData(result);
            });
        }
    }

    function formatTitle(tab, item) {
        if (tab === 'respuestas') {
            if (canEditUser) {
                return <Link to={'/usuarios/editar/' + item.usuario_id} className='hover:underline'>{item.title}</Link>
            }
            else {
                return item.title;
            }
        }
        else {
            switch (item.title) {
                case 'created':
                    return 'creado';
                case 'updated':
                    return 'actualizado';
                case 'closed':
                    return 'cerrado';
                case 'reopened':
                    return 'Reabierto';
                case 'task_add':
                    return 'tarea agregada';
                case 'task_delete':
                    return 'tarea eliminada';
                default:
                    return '';
            }
        }
    }

    function FormatTimeHistory(date) {
        var newDate = new Date(date);
        return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString();
    }

    function handleOpenModal(id) {
        setMessageLoaded(false);
        setShowModal(true);
        RestClient.GetRequest(AppUrl.RespuestasById + id).then((result) => {
            setModalMessage(result.mensaje);
            setMessageLoaded(true);
        });
    }

    function handleCloseModal() {
        setShowModal(false);
    }

    function formatHistoryMessage(message) {
        let resp = message.match(/\{[0-9]*\}/i);
        if (resp) {
            let id = resp[0].slice(1, -1);
            message = message.replace(resp[0], '');
            return <div className='sm:inline-flex gap-2'>
                <p>{message}</p>
                <button type='button' onClick={() => handleOpenModal(id)} className='italic font-semibold hover:underline'>Click para ver mensaje</button>
            </div>
        }else if (message.startsWith('Descripción')) {
            const messageArray = message.split("'");
            let previousDescriptionPlainText = '';
            let newDescriptionPlainText = '';
            let plainTextConcat = "";
            let previousDescriptionArray = JSON.parse(messageArray[1]).map(n => Node.string(n));
            let newDescriptionArray = JSON.parse(messageArray[3]).map(n => Node.string(n));

            if (previousDescriptionArray.length > 1) {
                previousDescriptionArray.forEach(element => {
                    plainTextConcat += element;
                });
                previousDescriptionPlainText += plainTextConcat;
            } else {
                previousDescriptionPlainText += previousDescriptionArray[0];
            }
            plainTextConcat = "";
            if (newDescriptionArray.length > 1) {
                newDescriptionArray.forEach(element => {
                    plainTextConcat += element;
                });
                newDescriptionPlainText += plainTextConcat;
            } else {
                newDescriptionPlainText += newDescriptionArray[0];
            }
            messageArray.splice(1, 1, previousDescriptionPlainText);
            messageArray.splice(3, 1, newDescriptionPlainText);
            return <p>{messageArray.join("'")}</p>;
        }
        return <p>{message}</p>;
    }

    return (
        <>
        <div className={`${activeTab !== 'tareas' && 'flex p-4 lg:px-6 px-0'} w-full`}>
            {/* No Messages Placeholder */}
            {renderPlaceholder &&
                <div className='flex w-full justify-center'>
                    <div className='flex-col'>
                        <img src={NoMessages} width={380} height={268} alt="No hay respuestas" />
                        <h3 className='text-center font-semibold text-xl text-gray-300'>Aún no hay respuestas...</h3>
                    </div>
                </div>}
            {/* Respuestas and Historial List */}
            {activeTab === 'tareas' ?
                <TareasComponent tareas={data} handleTareas={tareasUpdated} controls={context.isPeticionOwner ? ['all'] : [] } canEditUser={canEditUser} peticionContext={context.setColaboradores} peticion_id={context.peticion_id} /> :
            <ul className='-my-2'>
                {data.map((item, index) => (
                    <li key={index} className='relative py-2'>
                        <div className='absolute left-0 h-full w-0.5 bg-slate-200 self-start ml-2.5 -translate-x-1/2 translate-y-3' aria-hidden="true"></div>
                        <ReactTooltip className='tooltip-default' anchorId={'tooltip' + index} content={'Estatus: ' + item.estatus} place="top" />
                        <div id={'tooltip' + index} className='absolute left-0 rounded-full' aria-hidden="true" style={{ backgroundColor: item.color }} >
                            <svg className="w-5 h-5 fill-current text-transparent" viewBox="0 0 20 20">
                                <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z"></path>
                            </svg>
                        </div>
                        <div className='flex items-start mb-4 ml-9'>
                            <img className='rounded-full mr-4' width={40} height={40} src={AppUrl.ProfilePics + item.foto_perfil} alt="" />
                            <div>
                                <div className='lg:min-w-[420px] text-sm bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1'>
                                    <h5 className={`font-bold mb-2 ${activeTab === 'historial' && 'uppercase text-base text-gray-500'}`}>{formatTitle(activeTab, item)}</h5>
                                    <h5 hidden={activeTab === 'respuestas'} className='font-semibold mb-2'>Por: {canEditUser ? <Link to={'/usuarios/editar/' + item.usuario_id} className='hover:underline'>{item.nombre}</Link> : <span>{item.nombre}</span>}</h5>
                                    {activeTab === 'respuestas' ?
                                        <RespuestaComponent value={item.message} /> :
                                        formatHistoryMessage(item.message)}
                                    {activeTab === 'respuestas' && <div hidden={item.adjuntos.length === 0} className='p-2 mt-5 bg-gray-100 rounded-md'><h5 className="font-bold sm:text-sm text-gray-500">Adjuntos</h5>
                                        <ul id="gallery" className="flex flex-1 flex-wrap -m-1 mt-2">
                                            {item.adjuntos.map((adj, index) => (
                                                <a key={index} href={AppUrl.ArchivosAdjuntos + adj.key} target="_blank" rel='noreferrer' download={adj.file_name} className='z-0 container w-1/2 cursor-pointer'>
                                                    <li id={adj.id} className="flex p-1 w-full h-20">
                                                        <article tabIndex="0" className={`group ${adj.is_image === "1" && 'hasImage text-transparent hover:text-white'} w-full h-full rounded-md focus:outline-none focus:shadow-outline relative bg-white shadow-sm`}>
                                                            {adj.is_image === "1" && <img alt="upload preview" src={AppUrl.ArchivosAdjuntos + adj.key} className="w-full h-full sticky object-cover rounded-md bg-fixed" />}
                                                            <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                                                                <h1 className={`flex-1 ${adj.is_image !== "1" && 'group-hover:text-blue-800'} `}>{adj.file_name}</h1>
                                                                <div className="flex justify-start items-center">
                                                                    <p className={`p-1 text-xs ${!adj.is_image === "1" && 'text-gray-700'}`}>Tamaño: {adj.size}</p>
                                                                </div>
                                                            </section>
                                                        </article>
                                                    </li>
                                                </a>
                                            ))}
                                        </ul></div>}
                                </div>
                                <div className='flex items-center justify-between'>
                                    <div className='text-xs font-medium text-slate-500'>{FormatTimeHistory(item.created_at)}</div>
                                </div>
                            </div>
                        </div>
                    </li>))}
            </ul>}
        </div>
        {/* Footer */}
        {props.children}
        <ResponseInput hidden={activeTab !== 'respuestas'} />
        {/* Respuestas Modal - Historial */ }
    <ReactModal
        closeTimeoutMS={800}
        isOpen={showModal}
        contentLabel="Default Confirmation Modal"
        className='Modal'
        overlayClassName="Overlay"
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
    >
        <div className="flex flex-col items-start">
            <div className="flex items-center w-full border-b-2">
                <div className="text-gray-900 font-medium text-xl">Respuesta</div>
                <button onClick={handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                    <XIcon className='w-5 h-5' />
                </button>
            </div>
            <div className="border-b-2 w-full py-4">
                {!messageLoaded ? <div className='flex w-full justify-center bg-white'>
                    <svg className="animate-spin h-7 w-7 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                    : <RespuestaComponent value={modal_message} />}
            </div>
            <div className="flex justify-end w-full mt-4 gap-4">
                <button onClick={handleCloseModal} type='button' className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                    Ok
                </button>
            </div>
        </div>
    </ReactModal>
    </>
    )
}

export default DetailsBody;