import React, { useCallback, useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { CogIcon, XIcon } from '@heroicons/react/solid';
import ReactModal from 'react-modal';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
import NoTasks from '../../resources/images/no-tasks.png';
import CircularProgressBar from '../CircularProgressBar';
import TareasModal from './TareasModal';
import { useOutletContext } from 'react-router-dom';
import { ResponseContext } from '../../pages/peticiones/EditarPeticionPage';
import { AddButton, CustomLoader, DeleteButton, EditButton, FilterComponent, colorAccent2, customStyles } from '../CustomTableComponents';

ReactModal.setAppElement('#root');

const TareasComponent = ({ tareas, handleTareas, controls, canEditUser = false, peticionContext = () => { }, peticion_id = null }) => {
    // Get Current User
    const user = useOutletContext().user;
    // Get Peticion Context
    const context = useContext(ResponseContext) || { peticionClosed: false };

    const _data = [{}];
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState(_data);
    const [tareasModal, setTareasModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState('');
    const [dataId, setDataId] = React.useState(0);
    const [current_tarea, setCurrentTarea] = React.useState(null);
    const [userHasOtherTareas, setUserHasOtherTareas] = React.useState(false);
    const [processingRequest, setProcessingRequest] = React.useState(false);
    const [deletingUser, setDeletingUser] = React.useState(null);
    // Collections
    const [usuarios, setUsuarios] = React.useState([]);

    useEffect(() => {
        // Get Usuarios Administrativos
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({ label: depto, options: [{ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido }] });
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido });
                }
            });
            setUsuarios(groupedOptions);
        });
    }, [])

    const handleTareasModal = useCallback((value) => {
        setTareasModal(value);
    }, []);

    function updateTareas(tarea, updatingUserFlag = '') {
        if (!tarea.id) {
            let id;
            if (tareas.length === 0) {
                id = 1;
            } else {
                id = tareas[tareas.length - 1].id + 1;
            }
            tarea.id = id;
            const newTareas = [...tareas, tarea];
            // We are not creating Tareas in a saved Petición, so create it directly in DB
            if (tareas.some(t => t.created_at !== 'Ahora') || window.location.pathname.includes('/peticiones/editar/')) {
                createTareaInDB(tarea, newTareas, 'ADD_USER');
            } else {
                handleTareas(newTareas);
                toast.success('Se agregó la tarea exitosamente');
                setTareasModal(false);
            }

        } else {
            let index = tareas.findIndex(t => t.id === tarea.id);
            const newTareas = [...tareas];
            newTareas.splice(index, 1, tarea);
            // This is a saved Tarea, so update it in DB
            if (tarea.created_at !== 'Ahora') {
                updateTareaInDB(tarea, newTareas, updatingUserFlag, index);
            } else {
                handleTareas(newTareas);
                toast.success('Se actualizó la tarea exitosamente');
                setTareasModal(false);
            }
        }

    }

    function createTareaInDB(tarea, newTareas, updatingUserFlag) {
        setProcessingRequest(true);

        const data = new FormData();
        data.append('titulo', tarea.titulo);
        data.append('descripcion', tarea.descripcion);
        if (tarea.tiempo.dias !== 0 || tarea.tiempo.horas !== 0 || tarea.tiempo.minutos !== 0) {
            data.append('tiempo', JSON.stringify(tarea.tiempo))
        }
        data.append('estatus_id', tarea.estatus_id);
        data.append('usuario_id', tarea.usuario_id);
        data.append('prioridad_id', tarea.prioridad_id > 0 ? tarea.prioridad_id : '');
        data.append('porcentaje', tarea.porcentaje);
        data.append('tiempo_invertido', tarea.tiempo_invertido);
        data.append('creator_id', user.id);
        data.append('user_name', user.nombre);
        data.append('peticion_id', peticion_id);

        RestClient.PostRequest(AppUrl.CreateTarea, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                // Check if we need to add user to colaboradores
                if (!tareas.some(t => parseInt(t.usuario_id) === tarea.usuario_id)) {
                    peticionContext(updatingUserFlag, tarea.usuario_id);
                }
                handleTareas(newTareas);
                toast.success(result.message);
                setTareasModal(false);
                context.setUpdaterCount((prevState) => prevState + 1);
            }
            else if (result.status === 204) {
                toast.info(result.message);
                setTareasModal(false);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function updateTareaInDB(tarea, newTareas, updatingUserFlag, index) {
        setProcessingRequest(true);

        const data = new FormData();
        data.append('titulo', tarea.titulo);
        data.append('descripcion', tarea.descripcion);
        data.append('tiempo_id', tarea.tiempo_id === null ? '' : tarea.tiempo_id);
        if (tarea.tiempo.dias !== 0 || tarea.tiempo.horas !== 0 || tarea.tiempo.minutos !== 0) {
            data.append('tiempo', JSON.stringify(tarea.tiempo))
        }
        data.append('estatus_id', tarea.estatus_id);
        data.append('usuario_id', tarea.usuario_id);
        data.append('prioridad_id', tarea.prioridad_id > 0 ? tarea.prioridad_id : '');
        data.append('porcentaje', tarea.porcentaje);
        data.append('tiempo_invertido', tarea.tiempo_invertido);
        data.append('updater_id', user.id);
        data.append('user_name', user.nombre);

        RestClient.PostRequest((tarea.estatus_tipo === 'completado' ? AppUrl.ReopenTarea : AppUrl.UpdateTarea) + tarea.id, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                // Check if user in Tarea was updated
                if (parseInt(tareas[index].usuario_id) !== tarea.usuario_id && updatingUserFlag) {
                    peticionContext(updatingUserFlag, tarea.usuario_id, updatingUserFlag === 'REMOVE_USER' && parseInt(tareas[index].usuario_id));
                }
                handleTareas(newTareas);
                toast.success(result.message);
                setTareasModal(false);
                context.setUpdaterCount((prevState) => prevState + 1);
            }
            else if (result.status === 204) {
                toast.info(result.message);
                setTareasModal(false);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    const handleAddTarea = useCallback(() => {
        setCurrentTarea(null);
        if (activeTab === '' || activeTab === 'Agregar') {
            setTareasModal(true);
        }
        setActiveTab('Agregar');
    }, [activeTab])

    function handleEditTarea(id) {
        const tarea = tareas.find(t => t.id === id);
        current_tarea === tarea && setTareasModal(true);
        setCurrentTarea({ ...tarea, ti_horas: Math.floor(tarea.tiempo_invertido / 60), ti_minutos: tarea.tiempo_invertido % 60 });
        setUserHasOtherTareas(tareas.filter(t => t.usuario_id === tarea.usuario_id).length > 1);
        setActiveTab('Editar');
    }

    function handleDeleteTarea(updatingUserFlag) {
        //Disable controls while request is processed
        setProcessingRequest(true);

        // Check if this is a DB-Saved Tarea or if user has not created it yet
        const deletingTarea = tareas.find(t => t.id === dataId);
        let index = tareas.findIndex(t => t.id === dataId);
        const newTareas = [...tareas];
        newTareas.splice(index, 1);
        if (deletingTarea.created_at === 'Ahora') {
            handleTareas(newTareas);
            setProcessingRequest(false);
            handleCloseModal();
            toast.success('Se eliminó la tarea exitosamente');
        } else {
            const data = new FormData();
            data.append('peticion_id', peticion_id);
            data.append('deleter_id', user.id);
            data.append('user_name', user.nombre);
            RestClient.PostRequest(AppUrl.DeleteTarea + dataId, data).then((result) => {
                setProcessingRequest(false);
                if (result.status === 200) {
                    toast.success(result.message);
                    peticionContext(updatingUserFlag, parseInt(deletingTarea.usuario_id), parseInt(deletingTarea.usuario_id));
                    handleTareas(newTareas);
                    context.setUpdaterCount((prevState) => prevState + 1);
                }
                else if (result.status === 204) {
                    toast.warn(result.message);
                }
                else {
                    console.log(result);
                    toast.error(result.message);
                }
                handleCloseModal();
            });
        }
    }

    function handleOpenModal(dataId) {
        setDataId(dataId);
        const deletingTarea = tareas.find(t => t.id === dataId);
        setUserHasOtherTareas(tareas.filter(t => t.usuario_id === deletingTarea.usuario_id).length > 1);
        const usuario = usuarios.map(u => u.options).flat().find(u => u.value === parseInt(deletingTarea.usuario_id));
        //console.log(user);
        setDeletingUser({ id: usuario.value, name: usuario.label });
        setDeleteModal(true);
    }

    function handleCloseModal() {
        setDeleteModal(false);
    }

    useEffect(() => {
        if (tareas) {
            setFilteredItems(tareas.filter(
                item => (item.titulo && item.titulo.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.nombre_completo && item.nombre_completo.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.estatus_nombre && item.estatus_nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.prioridad_nombre && item.prioridad_nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.porcentaje && item.porcentaje.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.updated_at && item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
            ));
            setPending(false);
        }
    }, [tareas, filterText]);

    const actions = React.useMemo(() => <AddButton disabled={(!controls.includes('create') && !controls.includes('all')) || context.peticionClosed} onClick={handleAddTarea} tooltipContent={context.peticionClosed ? 'Esta petición está cerrada' : 'Colaboradores no cuentan con el permiso de agregar tareas'} label='Agregar Tarea' />, [controls, handleAddTarea, context.peticionClosed]);

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-accent-1 hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${(row.id >= filteredItems.length - 1 && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${(row.id >= filteredItems.length - 1 && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditButton itemId={row.id} onClick={handleEditTarea} disabled={(parseInt(row.usuario_id) !== user.id && !controls.includes('all')) || context.peticionClosed} />
                                    <DeleteButton itemId={row.id} onClickDelete={() => handleOpenModal(row.id)} disabled={(!controls.includes('delete') && !controls.includes('all')) || context.peticionClosed} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '100px'
        },
        {
            name: 'Título',
            selector: row => row.titulo,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Asignado A',
            selector: row => row.nombre_completo,
            wrap: true,
            grow: 1.3,
            sortable: true
        },
        {
            name: 'Estatus',
            selector: row => row.estatus_nombre,
            cell: row =>
                <div className='inline-flex items-center gap-2'>
                    <div className='w-6 h-6 m-1 rounded-lg shadow-md' style={{ backgroundColor: row.estatus_color }}>
                        <div className="h-full w-full rounded-lg border border-accent-1 p-0"></div>
                    </div>
                    <span>{row.estatus_nombre}</span>
                </div>,
            wrap: true,
            grow: 1.3,
            sortable: true
        },
        {
            name: 'Prioridad',
            selector: row => row.prioridad_nombre,
            cell: row => row.prioridad_id ?
                <div className='inline-flex items-center gap-2'>
                    <div className='w-6 h-6 m-1 rounded-lg shadow-md' style={{ backgroundColor: row.prioridad_color }}>
                        <div className="h-full w-full rounded-lg border border-accent-1 p-0"></div>
                    </div>
                    <span>{row.prioridad_nombre}</span>
                </div> :
                <em className='text-gray-500'>(Sin Asignar)</em>,
            wrap: true,
            grow: 1.3,
            sortable: true
        },
        {
            name: 'Progreso',
            selector: row => row.porcentaje,
            cell: row => <CircularProgressBar progress={parseInt(row.porcentaje)} size={80} trackWidth={5} indicatorWidth={8} indicatorColor={colorAccent2} />,
            wrap: true,
            grow: 1.3,
            sortable: true
        },
        {
            name: 'Fecha Registrado',
            selector: row => row.created_at,
            sortable: true
        },
        {
            name: 'Fecha Actualizado',
            selector: row => row.updated_at,
            sortable: true
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    // If there are no Tareas, return placeholder
    if (tareas.length === 0) {
        return (
            <div className='w-full p-5'>
                {/* Add Task Button */}
                <div className='flex w-full justify-end'>
                    <AddButton disabled={!controls.includes('create') && !controls.includes('all')} onClick={handleAddTarea} label='Agregar Tarea' />
                </div>
                {/* No Tasks Placeholder */}
                <div className='flex w-full justify-center'>
                    <div className='flex-col'>
                        <img src={NoTasks} width={380} height={268} alt="No hay Tareas" />
                        <h3 className='text-center font-semibold text-xl text-gray-300'>No hay tareas</h3>
                    </div>
                </div>
                <TareasModal
                    isOpen={tareasModal}
                    toggleModal={handleTareasModal}
                    activeTab={activeTab}
                    handleActiveTab={setActiveTab}
                    setTareas={updateTareas}
                    isPeticionOwner={controls.includes('all')}
                    processingRequest={processingRequest}
                />
            </div>
        );
    }
    return (
        <>
            <DataTable
                title='Tareas'
                columns={columns}
                data={filteredItems}
                actions={actions}
                customStyles={customStyles}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                striped
                progressPending={pending}
                progressComponent={<CustomLoader />}
            />
            <TareasModal
                isOpen={tareasModal}
                toggleModal={handleTareasModal}
                activeTab={activeTab}
                handleActiveTab={setActiveTab}
                setTareas={updateTareas}
                isPeticionOwner={controls.includes('all')}
                currentTarea={current_tarea}
                userHasOtherTareas={userHasOtherTareas}
                canEditUser={canEditUser}
                processingRequest={processingRequest}
            />
            <ReactModal
                closeTimeoutMS={800}
                isOpen={deleteModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Eliminar Tarea</div>
                        <button onClick={handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4">
                        {userHasOtherTareas ? <p>¿Estás seguro de que quieres eliminar esta tarea?</p> :
                            <p>El usuario <span className='font-semibold'>{deletingUser?.name}</span> no tiene otras tareas asignadas en esta petición. ¿Deseas también eliminar a <span className='font-semibold'>{deletingUser?.name}</span> de Colaboradores de esta Petición junto con la Tarea?</p>}
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={() => handleDeleteTarea('DELETE_USER')} hidden={processingRequest || userHasOtherTareas} type='button' className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Sí
                        </button>
                        <button onClick={() => handleDeleteTarea('KEEP_USER')} hidden={processingRequest || userHasOtherTareas} type='button' className="bg-accent-1 hover:bg-accent-2 text-white font-semibold py-2 px-4 rounded">
                            No
                        </button>
                        <button onClick={() => handleDeleteTarea('KEEP_USER')} type='button' hidden={processingRequest || !userHasOtherTareas} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Eliminar
                        </button>
                        <button onClick={handleCloseModal} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default TareasComponent