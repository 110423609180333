import { BellIcon, DocumentTextIcon, MailIcon, UsersIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { colorAccent2 } from '../../components/CustomTableComponents';
var CryptoJS = require("crypto-js");

const selectStyle = {
  option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? colorAccent2 : state.isFocused ? '#DEEBFF' : 'white',
  }),
}

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => { }) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarNotificacionCorreoPage = (props) => {

  // Notificaciones Correo states
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [asunto, setAsunto] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [usersIds, setUsersIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [characterCount, setCharacterCount] = useState(0);
  // Request/Form states
  const [statusResult, setStatusResult] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [processingRequest, setProcessingRequest] = useState(false);
  const [errors, setErrors] = useState([{ nombre: '', descripcion: '', asunto: '', mensaje: '', selectedUsers: '' }]);
  const [usersAccess, setUsersAccess] = useState([{}]);

  //Get the passed :id from parent and pass it to the component to render it
  const notificacionCorreo = useParams();

  /// Decript permissions
  useConstructor(() => {
    var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
    var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUsersAccess(_usersAccess);
  });

  // Get Petición by Id
  useEffect(() => {
    RestClient.GetRequest(AppUrl.NotificacionCorreoById + notificacionCorreo.id).then((result) => {
      setNombre(result.nombre);
      setDescripcion(result.descripcion);
      result.usuarios.forEach(element => {
        setUsersIds(prevState => [...prevState, element.id]);
      });
      setAsunto(result.asunto);
      handleMensaje(result.mensaje);
      setLoaded(true);
    });
  }, [notificacionCorreo.id]);

  // Load Usuarios
  useEffect(() => {
    RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
      var groupedOptions = [];
      result.filter(a => a.usuario_id !== null).forEach(element => {
        var depto = element.depto;
        if (!groupedOptions.some(o => o.label === depto)) {
          groupedOptions.push({ label: depto, options: [{ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido }] });
        }
        else {
          groupedOptions.find(o => o.label === depto).options.push({ value: parseInt(element.usuario_id), label: element.nombre + ' ' + element.apellido });
        }
      });
      setUsuarios(groupedOptions);
    });
  }, []);

  // Map selected users to display on multi
  useEffect(() => {
    setSelectedUsers(usuarios.filter(u => u.options.some(o => usersIds.some(su => su === o.value))).map(u => u.options).flat());
  }, [usuarios, usersIds])
  

  function handleMensaje(value) {
    let count = 0;
    const jsonValue = JSON.parse(value);
    const flatten = (members) => {
      let children = [];

      return members.map(mem => {
        const m = { ...mem }; // use spread operator
        if (m.children && m.children.length) {
          children = [...children, ...m.children];
        }
        delete m.children; // this will not affect the original array object
        return m;
      }).concat(children.length ? flatten(children) : children);
    };
    let flattenedArray = flatten(jsonValue);
    flattenedArray.forEach(element => {
      if (Object.hasOwn(element, 'text')) {
        count += element.text.length;
      }
    });
    if (flattenedArray && jsonValue.some(j => j.type === 'bulleted-list' || j.type === 'numbered-list')) {
      var jsonChild = {
        type: '',
        children: []
      };
      flattenedArray.forEach(child => {
        if (Object.hasOwn(child, 'type') && (child.type === 'bulleted-list' || child.type === 'numbered-list')) {
          jsonChild.type = child.type;
        }
        else {
          if (Object.hasOwn(child, 'type') && child.type === 'list-item') {
            jsonChild.children.push({type: child.type, children: []});
          } else {
            jsonChild.children.find(c => c.type === 'list-item' && c.children.length === 0).children.push(child);
          }          
        }
      });
      value = JSON.stringify([jsonChild]);
    }
    setMensaje(value);
    setCharacterCount(count);
  }

  function onFormSubmit(e) {
    e.preventDefault();
    //Disable controls while request is processed and clear error messages
    setProcessingRequest(true);
    setErrors([{ nombre: '', descripcion: '', asunto: '', mensaje: '', selectedUsers: '' }]);

    const data = new FormData();
    data.append('nombre', nombre);
    data.append('descripcion', descripcion === null ? '' : descripcion);
    data.append('selectedUsers', JSON.stringify(selectedUsers));
    data.append('asunto', asunto);
    data.append('mensaje', mensaje);

    RestClient.PostRequest(AppUrl.UpdateNotificacionCorreo + notificacionCorreo.id, data).then((result) => {
      setProcessingRequest(false);
      setStatusResult(result.status);
      setErrors(result.data);
      if (result.status === 200) {
          toast.success(result.message);
      }
      else {
          console.log(result);
          toast.error(result.message);
      }
    });
  }

  if (usersAccess.find(ua => ua.modulo_name === 'notificaciones correo' && ua.permiso_name === 'update') === undefined) {
    return <Navigate to='/access-denied' />
  }
  if (statusResult === 200 || statusResult === 204) {
    return <Navigate to='/notificaciones-correo' />
  }
  return (
    <div className={`w-auto min-h-screen ${props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
      {/* Page Title */}
      <div className='flex w-full'>
        <h1 className='text-xl font-medium'>Editar Notificación por Correo</h1>
      </div>
      {/* Breadcrum Nav */}
      <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
        {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
          <>
            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
            <span className='text-xs'>/</span>
          </>}
        <p className='text-gray-400'>Automatización</p>
        <span className='text-xs'>/</span>
        <Link to='/notificaciones-correo' className='font-semibold hover:underline'>Notificaciones Correo</Link>
        <span className='text-xs'>/</span>
        <p className='text-dark-purple font-semibold'>Editar Notificación Correo</p>
      </div>
      {/* Main Section */}
      <div className='w-full flex justify-center mt-6'>
        <div className='w-full flex justify-center bg-white rounded shadow-lg'>
          <div className='w-11/12 mt-8 mb-8'>
            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
              <div className='bg-gray-50'>
                <div className='bg-white border'>
                  <h1 className='text-accent-1 text-2xl p-4'>Editar Notificación por Correo</h1>
                </div>
                {!loaded ?
                  <div className='flex w-full justify-center bg-white'>
                    <Loading />
                  </div> :
                  <form onSubmit={onFormSubmit} className='mt-4'>
                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                      <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Notificación</h2>
                      <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                        <div className=''>
                          <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group ${parseInt(notificacionCorreo.id) !== 1 && 'bg-white'}`}>
                            <div className="w-1/12 pt-2 flex justify-center">
                              <BellIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                            </div>
                            <div className="relative z-0 w-full group">
                              <input onChange={(e) => setNombre(e.target.value)} id='txtNombre' disabled={parseInt(notificacionCorreo.id) === 1} defaultValue={nombre} type="text" name="nombre" className="disabled:cursor-not-allowed disabled:text-gray-500 block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                              <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                            </div>
                          </div>
                          <span className='text-sm text-red-500'>{errors.nombre}</span>
                        </div>
                        <div className=''>
                          <div className={`w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group ${parseInt(notificacionCorreo.id) !== 1 && 'bg-white'}`}>
                            <div className="w-1/12 items-center flex justify-center">
                              <DocumentTextIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                            </div>
                            <div className="relative z-0 w-full group flex items-center">
                              <div className='w-full border-l-2 border-gray-400 focus-within:border-accent-1 peer'>
                                <textarea onChange={(e) => setDescripcion(e.target.value)} id='taDescripcion' disabled={parseInt(notificacionCorreo.id) === 1} defaultValue={descripcion} name="descripcion" className="disabled:cursor-not-allowed disabled:text-gray-500 block px-4 my-2 w-full text-sm text-gray-900 bg-transparent appearance-none focus:outline-none focus:ring-0 peer" placeholder=" " />
                              </div>
                              <label htmlFor="taDescripcion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Descripción (Opcional)</label>
                            </div>
                          </div>
                          <span className='text-sm text-red-500'>{errors.descripcion}</span>
                        </div>
                        {parseInt(notificacionCorreo.id) !== 1 && <div className=''>
                          <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group bg-white">
                            <div className="w-1/12 items-center flex justify-center">
                              <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                            </div>
                            <div className="relative z-0 w-full group flex items-center">
                              <Select placeholder='--Seleccionar Usuario(s)--'
                                id='selectUsuarios'
                                isMulti
                                options={usuarios}
                                value={selectedUsers}
                                menuPortalTarget={document.body}
                                className='p-1 w-full'
                                onChange={(usuario) => setSelectedUsers(usuario)}
                                isLoading={usuarios.length === 0}
                                styles={{
                                  ...selectStyle,
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999
                                  }),
                                }}
                              />
                              <label htmlFor="selectUsuarios" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Notificar A</label>
                            </div>
                          </div>
                          <span className='text-sm text-red-500'>{errors.selectedUsers}</span>
                        </div>}
                      </div>

                    </div>
                    <hr />
                    <div className="md:flex-col space-y-4 w-full p-8 md:px-20  text-gray-500 items-center mb-4">
                      <h5 className='text-accent-1 mb-8 max-w-sm'>Información Correo</h5>
                      <div className=''>
                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group bg-white">
                          <div className="w-1/12 pt-2 flex justify-center">
                            <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                          </div>
                          <div className="relative z-0 w-full group">
                            <input onChange={(e) => setAsunto(e.target.value)} id='txtAsunto' defaultValue={asunto} type="text" name="asunto" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                            <label htmlFor="txtAsunto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Asunto</label>
                          </div>
                        </div>
                        <span className='text-sm text-red-500'>{errors.asunto}</span>
                      </div>
                      <div className=''>
                        <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Mensaje</h5>
                        <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-1 peer'>
                          <TextEditorInput placeholder='El mensaje que se enviara por correo...' defaultValue={mensaje} handleDescripcion={(descripcionValue) => handleMensaje(descripcionValue)} />
                        </div>
                        <p className={`flex w-full justify-end text-xs ${characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{characterCount}/500</p>
                        <span hidden={characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
                        <span className='text-sm text-red-500'>{errors.mensaje}</span>
                      </div>
                    </div>
                    <hr />
                    <div className='p-4 flex justify-center gap-4 bg-white'>
                      <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </button>
                      <button type="submit" disabled={characterCount > 500} hidden={processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                      <Link to='/notificaciones-correo' hidden={processingRequest} className="text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Cancelar</Link>
                    </div>
                  </form>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditarNotificacionCorreoPage