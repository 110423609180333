import React, { useCallback, useEffect, useState } from 'react';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import PeticionesTable from '../peticiones/PeticionesTable';

const TableComponent = ({ modulo, range, parametros, trigger, visible = true }) => {
    const [data, setData] = useState(null);

    const collectData = useCallback(() => {
        // Add a day to the ending date of the range search
        const endingDate = new Date(range[1]);
        endingDate.setDate(endingDate.getDate() + 1);
        const updatedEndingDate = endingDate.toISOString().split('T')[0];
        switch (modulo) {
            case 'peticiones':
                const formdata = new FormData();
                formdata.append('parametros', JSON.stringify(parametros));
                formdata.append('dates', JSON.stringify([range[0], updatedEndingDate]));
                RestClient.PostRequest(AppUrl.ReportePeticionesTable, formdata).then((result) => {
                    setData(result);
                });
                break;
            default:
                break;
        }
    }, [modulo, parametros, range])

    useEffect(() => {
        collectData();
    }, [trigger, collectData])

    function renderTable() {
        switch (modulo) {
            case 'peticiones':
                return (<div id='divPeticionesTable'>
                        <PeticionesTable data={data} authUser={null} controls={[]} hasUpdated={() => {}} report={true} />
                    </div>);
            default:
                break;
        }
    }

    return (
        <div hidden={!visible} className='p-5 w-full bg-gray-100 rounded-lg shadow-lg'>
            {renderTable()}
        </div>
    )
}

export default TableComponent