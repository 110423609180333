import React, { useEffect } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { CogIcon, PlusIcon, XIcon } from '@heroicons/react/solid';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ExpandableEstudiantesTable from './ExpandableEstudiantesTable';

const customStyles = {
    header: {
        style: {
            backgroundColor: '#F3F4F6',
            fontSize: '22px',
            color: '#3730A3',
            minHeight: '56px',
            paddingLeft: '16px',
            paddingRight: '8px',
        },
    },
    subHeader: {
        style: {
            backgroundColor: '#F3F4F6',
            flexWrap: 'inherit',
        }
    },
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            backgroundColor: '#3730A3',
            color: 'white',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const TextField = styled.input`
	height: 32px;
	width: 100%;
    @media (min-width: 640px) {
        width: 200px;
    }
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
`;

const Button = styled.button`
    background-color: #3730A3;
    border: none;
    color: white;
    padding: 8px 32px 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Buscar"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const CustomLoader = () => (
    <Loading />
);

const AddDescuento = ({ disabled }) => {

    if (disabled) {
        return (
        <>
            <ReactTooltip className='tooltip-default' anchorId='btnAddDescuentos' content="No cuentas con este permiso en este módulo" place="top" positionStrategy='fixed' />
            <button type='button' id={'btnAddDescuentos'} className="p-2 bg-blue-900/60 text-gray-400 flex gap-1 items-center rounded-full shadow-lg cursor-default">
                <PlusIcon className='h-5 w-5 text-white' />
                <div className='mx-1 flex items-center'>
                    <span className='font-semibold text-sm text-white'>Agregar</span>
                </div>
            </button>
        </>
        );
    }
    return (
        <Link to='/becas-descuentos/crear-descuento'
            id='btnAddBecas' className='p-2 bg-accent-1 flex gap-1 items-center rounded-full shadow-lg hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
            <PlusIcon className='h-5 w-5 text-white' />
            <div className='mx-1 flex items-center'>
                <span className='font-semibold text-sm text-white'>Agregar</span>
            </div>
        </Link>
    );
}

const EditDescuentos = ({ descuentoId, disabled }) => {
    if (disabled) {
        return (
            <button type='button' disabled
                id={'btnEditDescuentos' + descuentoId} className="disabled:text-gray-400 disabled:hover:bg-gray-200 flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
                Editar
            </button>
        );
    }
    return (
        <Link to={'/becas-descuentos/editar-descuento/' + descuentoId}
            id={'btnEditDescuentos' + descuentoId} className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Editar
        </Link>
    );
}

const DeleteDescuentos = ({ descuentoId, onClickDelete, disabled }) => {
    return (
        <button type='button' onClick={e => onClickDelete(e.target)} disabled={disabled}
            id={'btnDeleteDescuentos' + descuentoId} className="disabled:text-gray-400 disabled:hover:bg-gray-200 flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Eliminar
        </button>
    );
}

ReactModal.setAppElement('#root');

const DescuentosTable = ({ data, controls, expandable }) => {

    const _data = [{}];
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState(_data);
    const [showModal, setShowModal] = React.useState(false);
    const [dataId, setDataId] = React.useState(0);
    const [processingRequest, setProcessingRequest] = React.useState(false);

    // eslint-disable-next-line
    handleOpenModal = handleOpenModal.bind(this);
    // eslint-disable-next-line
    handleCloseModal = handleCloseModal.bind(this);

    const actions = React.useMemo(() => {
        return (
            <AddDescuento disabled={!controls.includes('create')} />
        );
    }, [controls]);

    function handleOpenModal(dataId) {
        setShowModal(true);
        setDataId(dataId);
    }
    function handleCloseModal() {
        setShowModal(false);
    }

    function handleDelete() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        RestClient.GetRequest(AppUrl.DeleteDescuento + dataId).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else if (result.status === 204) {
                toast.warn(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
            handleCloseModal();
            //TO DO: Refresh page upon delete
        });
    }

    useEffect(() => {
        if (data) {
            setFilteredItems(data.filter(
                item => (item.concepto.nombre && item.concepto.nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.tipo && item.tipo.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.valor && item.valor.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.updated_at && item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
            ));
            setPending(false);
        }
    }, [data, filterText])

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-accent-1 hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${(row.id >= filteredItems.length - 1 && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${(row.id >= filteredItems.length - 1 && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditDescuentos descuentoId={row.id} disabled={!controls.includes('update')} />
                                    <DeleteDescuentos descuentoId={row.id} onClickDelete={() => handleOpenModal(row.id)} disabled={!controls.includes('delete')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '100px'
        },
        {
            name: 'Concepto',
            selector: row => row.concepto.nombre,
            sortable: true
        },
        {
            name: 'Tipo',
            selector: row => row.tipo.charAt(0).toUpperCase() + row.tipo.slice(1),
            sortable: true
        },
        {
            name: 'Valor',
            selector: row => row.valor,
            sortable: true
        },
        {
            name: 'Fecha Registrado',
            selector: row => row.created_at,
            sortable: true
        },
        {
            name: 'Fecha Actualizado',
            selector: row => row.updated_at,
            sortable: true
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                title='Descuentos'
                columns={columns}
                data={filteredItems}
                expandableRows={expandable}
                expandableRowsComponent={ExpandableEstudiantesTable}
                expandableRowsComponentProps={{table: 'descuentos'}}
                actions={actions}
                customStyles={customStyles}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                striped
                progressPending={pending}
                progressComponent={<CustomLoader />}
            />

            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Eliminar Descuento</div>
                        <button onClick={handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4">
                        <p>¿Estas seguro que quieres eliminar este descuento?</p>
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={handleDelete} type='button' hidden={processingRequest} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Eliminar
                        </button>
                        <button onClick={handleCloseModal} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};
export default DescuentosTable;