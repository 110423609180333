import React, { Component } from 'react';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Loading from '../Loading';

class ConfiguracionesPanel extends Component {

    state = { originalUserConfiguraciones: [], userConfiguraciones: [], updatedConfiguraciones: [], loaded: false, processingRequest: false }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.ConfiguracionesByUser + this.props.id).then((result) => {
            var sections = [];
            result.forEach((config) => {
                var modulo = config.configuracion.modulo;
                if (!sections.some(s => s.modulo === modulo)) {
                    sections.push({ modulo: modulo, configuraciones: [config] });
                }
                else {
                    sections.find(s => s.modulo === modulo).configuraciones.push(config);
                }
            });
            this.setState({ userConfiguraciones: sections, originalUserConfiguraciones: sections, loaded: true });
        });
    }
    // Get updated values everytime user changes settings
    componentDidUpdate(_, prevState) {
        if (prevState.userConfiguraciones !== this.state.userConfiguraciones) {
            const originalValues = this.state.originalUserConfiguraciones.map(ov => ov.configuraciones).flat();
            const currentValues = this.state.userConfiguraciones.map(cv => cv.configuraciones).flat();
            const updatedValues = this.arrayDifference(currentValues, originalValues);
            const formatedValues = updatedValues.map((uv) => {
                return { id: uv.configuracion_id, activo: uv.activo === 1, correo: uv.correo === 1 }
            })
            this.setState({ updatedConfiguraciones: formatedValues });
        }
    }

    arrayDifference = (a1, a2) => {
        // A comparer used to determine if two entries are equal.
        const isSameConfig = (a, b) => a.configuracion_id === b.configuracion_id && a.activo === b.activo && a.correo === b.correo;

        // Get items that only occur in the left array,
        // using the compareFunction to determine equality.
        const onlyInLeft = (left, right, compareFunction) =>
            left.filter(leftValue =>
                !right.some(rightValue =>
                    compareFunction(leftValue, rightValue)));

        const onlyInA = onlyInLeft(a1, a2, isSameConfig);
        //const onlyInB = onlyInLeft(a2, a1, isSameConfig);

        return [...onlyInA];
    }

    toggleSwitch = (value, config) => {
        let updateEmail = typeof (config) === 'string';
        let modulo = updateEmail ? config : config.configuracion.modulo;
        let newConfigs = !updateEmail ? this.state.userConfiguraciones.map((c) => {
            if (c.modulo === modulo) {
                let index = c.configuraciones.indexOf(config);
                return {
                    ...c, configuraciones: c.configuraciones.map((cx, i) => {
                        if (i === index) {
                            if (!value) {
                                const activeCount = c.configuraciones.reduce((accumulator, currentValue) => currentValue.activo === 1 ? ++accumulator : accumulator, 0);
                                if (activeCount === 1) {
                                    updateEmail = true;
                                }
                            }
                            return { ...cx, activo: value ? 1 : 0 };
                        }
                        return cx;
                    })
                }
            }
            return c;
        }) : this.state.userConfiguraciones;
        if (updateEmail) {
            newConfigs = newConfigs.map((c) => {
                if (c.modulo === modulo) {
                    return {
                        ...c, configuraciones: c.configuraciones.map((ce) => {
                            return { ...ce, correo: value ? 1 : 0 };
                        })
                    }
                }
                return c;
            });
        }
        this.setState({ userConfiguraciones: newConfigs });
    }

    getUpdatedConfiguraciones = () => {
        RestClient.GetRequest(AppUrl.ConfiguracionesByUser + this.props.id).then((result) => {
            var sections = [];
            result.forEach((config) => {
                var modulo = config.configuracion.modulo;
                if (!sections.some(s => s.modulo === modulo)) {
                    sections.push({ modulo: modulo, configuraciones: [config] });
                }
                else {
                    sections.find(s => s.modulo === modulo).configuraciones.push(config);
                }
            });
            this.setState({ userConfiguraciones: sections, originalUserConfiguraciones: sections, updatedConfiguraciones: [] });
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed
        this.setState({ processingRequest: true });

        const data = new FormData();
        data.append('configuraciones', JSON.stringify(this.state.updatedConfiguraciones));

        RestClient.PostRequest(AppUrl.UpdateUserConfiguraciones + this.props.id, data).then((result) => {
            this.setState({ processingRequest: false });
            if (result.status === 200) {
                toast.success(result.message);
                this.getUpdatedConfiguraciones();
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        return (
            <div className='flex-grow'>
                {/* Panel Body */}
                <div className='p-6 space-y-6'>
                    <h2 className='text-2xl tracking-[-.01em] text-slate-800 font-bold mb-6'>Configuraciones</h2>
                    {/* Notificaciones */}
                    {!this.state.loaded ?
                        <div className='flex w-full justify-center bg-white'>
                            <Loading />
                        </div>
                        : this.state.userConfiguraciones.map(section => (
                            <section key={section.modulo} className='md:w-11/12'>
                                <h3 className='text-xl leading-[1.375] tracking-[-.01em] text-slate-800 font-bold mb-1 capitalize'>Notificaciones de {section.modulo}</h3>
                                <ul>
                                    {/* Configuracion */}
                                    {section.configuraciones.map(config => (
                                        <li key={config.configuracion_id} className='flex justify-between items-center py-3 border-b border-slate-200'>
                                            {/* Left */}
                                            <div>
                                                <div className='text-slate-800 font-semibold capitalize'>{config.configuracion.nombre}</div>
                                                <div className='text-sm text-slate-600'>{config.configuracion.descripcion}</div>
                                            </div>
                                            {/* Right */}
                                            <div className='max-w-[50px] mx-5'>
                                                <div className="flex flex-col items-center justify-center">
                                                    <input onChange={(e) => this.toggleSwitch(e.target.checked, config)} id={config.configuracion_id} checked={config.activo} className="mt-[.135rem] rounded-[2.5rem] duration-300 ease-in-out after:rounded-full after:shadow-2xl after:duration-300 checked:after:translate-x-[1.3125rem] h-5 relative float-left w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-accent-1 checked:bg-accent-2 checked:bg-none checked:bg-right" type="checkbox" />
                                                    <label className="font-normal cursor-pointer select-none text-sm" htmlFor={config.configuracion_id}>{config.activo ? 'Activo' : 'Desactivado'}</label>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {/* Correo */}
                                    <li className='flex justify-between items-center py-3 border-b border-slate-200'>
                                        {/* Left */}
                                        <div>
                                            <div className='text-slate-800 font-semibold'>Por Correo</div>
                                            <div className='text-sm text-slate-600'>Se te notificará por correo electrónico cuando tengas una nueva notificación de {section.modulo}. <em className='block'>Nota: Estos correos se envían al correo que se uso cuando se creó tu cuenta, el que usas para acceder a esta plataforma.</em></div>
                                        </div>
                                        {/* Right */}
                                        <div className='max-w-[50px] mx-5'>
                                            <div className="flex flex-col items-center justify-center">
                                                <input onChange={(e) => this.toggleSwitch(e.target.checked, section.modulo)} id={section.modulo + '_correo'} disabled={!this.state.userConfiguraciones.find(c => c.modulo === section.modulo).configuraciones.some(c => c.activo === 1)} checked={this.state.userConfiguraciones.find(c => c.modulo === section.modulo).configuraciones.some(c => c.correo === 1)} className="disabled:opacity-70 disabled:cursor-default mt-[.135rem] rounded-[2.5rem] duration-300 ease-in-out after:rounded-full after:shadow-2xl after:duration-300 checked:after:translate-x-[1.3125rem] h-5 relative float-left w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-accent-1 checked:bg-accent-2 checked:bg-none checked:bg-right peer" type="checkbox" />
                                                <label className="peer-disabled:opacity-70 peer-disabled:cursor-default font-normal cursor-pointer select-none text-sm" htmlFor={section.modulo + '_correo'}>{this.state.userConfiguraciones.find(c => c.modulo === section.modulo).configuraciones.some(c => c.correo === 1) ? 'Activo' : 'Desactivado'}</label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </section>
                        ))}
                </div>
                {/* Panel Footer */}
                <footer>
                    <div className='flex flex-col px-6 py-5 border-t border-slate-200'>
                        <div className='flex self-end'>
                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </button>
                            <button type='button' onClick={this.onFormSubmit} hidden={this.state.processingRequest || !this.state.loaded} className='text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>Guardar Cambios</button>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default ConfiguracionesPanel;