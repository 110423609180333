import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import { Link, Navigate } from 'react-router-dom';
import GruposTable from '../../components/grupos/GruposTable';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class GruposPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'grupos') !== undefined) {
            _moduloPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'grupos') !== undefined) {
            _moduloPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'grupos') !== undefined) {
            _moduloPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloPermissions: _moduloPermissions,
            dataGrupos: null,
            filter: 'todos', collapsed: window.innerWidth > 640
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Grupos).then((result) => {
            this.setState({ dataGrupos: result });
        });
    }

    handleFilterData = (filter) => {
        if (filter === 'todos' || filter === null || filter === undefined || filter === '') {
            return this.state.dataGrupos;
        }
        return this.state.dataGrupos.filter(g => g.nivel === filter);
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'grupos') === undefined) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Grupos</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                        <p className='text-gray-400'>Escuelas</p>
                        <span className='text-xs'>/</span>
                    <Link to='/grupos' className='text-dark-purple font-semibold hover:underline'>Grupos</Link>
                </div>
            </div>
            {/* Data Table And Filter */}
            <div className='w-full flex justify-center sm:mt-6 mt-3'>
                <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                    <div className='sm:mx-12 mx-0 sm:my-8 my-2 bg-gray-100 rounded shadow-lg'>
                        <fieldset className="flex flex-col xl:flex-row w-full xl:items-center md:p-5 py-2.5 px-5 md:space-y-3 xl:space-y-0">
                            <div onClick={() => window.innerWidth <= 640 && this.setState({ collapsed: !this.state.collapsed })} className="2xl:w-1/4 lg:w-1/6 w-full h-full sm:flex-none inline-flex flex-wrap items-center justify-between">
                                <h1 className="sm:text-base text-lg font-semibold">Filtrar por:</h1>
                                <ChevronDownIcon className={`sm:hidden w-7 h-7 text-slate-500 ${this.state.collapsed && 'rotate-180'} duration-300`} />
                            </div>
                            <Collapse isOpened={this.state.collapsed}>                            
                                <div className='flex flex-col lg:flex-row w-full justify-start lg:gap-8 gap-3 md:mt-0 mt-3'>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioTodos" value="todos" defaultChecked/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioTodos">
                                            Todos
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioPreescolar" value="preescolar"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioPreescolar">
                                            Preescolar
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioPrimaria" value="primaria"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioPrimaria">
                                            Primaria
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioSecundaria" value="secundaria"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioSecundaria">
                                            Secundaria
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioPreparatoria" value="preparatoria"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioPreparatoria">
                                            Preparatoria
                                        </label>
                                    </div>
                                </div>
                            </Collapse>
                        </fieldset>
                    </div>
                    <div className='sm:mx-12 mx-0 sm:my-8 my-2'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <GruposTable data={this.handleFilterData(this.state.filter)} controls={this.state.moduloPermissions} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default GruposPage;