import React, { Component } from 'react';
import Login from '../../components/login/Login';
import OriginalLogoFull from '../../resources/images/Logo404x394.png';
import styles from '../../custom-css/Activacion.module.css';

class LoginPage extends Component {

    render() {
        return (
            <>
            <div className="h-screen flex">
                {/* Full Screen :Left Screen Logo */}
                <div className="lg:flex w-[55%] hidden relative items-center bg-waives z-10">
                    <div className="absolute bg-gradient-to-r from-black/60 via-accent-1/50 to-white/60 inset-0 -z-10" />
                    <div className="w-full flex justify-center px-24 z-10 mb-20 mr-16">
                        {/* :Logo and Title */}
                        <div className='w-4/5'>
                            <div className="flex w-full justify-center items-center mb-8">
                                <img className="xl:w-32 xl:h-32 lg:w-20 lg:h-20" src={OriginalLogoFull} alt="Logo" />
                                <p className="text-gray-100 font-bold text-center xl:text-7xl lg:text-5xl">TaskFlow360</p>
                            </div>
                            <p className="uppercase -translate-y-5 text-gray-100 font-semibold text-center text-2xl">The perfect flow for your business tasks</p>
                            <hr />
                            <p className="uppercase text-2xl font-normal text-center text-gray-100">Sistema Para {process.env.REACT_APP_CLIENT_FULL_NAME}</p>
                        </div>
                    </div>
                    {/* :Footer */}
                    <div className="bottom-0 absolute p-4 text-center text-gray-100 right-0 left-0 flex justify-center space-x-4 mr-16">
                        <span>Copyright &copy; 2023 TASKFLOW360</span>
                    </div>
                </div>
                {/* :Right screen Form */}
                <div className="header hidden lg:flex">
                    <div className="inner-header flex-css">
                        <div>
                            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-4 px-0 z-20 bg-white">
                    {/* Mobile Screen: Full Screen Overlay */}
                    <div className='lg:hidden z-10 inset-0 w-full h-screen fixed bg-accent-1 bg-gradient-to-r from-black/60 via-accent-1 to-accent-2 bg-no-repeat bg-cover items-center bg-image-overlay'>
                        <div className="absolute bg-black lg:opacity-60 opacity-20 inset-0 z-0"/>
                        <ul className={styles.circles}>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    {/* :Title Form */}
                    <Login />
                </div>
            </div>
            </>
        );
    }
}

export default LoginPage;