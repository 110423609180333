import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon as CRightIcon } from '@heroicons/react/solid';

const PaginationComponent = ({ page, count, handleFunction, hidden = false }) => {

    function renderPagination() {
        var buttons = [];
        for (let index = 1; index <= Math.ceil(count / 5); index++) {
            buttons.push(<li key={index}>
                <button type='button' onClick={() => handleFunction(index)} disabled={index === page} className={`${index === 1 && 'rounded-tl rounded-bl'} ${index === Math.ceil(count / 5) && 'rounded-tr rounded-br'} ${index === page ? 'bg-accent-2 text-white' : 'text-slate-600'} inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white border border-slate-200 hover:bg-accent-2 hover:text-white`}>{index}</button>
            </li>);
        }
        return buttons;
    }

    return (
        <div className={`${hidden ? 'hidden' : 'flex'} justify-center px-6`} hidden={hidden}>
            <nav className='flex' role='navigation' aria-label='Navigation'>
                <div className='mr-2'>
                    <button type='button' onClick={() => handleFunction(page - 1)} disabled={page === 1} className='disabled:text-slate-300 disabled:hover:bg-white disabled:hover:text-slate-300 inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 text-slate-600 hover:bg-accent-2 hover:text-white'>
                        <span className='absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-0'>Previous</span>
                        <wbr />
                        <ChevronLeftIcon className='h-4 w-4 fill-current' />
                    </button>
                </div>
                <ul className='inline-flex text-sm font-medium shadow-sm -space-x-px'>
                    {renderPagination()}
                </ul>
                <div className='ml-2'>
                    <button type='button' onClick={() => handleFunction(page + 1)} disabled={page === Math.ceil(count / 5)} className='disabled:text-slate-300 disabled:hover:bg-white disabled:hover:text-slate-300 inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 text-slate-600 hover:bg-accent-2 hover:text-white'>
                        <span className='absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-0'>Next</span>
                        <wbr />
                        <CRightIcon className='h-4 w-4 fill-current' />
                    </button>
                </div>
            </nav>
        </div>
    )
}

export default PaginationComponent;