const modalForm = {
    minMedia1024px: {
      content: {
        top: "5%",
        left: "20px",
        right: "20px",
        bottom: "5%",
        overflow: "hidden",
        padding: "20px",
        outline: "none",
        borderRadius: "4px",
        background: "rgb(255, 255, 255)",
        border: "1px solid rgba(204, 204, 204),",
      },
    },
    maxMedia1024px: {
      content: {
        top: "10%",
        left: "10%",
        right: "10%",
        bottom: "10%",
        overflow: "hidden",
        padding: "20px",
        outline: "none",
        borderRadius: "4px",
        background: "rgb(255, 255, 255)",
        border: "1px solid rgba(204, 204, 204),",
      },
    },
  };
  
  export default modalForm;
  