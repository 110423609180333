import { CalendarIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class CrearCicloPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            // Ciclo states
            nameIni: '', nameFin: '', fecha_inicial: '', fecha_final: '', estatus: '', 
            // Request/Form states
            statusResult: 0, processingRequest: false, errors: [{nombre: '', fecha_inicial: '', fecha_final: '', estatus: ''}]
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{nombre: '', fecha_inicial: '', fecha_final: '', estatus: ''}] });

        const data = new FormData();
        data.append('nombre', this.state.nameIni.toUpperCase() + this.state.nameFin.toUpperCase());
        data.append('fecha_inicial', this.state.fecha_inicial);
        data.append('fecha_final', this.state.fecha_final);
        data.append('estatus', this.state.estatus);

        RestClient.PostRequest(AppUrl.CreateCiclo, data).then((result) => {
            this.setState({ statusResult: result.status, processingRequest: false, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'ciclos' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/ciclos' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                <div className='sm:px-0 px-3'>
                    {/* Page Title */}
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Crear Ciclo</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                        <p className='text-gray-400'>Escuelas</p>
                        <span className='text-xs'>/</span>
                        <Link to='/ciclos' className='font-semibold hover:underline'>Ciclos</Link>
                        <span className='text-xs'>/</span>
                        <p className='text-dark-purple font-semibold'>Crear Ciclo</p>
                    </div>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='sm:w-11/12 w-full sm:my-8 my-2'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 sm:text-2xl text-xl p-4'>Registrar Ciclo <span className='ml-2 font-bold uppercase'>{this.state.nameIni !== '' && `'${this.state.nameIni + this.state.nameFin}'`}</span></h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Ciclo</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ fecha_inicial: e.target.value, nameIni: `${new Date(e.target.value).getFullYear()}-${new Date(e.target.value).toLocaleDateString('es-MX', {month: 'short'})}/` })} id='dateFechaInicial' type='date' name="fecha_inicial" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="dateFechaInicial" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha Inicial</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.fecha_inicial}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ fecha_final: e.target.value, nameFin: `${new Date(e.target.value).getFullYear()}-${new Date(e.target.value).toLocaleDateString('es-MX', {month: 'short'})}` })} id='dateFechaFinal' type='date' name="fecha_final" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="dateFechaFinal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha Final</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.fecha_final}</span>
                                                </div>
                                                <div className='-translate-y-2'>
                                                    <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1'>Ciclo Actual</h5>
                                                    <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                        <input onChange={() => this.setState({ estatus: !this.state.estatus })} className='accent-accent-2' type="checkbox" defaultChecked style={{ transform: 'scale(1.5)' }} />
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.estatus}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={this.state.processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/ciclos' hidden={this.state.processingRequest} className={`${!this.state.processingRequest && 'flex'} justify-center items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearCicloPage;