import React, { useEffect } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { CheckIcon, CogIcon, LinkIcon, XIcon } from '@heroicons/react/solid';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CollectionIcon } from '@heroicons/react/outline';

const customStyles = {
    subHeader: {
        style: {
            backgroundColor: '#3d6bec'
        }
    },
    headRow: {
        style: {
            border: 'none',
        },
    },
    headCells: {
        style: {
            backgroundColor: '#123CB8',
            color: 'white',
            fontSize: '14px',
        },
    },
    rows: {
        style: {
            backgroundColor: '#3d6bec',
            color: 'white',
        },
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
            color: 'white',
            backgroundColor: '#123CB8',
        },
    },
    pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '32px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: 'white',
        fill: 'white',
        backgroundColor: 'transparent',
        '&:disabled': {
            cursor: 'unset',
            color: 'white',
            fill: 'white',
        }
    },
};

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
`;

const Button = styled.button`
    background-color: #123CB8;
    border: none;
    color: white;
    padding: 8px 32px 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
    &:hover {
        cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className='flex items-center'>
        <TextField
            id="search"
            type="text"
            placeholder="Buscar"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </div>
);

const AddEstudiante = ({ onClickAdd, loaded }) => {
    return (
        <button onClick={e => onClickAdd(e.target)} disabled={!loaded}
            id='btnAsociarEstudiantes' className='disabled:bg-accent-2 disabled:hover:translate-y-0 p-2 bg-accent-1 flex gap-1 items-center rounded-full shadow-lg hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
            {loaded ? <LinkIcon className='h-5 w-5 text-white' /> : 
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
            <div className='mx-1 flex items-center'>
                <span className='font-semibold text-sm text-white'>Asociar Estudiantes</span>
            </div>
        </button>
    );
}

const EditEstudiante = ({ estudianteId }) => {
    return (
        <Link to={'/estudiantes/editar/' + estudianteId}
            id={'btnEditEstudiantes' + estudianteId} className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Editar
        </Link>
    );
}

const RemoveEstudiante = ({ estudianteId, onClickRemove }) => {
    return (
        <button type='button' onClick={e => onClickRemove(e.target)}
            id={'btnRemoveEstudiante' + estudianteId} className="flex w-full justify-between px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-accent-2 hover:text-white" role="menuitem">
            Remover
        </button>
    );
}

const ExpandableEstudiantesTable = (props) => {

    const _data = [{}];
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState(_data);
    const [showModal, setShowModal] = React.useState(false);
    const [showRemoveModal, setShowRemoveModal] = React.useState(false);
    const [dataId, setDataId] = React.useState(0);
    const [processingRequest, setProcessingRequest] = React.useState(false);
    const [piezas, setPiezas] = React.useState(1);

    // Handles estudiantes and if they have been updated
    const [hasUpdated, setHasUpdated] = React.useState(false);
    const [estudiantes, setEstudiantes] = React.useState([{}]);
    const [loadedEstudiantes, setLoadedEstudiantes] = React.useState(false);
    useEffect(() => {
        RestClient.GetRequest((props.table === 'becas' ? AppUrl.BecaById : props.table === 'descuentos' ? AppUrl.DescuentoById : props.table === 'tesoreria' ? AppUrl.TesoreriaById : AppUrl.InscripcionById) + props.data.id).then((result) =>{
            setEstudiantes(result.estudiantes);
            setLoadedEstudiantes(true);
            setHasUpdated(false);
        });
    }, [hasUpdated, props.data.id, props.table]);

    // Handles filter and loads all Estudiantes
    const [allEstudiantes, setAllEstudiantes] = React.useState([{}]);
    const [loadedAllEstudiantes, setLoadedAllEstudiantes] = React.useState(false);
    useEffect(() => {
        //get all estudiantes
        if (!loadedAllEstudiantes && loadedEstudiantes) {
            RestClient.GetRequest(AppUrl.Estudiantes).then((result) =>{
                var groupedOptions = [];
                result.forEach((estudiante) => {
                    if (!estudiantes.some(e => e.id === estudiante.id)) {
                        var nivel = estudiante.nivel;
                        if (!groupedOptions.some(group => group.label === nivel)) {
                            if (props.table === 'inscripciones') {
                                if (props.data.nivel_id === estudiante.nivel_id) {
                                    groupedOptions.push({label: nivel, options: [{value: estudiante.id, label: estudiante.nombre + ' ' + estudiante.apellido}]});
                                }
                            } 
                            else {
                                groupedOptions.push({label: nivel, options: [{value: estudiante.id, label: estudiante.nombre + ' ' + estudiante.apellido}]});
                            }
                            
                        }
                        else {
                            groupedOptions.find(group => group.label === nivel).options.push({value: estudiante.id, label: estudiante.nombre + ' ' + estudiante.apellido});
                        }
                    }
                });
                setAllEstudiantes(groupedOptions);
                setLoadedAllEstudiantes(true);
            });
        }
        // Searches for filter
        if (estudiantes) {
            setFilteredItems(estudiantes.filter(
                item => (item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.apellido && item.apellido.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.no_expediente && item.no_expediente.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.nivel && item.nivel.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.estatus_academico && item.estatus_academico.toLowerCase().includes(filterText.toLowerCase()))
            ));
            setPending(false);
        }
    }, [estudiantes, filterText, loadedAllEstudiantes, loadedEstudiantes, props.data.nivel_id, props.table])

    function handleOpenModal(modal, dataId = 0) {
        if (modal === 'Asociar') {
            setShowModal(true);
        }
        else {
            setShowRemoveModal(true)
            setDataId(dataId);
        }
        
    }
    function handleCloseModal(modal) {
        if (modal === 'Asociar') {
            setShowModal(false);
        }
        else {
            setShowRemoveModal(false)
        }
    }
    // Handles the update of relationships
    const [selectedItems, setSelectedItems] = React.useState([{}]);
    const [errors, setErrors] = React.useState([{piezas: ''}]);
    function asociarEstudiantes() {
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{piezas: ''}])

        const formData = new FormData();
        formData.append('estudiantes', JSON.stringify(selectedItems));
        if (props.table === 'tesoreria') {
            formData.append('piezas', piezas);
        }

        RestClient.PostRequest((props.table === 'becas' ? AppUrl.BecaById : props.table === 'descuentos' ? AppUrl.DescuentoById : props.table === 'tesoreria' ? AppUrl.TesoreriaById : AppUrl.InscripcionById) + props.data.id + '/estudiantes', formData).then((result) => {
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
                setHasUpdated(true);
                handleCloseModal('Asociar');
                setLoadedAllEstudiantes(false);
                if (props.table === 'inscripciones') {
                    props.hasUpdated();
                }
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }
    // Handle single remove relationship
    function handleRemove() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        RestClient.GetRequest((props.table === 'becas' ? AppUrl.BecaById : props.table === 'descuentos' ? AppUrl.DescuentoById : props.table === 'tesoreria' ? AppUrl.TesoreriaById : AppUrl.InscripcionById) + props.data.id + '/estudiantes/remove/' + dataId).then((result) => {
            setProcessingRequest(false);
            setErrors(result.data)
            if (result.status === 200) {
                toast.success(result.message);
                setHasUpdated(true);
                handleCloseModal('Remover');
                setLoadedAllEstudiantes(false);
                if (props.table === 'inscripciones') {
                    props.hasUpdated();
                }
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }
    // Handle Piezas update
    const [updatingPiezasRow, setUpdatingPiezasRow] = React.useState(0);
    function updatePiezas() {
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{piezas: ''}])

        const formData = new FormData();
        formData.append('piezas', piezas);

        RestClient.PostRequest(AppUrl.TesoreriaById + props.data.id + '/estudiantes/update/' + updatingPiezasRow, formData).then((result) => {
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
                setHasUpdated(true);
                setLoadedAllEstudiantes(false);
                setUpdatingPiezasRow(0);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
        
    }

    const columns = [
        {
            cell: row => (
                <>
                    <div className="dropdown relative text-left">
                        <span className="rounded-md shadow-sm">
                            <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                                <span className="sr-only">Open table menu</span>
                                <CogIcon className='w-5 h-5 text-white hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                            </button>
                        </span>
                        <div className={`dropdown-menu absolute invisible ${(row.id >= filteredItems.length - 1 && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-50`}>
                            <div className={`left-4 translate-x-6 ${(row.id >= filteredItems.length - 1 && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                                <div className="py-1">
                                    <EditEstudiante estudianteId={row.id} />
                                    <RemoveEstudiante estudianteId={row.id} onClickRemove={() => handleOpenModal('Remover', row.id)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ),

            button: true,
            width: '100px'
        },
        {
            name: 'Nombre Completo',
            selector: row => row.nombre + ' ' + row.apellido,
            sortable: true,
            grow: 1.2
        },
        {
            name: 'Expediente',
            selector: row => row.no_expediente,
            cell: row => row.no_expediente === null ? <em>(Pendiente)</em> : row.no_expediente,
            sortable: true,
            grow: 0.5
        },
        {
            name: 'Grupo',
            selector: row => row.grupo,
            sortable: true,
            grow: 0.5
        },
        {
            name: 'Nivel Académico',
            selector: row => row.nivel.charAt(0).toUpperCase() + row.nivel.slice(1),
            sortable: true,
            grow: 0.8
        },
        {
            name: 'Estatus Académico',
            selector: row => row.estatus_academico,
            sortable: true,
            grow: 0.8,
        },
        {
            name: 'Piezas',
            selector: row => row.piezas,
            cell: row => 
            updatingPiezasRow === 0 ? 
            (<div className='flex items-center space-x-4'>
                <span>{row.piezas}</span>
                <button hidden={loadedAllEstudiantes} type='button' disabled className='text-white bg-accent-2 rounded-full w-full sm:w-auto px-3 py-2.5 text-center'>
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </button>
                <button hidden={!loadedAllEstudiantes} type='button' onClick={() => {setUpdatingPiezasRow(row.id); setPiezas(parseInt(row.piezas))}} title='Cambiar Piezas' className={`${loadedAllEstudiantes && 'inline-flex'} justify-center items-center bg-accent-1 p-2.5 rounded-full shadow-md hover:bg-accent-2 hover:-translate-y-1 transition duration-300`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4 fill-white' viewBox="0 0 384 512">
                        <path d="M224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H160V320c0 17.7 14.3 32 32 32s32-14.3 32-32V208H336c17.7 0 32-14.3 32-32s-14.3-32-32-32H224V32zM0 480c0 17.7 14.3 32 32 32H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"/>
                    </svg>
                </button>
            </div>) :
            updatingPiezasRow === row.id ? 
            (<div className="w-full inline-flex flex-wrap gap-4">
                <div className="w-3/5 inline-flex border-2 rounded-full bg-gray-100 border-accent-1 focus-within:border-accent-2 group my-1">
                    <div className="relative z-0 w-full group">
                        <input onChange={(e) => setPiezas(Math.round(e.target.value))} value={piezas} id="numPiezas" type="number" name="piezas"  min={1} step={1} pattern="[0-9]*" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                    </div>
                </div>
                <div className='flex items-center'>
                    <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-full w-full sm:w-auto px-3 py-2.5 text-center'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                    <button hidden={processingRequest} type='button' onClick={updatePiezas} title='Guardar' className={`p-2.5 my-1 bg-accent-1 ${!processingRequest && 'flex'} justify-center items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300`}>
                        <CheckIcon className='w-5 h-5' />
                    </button>
                </div>
            </div>) :
            (<span>{row.piezas}</span>),
            sortable: true,
            omit: props.table !== 'tesoreria'
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div className='flex w-full justify-between items-center'>
                <h1 className='text-white font-semibold'>Estudiantes Asociados</h1>
                <div className='inline-flex gap-4'>
                    <AddEstudiante onClickAdd={() => handleOpenModal('Asociar')} loaded={loadedAllEstudiantes} />
                    <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                </div>
            </div>
            
        );
    }, [filterText, resetPaginationToggle, loadedAllEstudiantes]);

    if (!loadedEstudiantes) {
        return <div className='flex w-full justify-center bg-white'>
            <Loading />
        </div>
    }
    return (
        <pre className='w-full flex justify-center'>
            <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                <div className='w-11/12'>
                    <div className='w-full bg-gray-100 rounded-lg shadow-lg'>  
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            customStyles={customStyles}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            progressPending={pending}
                        />
                    </div>
                </div>
            </div>
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={() => handleCloseModal('Asociar')}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Asociar Estudiantes</div>
                        <button onClick={() => handleCloseModal('Asociar')} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-6 space-y-6">
                        <div className=''>
                            <Select placeholder='--Seleccionar Estudiantes--' 
                                isMulti 
                                options={allEstudiantes} 
                                menuPortalTarget={document.body} 
                                closeMenuOnSelect={false}
                                onChange={(values) => setSelectedItems(values)} 
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} 
                            /> 
                        </div>
                        <div hidden={props.table !== 'tesoreria'} className=''>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                <div className="w-1/12 items-center flex justify-center">
                                    <CollectionIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => setPiezas(Math.round(e.target.value))} value={piezas} id="numPiezas" type="number" name="piezas"  min={1} step={1} pattern="[0-9]*" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                    <label htmlFor="numPiezas" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Piezas</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{errors?.piezas}</span>
                        </div>                  
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={asociarEstudiantes} type='button' hidden={processingRequest} className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Guardar
                        </button>
                        <button onClick={() => handleCloseModal('Asociar')} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showRemoveModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={() => handleCloseModal('Remover')}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Remover Asociación</div>
                        <button onClick={() => handleCloseModal('Remover')} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4">
                        <p>{`¿Estas seguro que quieres remover esta asociación entre ${props.table === 'becas' ? 'beca' : props.table === 'descuentos' ? 'descuento' : props.table === 'tesoreria' ? 'tesorería' : 'inscripción'} y estudiante?`}</p>               
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={handleRemove} type='button' hidden={processingRequest} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Remover
                        </button>
                        <button onClick={() => handleCloseModal('Remover')} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </pre>
    );
}   

export default ExpandableEstudiantesTable;