import { PlusIcon, XIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { Collapse } from 'react-collapse';
import RespuestaComponent from '../form-controls/RespuestaComponent';

class EscalacionCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            id: props._id,
            name: props._name,
            users: props._users,
            subject: props._subject,
            message: props._message,
            cardOpen: false,
        }
    }

    componentDidMount() {
        this.setState({
            id: this.props._id,
            name: this.props._name,
            users: this.props._users,
            subject: this.props._subject,
            message: this.props._message,
        })
    }

    render() {
        return (
            <div className={`border-none rounded shadow-md overflow-hidden my-3 flex w-full flex-col min-w-0 break-normal bg-white group`}>
                {/* Card Header */}
                <div className='bg-slate-200 p-4 flex w-full justify-between items-center '>
                    <button type='button' onClick={() => this.setState({ cardOpen: !this.state.cardOpen })} className='text-black font-normal flex justify-between w-full gap-1 items-center'>
                        <div className='inline-flex items-center gap-2'>
                            <div><PlusIcon className='h-4' /></div>
                            <span>Notificacion:</span>
                            <span className='font-bold'>{this.state.name}</span>
                        </div>
                    </button>
                    <button type='button' title='Remover' onClick={() => this.props.handleRemoveNC(this.state.id)} className='hover:text-red-500'>
                        <XIcon className='h-4' />
                    </button>
                </div>
                {/* Card Body */}
                <Collapse isOpened={this.state.cardOpen}>
                    <div id='card-body' className='p-5'>
                        <div className='mx-auto container space-y-2'>
                            <div className='flex justify-around w-full'>
                                <div className='flex w-1/3 justify-center'>
                                    <span className='font-bold'>Notificar A:</span>
                                </div>
                                <div className='w-2/3'>
                                    <span>{this.state.users.map((user, index) => (
                                        (index + 1) === this.state.users.length ? user :
                                        user + ', '
                                    ))}</span>
                                </div>
                            </div>
                            <div className='flex w-full'>
                                <div className='flex w-1/3 justify-center'>
                                    <span className='font-bold'>Asunto:</span>
                                </div>
                                <div className='w-2/3'>
                                    <p>{this.state.subject}</p>
                                </div>
                            </div>
                            <div className='flex w-full'>
                                <div className='flex w-1/3 justify-center'>
                                    <span className='font-bold'>Mensaje:</span>
                                </div>
                                <div className='w-2/3'>
                                    <RespuestaComponent value={this.state.message} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default EscalacionCard;