import { ChatAltIcon, EyeIcon, EyeOffIcon, TicketIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import NoAlarmGray from '../../resources/images/no-alarm-gray.png';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const NotificacionesPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Notification Page States
    const [notificaciones, setNotificaciones] = useState([]);
    const [notificacionesLoaded, setNotificacionesLoaded] = useState(false);
    const [selectedNotificaciones, setSelectedNotificaciones] = useState([]);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (user) {
            RestClient.GetRequest(AppUrl.AllUserNotifications + user.id + '/' + page).then((result) => {
                setNotificaciones(result);
            });
            RestClient.GetRequest(AppUrl.UserNotificationsCount + user.id).then((result) => {
                setCount(result);
                setNotificacionesLoaded(true);
            });
        }
    }, [user, page])

    function formatTimeHistory(date) {
        var newDate = new Date(date);
        return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString();
    }

    function handleNotificacionSelected(checkbox, id) {
        if (checkbox.checked) {
            setSelectedNotificaciones(prevState => ([...prevState, id]));
        }
        else {
            setSelectedNotificaciones(prevState => (prevState.filter(function (notificacion) {
                return notificacion !== id;
            })));
        }
    }

    function toggleReadSingle(id) {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('usuario_id', user.id);

        RestClient.PostRequest(AppUrl.ToggleReadSingle + id, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                getUpdatedNotifications(page);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function toggleReadMultiple() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('notificaciones', selectedNotificaciones);
        data.append('usuario_id', user.id);

        RestClient.PostRequest(AppUrl.ToggleReadMultiple, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                getUpdatedNotifications(page);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function deleteNotificacionSingle(id) {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('usuario_id', user.id);

        RestClient.PostRequest(AppUrl.DeleteNotificacionSingle + id, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                getUpdatedNotifications(page);
                setSelectedNotificaciones(prevState => (prevState.filter(function (notificacion) {
                    return notificacion !== id;
                })));
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function deleteNotificacionMultiple() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('notificaciones', selectedNotificaciones);
        data.append('usuario_id', user.id);

        RestClient.PostRequest(AppUrl.DeleteNotificacionMultiple, data).then((result) => {
            setProcessingRequest(false);
            setSelectedNotificaciones([]);
            if (result.status === 200) {
                toast.success(result.message);
                getUpdatedNotifications(page);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function getUpdatedNotifications(page, shouldReload = false) {
        setPage(page);
        setNotificacionesLoaded(!shouldReload);
        RestClient.GetRequest(AppUrl.AllUserNotifications + user.id + '/' + page).then((result) => {
            setNotificaciones(result);
        });
        RestClient.GetRequest(AppUrl.UserNotificationsCount + user.id).then((result) => {
            setCount(result);
            setNotificacionesLoaded(true);
        });
    }

    function renderPagination() {
        var buttons = [];
        for (let index = 1; index <= Math.ceil(count / 10); index++) {
            buttons.push(<li key={index}>
                <button type='button' onClick={() => getUpdatedNotifications(index, true)} disabled={index === page} className={`${index === 1 && 'rounded-tl rounded-bl'} ${index === Math.ceil(count / 10) && 'rounded-tr rounded-br'} ${index === page ? 'bg-accent-2 text-white' : 'text-slate-600'} inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white border border-slate-200 hover:bg-accent-2 hover:text-white`}>{index}</button>
            </li>);
        }
        return buttons;
    }

    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Mis Notificaciones</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex md:flex-row flex-col w-full text-sm text-gray-500 sm:mt-2 justify-between'>
                    <div className='space-x-1'>
                        <Link to='/perfil' className='font-semibold hover:underline'>Perfil</Link>
                        <span className='text-xs'>/</span>
                        <Link to='/notificaciones' className='text-dark-purple font-semibold hover:underline'>Notificaciones</Link>
                    </div>
                    {selectedNotificaciones.length > 0 && !processingRequest && <div className='flex sm:flex-row flex-col md:mr-10 md:-translate-y-4 sm:space-y-0 md:my-0 space-y-4 my-2 md:space-x-0 sm:space-x-4'>
                        <button type='button' onClick={deleteNotificacionMultiple} className='md:mx-6 flex justify-center gap-2 items-center text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-600 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            Eliminar
                            <span className="-top-2 -right-2 rounded-full bg-white flex justify-center items-center px-1.5">
                                <span className='text-red-600 font-semibold text-sm'>{selectedNotificaciones.length}</span>
                            </span>
                        </button>
                        <button type='button' onClick={toggleReadMultiple} className='flex justify-center gap-2 items-center text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-600 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            Marcar como leído/no leído
                            <span className="-top-2 -right-2 rounded-full bg-white flex justify-center items-center px-1.5">
                                <span className='text-yellow-600 font-semibold text-sm'>{selectedNotificaciones.length}</span>
                            </span>
                        </button>
                    </div>}
                </div>
            </div>
            
            {/* Main */}
            <div className={`w-full flex justify-center ${selectedNotificaciones.length > 0 ? 'mt-1' : 'mt-6'}`}>
                <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                    <div className='sm:mx-12 mx-0 sm:my-8 my-2'>
                        {notificaciones.length > 0 && <div className='flex justify-center px-6 pb-4'>
                            <nav className='flex' role='navigation' aria-label='Navigation'>
                                <div className='mr-2'>
                                    <button type='button' onClick={() => getUpdatedNotifications(page - 1, true)} disabled={page === 1} className='disabled:text-slate-300 disabled:hover:bg-white disabled:hover:text-slate-300 inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 text-slate-600 hover:bg-accent-2 hover:text-white'>
                                        <span className='absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-0'>Previous</span>
                                        <wbr />
                                        <ChevronLeftIcon className='h-4 w-4 fill-current' />
                                    </button>
                                </div>
                                <ul className='inline-flex text-sm font-medium shadow-sm -space-x-px'>
                                    {renderPagination()}
                                </ul>
                                <div className='ml-2'>
                                    <button type='button' onClick={() => getUpdatedNotifications(page + 1, true)} disabled={page === Math.ceil(count / 10)} className='disabled:text-slate-300 disabled:hover:bg-white disabled:hover:text-slate-300 inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white border border-slate-200 text-slate-600 hover:bg-accent-2 hover:text-white'>
                                        <span className='absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-0'>Next</span>
                                        <wbr />
                                        <ChevronRightIcon className='h-4 w-4 fill-current' />
                                    </button>
                                </div>
                            </nav>
                        </div>}
                        <div className='w-full rounded-lg shadow-lg box-border sm:py-6 py-4 sm:px-8 px-6 relative border border-slate-200'>
                            {!notificacionesLoaded ?
                                <div className='flex w-full justify-center bg-white'>
                                    <Loading />
                                </div>
                                : notificaciones.length === 0 ? <>
                                    <img className="mx-auto h-32" src={NoAlarmGray} alt="" />
                                    <h3 className='pt-4 text-center text-lg font-bold text-slate-400 uppercase'>No tienes nuevas notificaciones</h3>
                                </>
                                    : notificaciones.map(notificacion => (
                                        /* Single Notification */
                                        <div key={notificacion.id} className={`${parseInt(notificacion.read) === 1 && 'opacity-70'} flex sm:flex-row flex-col justify-between items-start py-5 border-b border-slate-200 hover:bg-gray-100 px-3 rounded-md`} >
                                            {/* Checkbox */}
                                            < div className='max-w-[50px] w-full pt-3' >
                                                <div className='block pl-10 min-h-[28px] mb-5'>
                                                    <input onClick={(e) => handleNotificacionSelected(e.target, notificacion.id)} className='h-7 w-7 rounded -ml-10 cursor-pointer float-left bg-gray-200 border-slate-200 accent-accent-2' type="checkbox" name="" id="" />
                                                </div>
                                            </div>
                                            {/* Notification */}
                                            <div className='flex w-full'>
                                                {/* Icon */}
                                                <div className='max-w-[50px] w-full h-12 rounded-full overflow-hidden text-white justify-center items-center font-semibold mr-4 bg-accent-2' style={{ backgroundColor: notificacion.color }}>
                                                    {notificacion.tipo === 'updated' ? <TicketIcon className='p-1' /> : <ChatAltIcon className='p-1' />}
                                                </div>
                                                {/* Message */}
                                                <Link to={'/peticiones/editar/' + notificacion.peticion_id} className='block max-w-[800px]'>
                                                    <h5 className='font-bold capitalize text-lg text-gray-800'>{notificacion.mensaje + ' Petición ID #' + notificacion.peticion_id}</h5>
                                                    <span className='text-sm font-medium text-slate-400'>{formatTimeHistory(notificacion.created_at)}</span>
                                                </Link>
                                            </div>
                                            {/* Action */}
                                            <div className='inline-flex justify-end pt-3'>
                                                {processingRequest ? <div className='flex w-full justify-center bg-transparent'>
                                                    <svg className="animate-spin h-7 w-7 text-accent-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                </div>
                                                    : <>
                                                        <button type='button' title='Eliminar' onClick={() => deleteNotificacionSingle(notificacion.id)} className='border-none bg-transparent ml-5 text-base text-slate-400 hover:text-red-500'>
                                                            <TrashIcon className='h-7' />
                                                        </button>
                                                        <button type='button' onClick={() => toggleReadSingle(notificacion.id)} title={parseInt(notificacion.read) === 0 ? 'Marcar como leída' : 'Marcar como no leída'} className='border-none bg-transparent ml-5 text-base text-slate-400 hover:text-yellow-500'>
                                                            {parseInt(notificacion.read) === 0 ? <EyeIcon className='h-7' /> : <EyeOffIcon className='h-7' />}
                                                        </button>
                                                    </>}
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default NotificacionesPage;