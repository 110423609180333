import styles from '../../custom-css/Activacion.module.css'
import React, { useEffect, useState } from 'react'
import Logo from '../../resources/images/OriginalLogo.png'
import { useParams } from 'react-router-dom'
import ActivacionPasoUno from '../../components/activacion/ActivacionPasoUno'
import RestClient from '../../RestAPI/RestClient'
import AppUrl from '../../RestAPI/AppUrl'
import ActivacionPasoDos from '../../components/activacion/ActivacionPasoDos'
import ActivacionPasoTres from '../../components/activacion/ActivacionPasoTres'
import { toast } from 'react-toastify'

const ActivacionPage = () => {

    const [step, setStep] = useState(1);
    const [usuario_id, setUsuarioId] = useState(null);
    const [nombre, setNombre] = useState('');
    const [userConfiguraciones, setUserConfiguraciones] = useState([]);
    const [updatedConfiguraciones, setUpdatedConfiguraciones] = useState([]);
    const [foto_perfil, setFotoPerfil] = useState(undefined);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [processingRequest, setProcessingRequest] = useState(false);
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const { token } = useParams();

    useEffect(() => {
      RestClient.GetRequest(AppUrl.ActivatingUser + token).then((result) => {
        setStatusResult(result.status);
        setUsuarioId(result.id);
        setNombre(result.nombre);
        setFotoPerfil(result.foto_perfil);
        setEmail(result.email);
        setLoaded(true);
      });
    }, [token]);

    useEffect(() => {
        if (usuario_id) {
            RestClient.GetRequest(AppUrl.ConfiguracionesByUser + usuario_id).then((result) => {
                var sections = [];
                result.forEach((config) => {
                    var modulo = config.configuracion.modulo;
                    if (!sections.some(s => s.modulo === modulo)) {
                        sections.push({modulo: modulo, configuraciones: [config]});
                    }
                    else {
                        sections.find(s => s.modulo === modulo).configuraciones.push(config);
                    }
                });
                setUserConfiguraciones(sections);
            });
        }
    }, [usuario_id]);

    function activateUser() {
        setProcessingRequest(true);
        const data = new FormData();
        data.append('password', password);
        data.append('foto_perfil[]', foto_perfil);
        if (updatedConfiguraciones.length > 0) {
            data.append('configuraciones', JSON.stringify(updatedConfiguraciones));
        }

        RestClient.PostRequest(AppUrl.ActivateUser + usuario_id, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                setStep(3);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    function renderStep() {
        switch (step) {
            case 1:
                return <ActivacionPasoUno nombre={nombre} handleFotoPerfil={setFotoPerfil} foto_perfil={foto_perfil} handlePassword={setPassword} handleStep={setStep} />;
            case 2:
                return <ActivacionPasoDos userConfiguraciones={userConfiguraciones} handleUpdatedConfiguraciones={setUpdatedConfiguraciones} handleActivate={activateUser} processingRequest={processingRequest} handleStep={setStep} />;
            case 3:
                return <ActivacionPasoTres email={email} password={password} handleStep={setStep} />;
            default:
                break;
        }
    }

    return (
        <main id='activacionMain'>
            <div className={styles.context}>
                <div className='w-full'>
                    <div className='min-h-screen h-full flex flex-col after:flex-1'>
                        <div className='flex-1'>
                            {/* Header */}
                            <div className='flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8 lg:fixed'>
                                {/* Logo */}
                                <a href="https://alfa.geoacademy.com.mx/login" className='inline-flex'>
                                    <img src={Logo} alt="Logo Colegio Alfa" className='h-12 mr-2 rounded cursor-pointer block float-left'/>
                                    <h1 className='text-white origin-left text-2xl font-medium mt-1'>{process.env.REACT_APP_CLIENT_FULL_NAME}</h1>
                                </a>
                            </div>
                            {/* Progress Bar */}
                            {statusResult === undefined && <div className='px-4 pt-12 pb-8'>
                                <div className='max-w-md mx-auto w-full'>
                                    <div className='relative'>
                                        <div className='absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200' aria-hidden="true"></div>
                                        <ul className='relative flex justify-between w-full'>
                                            <li>
                                                <div className='flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-accent-2 text-white'>1</div>
                                            </li>
                                            <li>
                                                <div className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step > 1 ? 'bg-accent-2 text-white' : 'bg-slate-200 text-slate-500'}`}>2</div>
                                            </li>
                                            <li>
                                                <div className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step > 2 ? 'bg-accent-2 text-white' : 'bg-slate-200 text-slate-500'}`}>3</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className='px-4 py-8'>
                            {
                                !loaded || statusResult !== undefined ? 
                                <div className='max-w-2xl mx-auto bg-white rounded-lg shadow-lg px-8 py-6'>
                                    <h1 className='text-3xl text-slate-800 font-bold mb-6'>{!loaded ? 'Buscando cuenta...' : 'Algo salió mal.'}</h1>
                                    {!loaded ? <div className='flex w-full justify-center'>
                                        <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_mh508ry7.json"  background="transparent"  speed="1"  style={{width: "400px", height: "400px",}}  loop autoplay></lottie-player>
                                    </div> :
                                    <p className='text-slate-600 font-semibold mb-6'>Lamentablemente, no encontramos tu cuenta con el token que se brindó. Si piensas que esto es un error, favor de contactar a un administrador.</p>}
                                </div> :
                                renderStep()
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* Css Background animation */}
            <div className='w-full h-screen fixed bg-accent-1 bg-gradient-to-r from-black/60 via-accent-1 to-accent-2'>
                <ul className={styles.circles}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </main>
    )
}

export default ActivacionPage