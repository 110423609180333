import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import ChartsComponent from '../../components/reportes/ChartsComponent';
import FilterReportes from '../../components/reportes/FilterReportes';
import TableComponent from '../../components/reportes/TableComponent';
var CryptoJS = require("crypto-js");

class ReportesPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _deptosAccess = Object.values(_usersAccess.find(ua => ua.deptos)).flat();
        this.state = {
            usersAccess: _usersAccess,
            deptosAccess: _deptosAccess,
            modulo: '',
            range: [],
            parametros: [],
            display: '',
            triggerFunction: 0,
            orderBy: '',
            chartType: '',
            loading: null,
        }
    }

    renderComponents = () => {
        switch (this.state.display) {
            case 'tabla':
                return <TableComponent 
                        modulo={this.state.modulo}
                        range={this.state.range} 
                        parametros={this.state.parametros}
                        trigger={this.state.triggerFunction}
                        setLoaded={(value) => this.setState({ tableLoaded: value })}
                        />;

            case 'gráfica':
                return <ChartsComponent 
                        ref={instance => { this.chartsComponent = instance; }}
                        modulo={this.state.modulo} 
                        range={this.state.range} 
                        parametros={this.state.parametros} 
                        orderBy={this.state.orderBy} 
                        chartType={this.state.chartType} 
                        setLoading={() => {}}
                        visible={true}
                        />;
            case 'tabla y gráfica':
                return <div hidden={this.state.loading === null}>
                    <div className={`${this.state.loading ? 'flex' : 'hidden'} flex w-full justify-center bg-white`}>
                        <Loading />
                    </div>
                    <TableComponent
                        modulo={this.state.modulo}
                        range={this.state.range}
                        parametros={this.state.parametros}
                        trigger={this.state.triggerFunction}
                        visible={!this.state.loading}
                    />
                    <ChartsComponent
                        ref={instance => { this.chartsComponent = instance; }}
                        modulo={this.state.modulo}
                        range={this.state.range}
                        parametros={this.state.parametros}
                        orderBy={this.state.orderBy}
                        chartType={this.state.chartType}
                        setLoading={(value) => this.setState({ loading: value })}
                        visible={!this.state.loading}
                    />
                </div>
            default:
                break;
        }
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'reportes' && ua.permiso_name === 'read') === undefined) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                {/* Page Title */}
                <div className='sm:px-0 px-3'>
                    <div className='flex w-full'>
                        <h1 className='sm:text-xl text-lg font-medium'>Reportes</h1>
                    </div>
                    {/* Breadcrum Nav */}
                    <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                        {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                            <>
                                <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                                <span className='text-xs'>/</span>
                            </>}
                        <Link to='/reportes' className='text-dark-purple font-semibold hover:underline'>Reportes</Link>
                    </div>
                </div>
                {/* Charts and Filters */}
                <div className='w-full flex justify-center sm:mt-6 mt-3'>
                    <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                        {/* Filter Component */}
                        <FilterReportes 
                            deptos={this.state.deptosAccess}
                            setModulo={(modulo) => this.setState({ modulo: modulo })} 
                            setRange={(f_inicial, f_final) => this.setState({ range: [f_inicial, f_final] })} 
                            setParametros={(parametros) => this.setState({ parametros: parametros })} 
                            setDisplay={(display) => this.setState({ display: display })}
                            resetLoading={() => this.setState({ loading: null })}
                            generateTable={() => this.setState(prevState => ({ triggerFunction: prevState.triggerFunction + 1 }))}
                            setOrderBy={(orderBy) => this.setState({ orderBy: orderBy })} 
                            setChartType={(chartType) => this.setState({ chartType: chartType })} 
                            generateGraph={() => this.chartsComponent.collectData()}
                        />
                        <div className='sm:mx-12 mx-0 sm:my-8 my-2'>
                            {this.renderComponents()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportesPage;