import { BriefcaseIcon, LockClosedIcon, MailIcon, PhotographIcon, UserIcon } from '@heroicons/react/outline';
import { PencilAltIcon, PlusIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}
//This has to be a functional component to use 'useParams()' from react-router-dom
const CrearUsuarioWithEstudiantePage = (props) => {
    //Usuario Data
    const [persona, setPersona] = useState(null);
    const [userType, setUserType] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [rol_id, setRolId] = useState(0);
    const [foto_perfil, setFotoPerfil] = useState('');
    const [roles, setRoles] = useState([]);
    // Other Data
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{email: '', password: '', rol_id: '', persona_id: '', foto_perfil: ''}]);
    const [usersAccess, setUsersAccess] = useState([{}]);

    //Get the passed :id from parent and pass it to the component to render it
    const estudiante = useParams();

    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Get Tutor by Id and Roles
    useEffect(() => {
        RestClient.GetRequest(AppUrl.EstudianteById + estudiante.id).then((result) => {
            setPersona({'id': result.persona_id, 'nombre': result.nombre, 'apellido': result.apellido});
            setUserType('estudiantes');
            setLoaded(true);
        });
        RestClient.GetRequest(AppUrl.Roles).then((result) => {
            setRoles(result);
        });
    }, [estudiante.id]);

    function handleChange(e) {
        setFotoPerfil(e.target.files[0]);
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{email: '', password: '', rol_id: '', persona_id: '', foto_perfil: ''}]);

        const data = new FormData();
        data.append('email', email);
        data.append('password', password);
        data.append('password_confirmation', password_confirmation);
        data.append('rol_id', rol_id);
        data.append('foto_perfil[]', foto_perfil);
        data.append('user_type', userType);
        data.append('persona_id', persona.id);

        RestClient.PostRequest(AppUrl.RegisterUser, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }
    
    if (usersAccess.find(ua => ua.modulo_name === 'usuarios' && ua.permiso_name === 'create') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/usuarios' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
            {/* Page Title */}
            <div className='flex w-full'>
                <h1 className='text-xl font-medium'>Crear Usuario</h1>
            </div>
            {/* Breadcrum Nav */}
            <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                <p className='text-gray-400'>Admin</p>
                <span className='text-xs'>/</span>
                <Link to='/usuarios' className='font-semibold hover:underline'>Usuarios</Link>
                <span className='text-xs'>/</span>
                <p className='text-dark-purple font-semibold'>Crear Usuario</p>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center mt-6'>
                <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                    <div className='w-11/12 mt-8 mb-8'>
                        <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                            <div className='bg-gray-50'>
                                <div className='bg-white border'>
                                    <h1 className='text-accent-1 text-2xl p-4'>Registrar Usuario</h1>
                                </div>
                                {!loaded ?
                                    <div className='flex w-full justify-center bg-white'>
                                        <Loading />
                                    </div> :
                                <form onSubmit={onFormSubmit} className='mt-4' encType="multipart/form-data">
                                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                        <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Persona</h2>
                                        <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                            <div className=''>
                                                <div className="w-full inline-flex gap-4">
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                        <div className="w-1/12 pt-2 flex justify-center mx-0.5">
                                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input disabled id='txtPersona' defaultValue={persona.nombre + ' ' + persona.apellido} type="text" name="persona" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                            <label htmlFor="txtPersona" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Persona</label>
                                                        </div>
                                                    </div>
                                                    {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'tutores') !== undefined && 
                                                    <Link to={'/estudiantes/editar/' + estudiante.id} title='Editar' className='p-3 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                        <PencilAltIcon className='h-5' />
                                                    </Link>}
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.persona_id}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4 mt-4">
                                        <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Usuario</h2>
                                        <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => setEmail(e.target.value)} id='txtEmail' type="text" name="email" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " required />
                                                        <label htmlFor="txtEmail" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Correo</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.email}</span>
                                            </div>
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => setPassword(e.target.value)} id='txtPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                        <label htmlFor="txtPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Contraseña</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.password}</span>
                                            </div>
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group">
                                                    <div className="w-1/12 items-center flex justify-center">
                                                        <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => setPasswordConfirmation(e.target.value)} id='txtConfirmPassword' type="password" name="password_confirmation" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer" placeholder=" " />
                                                        <label htmlFor="txtConfirmPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Confirmar Contraseña</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.password}</span>
                                            </div>
                                            <div className="grid grid-cols-1 mt-5">
                                                <label className="uppercase md:text-sm text-xs text-gray-400 text-light font-semibold mb-1">Foto De Perfil</label>
                                                <div className='flex items-center justify-center w-full'>
                                                    <label className='flex flex-col border-4 border-dashed w-full h-32 hover:bg-gray-100 hover:border-accent-2 group'>
                                                        <div className='flex flex-col items-center justify-center pt-7'>
                                                            <PhotographIcon className='w-10 h-10 text-gray-400 group-hover:text-accent-2' />
                                                            <p className='lowercase text-sm text-gray-400 group-hover:text-accent-1 pt-1 tracking-wider text-center'>{foto_perfil ? foto_perfil.name : 'Selecciona una foto, Formatos soportados: JPEG, JPG, GIF, PNG, BMP, WebP.'}</p>
                                                        </div>
                                                        <input onChange={handleChange} name='foto_perfil' type='file' className="hidden" accept='.jpg,.jpeg,.png,.gif,.bmp,.webp' />
                                                    </label>
                                                </div>
                                                <span className='text-sm text-red-500'>{errors.foto_perfil}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4 mt-4">
                                        <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Roles</h2>
                                        <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                            <div className=''>
                                            <div className="w-full inline-flex gap-4">
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-2 group my-1">
                                                    <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                        <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-2' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <select onChange={(e) => setRolId(e.target.value)} id='selectRoles' name="rol_id" defaultValue={rol_id} className={`block py-2.5 px-4 w-full text-sm bg-transparent border-l-2 border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-2 peer`} required>
                                                            <option value="0" disabled className='text-gray-400'>-- Seleccione un Rol --</option>
                                                            {roles.map(item => {
                                                                return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                            })}
                                                        </select>
                                                        <label htmlFor="selectRoles" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Rol</label>
                                                    </div>
                                                </div>
                                                {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'roles') !== undefined && 
                                                <Link to='/roles/crear' title='Agregar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                    <PlusIcon className='h-5' />
                                                </Link>}
                                            </div>
                                                <span className='text-sm text-red-500'>{errors.rol_id}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='p-4 flex justify-center gap-4 bg-white'>
                                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </button>
                                        <button type="submit" hidden={processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                        <Link to='/usuarios' hidden={processingRequest} className={`${!processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                    </div>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrearUsuarioWithEstudiantePage