import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import TutoresTable from '../../components/tutores/TutoresTable';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class TutoresPage extends Component {
    
    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'tutores') !== undefined) {
            _moduloPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'tutores') !== undefined) {
            _moduloPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'tutores') !== undefined) {
            _moduloPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloPermissions: _moduloPermissions,
            dataTutores: null,
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Tutores).then((result) => {
            this.setState({ dataTutores: result });
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'tutores') === undefined) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Tutores</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                    <p className='text-gray-400'>Personal</p>
                    <span className='text-xs'>/</span>
                    <Link to='/tutores' className='text-dark-purple font-semibold hover:underline'>Tutores</Link>
                </div>
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <TutoresTable data={this.state.dataTutores} controls={this.state.moduloPermissions} 
                                canCreateUser={this.state.usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined} 
                                canUpdateUser={this.state.usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'usuarios') !== undefined} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TutoresPage;