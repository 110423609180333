import { XCircleIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';

class AccessDeniedPage extends Component {
    render() {
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
                <div className='w-full flex justify-center'>
                        <div className='sm:w-4/5 w-full flex justify-center p-4'>
                            <div className='w-full flex justify-center mt-8 bg-white rounded shadow-lg'>
                                <div className='w-11/12 mt-8 mb-8'>
                                    <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                        <h1 className='uppercase sm:text-6xl text-4xl text-center text-gray-800 font-bold p-8'>Accesso Denegado</h1>
                                        <p className='text-center text-gray-700 font-semibold italic text-lg'>Lo sentimos, no tienes acceso a este módulo. Si piensas que esto es un error, favor de contactarse con el administrador.</p>
                                        <div className='w-full flex justify-center'>
                                            <XCircleIcon className='sm:h-48 sm:w-48 w-40 h-40 text-red-500' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default AccessDeniedPage;