import { AcademicCapIcon, BriefcaseIcon, CalendarIcon, DeviceMobileIcon, GlobeIcon, HashtagIcon, HeartIcon, HomeIcon, IdentificationIcon, LibraryIcon, LocationMarkerIcon, PhoneIcon, UserCircleIcon, UserGroupIcon, UserIcon, UsersIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Select from 'react-select';
import { PencilAltIcon } from '@heroicons/react/solid';
var CryptoJS = require("crypto-js");

// Create a constructor for functional component to get permissions
const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
}

//This has to be a functional component to use 'useParams()' from react-router-dom
const EditarAdministrativoPage = (props) => {

    // Domicilio states
    const [direccion, setDireccion] = useState('');
    const [codigo_postal, setCodigoPostal] = useState('');
    const [colonia, setColonia] = useState('');
    const [municipio, setMunicipio] = useState('');
    // Persona states
    const [personaId, setPersonaId] = useState(null);
    const [usuario_id, setUsuarioId] = useState(null);
    const [email, setEmail] = useState(null);
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [fecha_nacimiento, setFechaNacimiento] = useState('');
    const [sexo, setSexo] = useState('');
    const [nacionalidad, setNacionalidad] = useState('');
    const [no_celular, setNoCelular] = useState('');
    const [curp, setCURP] = useState('');
    const [estado_civil, setEstadoCivil] = useState('');
    // Administrativo states
    const [rfc, setRFC] = useState('');
    const [escolaridad_id, setEscolaridadId] = useState(0);
    const [carrera_id, setCarreraId] = useState(0);
    const [carrera, setCarrera] = useState('');
    const [no_seguro, setNoSeguro] = useState('');
    const [tipo_sangre, setTipoSangre] = useState('');
    const [alergias, setAlergias] = useState('');
    const [nombre_contacto, setNombreContacto] = useState('');
    const [telefono_contacto, setTelefonoContacto] = useState('');
    const [banco_id, setBancoId] = useState(0);
    const [no_cuenta, setNoCuenta] = useState('');
    const [departamento_id, setDepartamentoId] = useState(0);
    const [puesto_id, setPuestoId] = useState(0);
    const [fecha_ingreso, setFechaIngreso] = useState('');
    const [fecha_baja, setFechaBaja] = useState('');
    const [estatus, setEstatus] = useState(true);
    const [escolaridades, setEscolaridades] = useState([]);
    const [carreras, setCarreras] = useState([]);
    const [bancos, setBancos] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [puestos, setPuestos] = useState([]);
    // Request/Form states
    const [statusResult, setStatusResult] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [selectLoading, setSelectLoading] = useState(true);
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{
        // Domicilio errors
        direccion: '', codigo_postal: '', colonia: '', municipio: '',
        // Persona errors
        nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
        // Administrativo errors
        rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: '', carrera_id: '', banco_id: '', no_cuenta: '',
        departamento_id: '', puesto_id: '', nombre_contacto: '', telefono_contacto: '', fecha_ingreso: '', fecha_baja: '', estatus: '',
    }]);
    const [usersAccess, setUsersAccess] = useState([{}]);

    //Get the passed :id from parent and pass it to the component to render it
    const administrativo = useParams();
    
    /// Decript permissions
    useConstructor(() => {
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUsersAccess(_usersAccess);
    });

    // Load Escolaridades, Carreras, Bancos, Departamentos and Puestos
    useEffect(() => {
        // Get Escolaridades
        RestClient.GetRequest(AppUrl.Escolaridades).then((result) => {
            setEscolaridades(result);
        });
        // Get Carreras
        RestClient.GetRequest(AppUrl.Carreras).then((result) => {
            var options = [];
            var groupedOptions = [];
            result.forEach((element, index) => {
                var group = element.area;
                if ((index + 1) !== result.length && group === result[index + 1].area) {
                    options.push({value: element.id, label: element.nombre});
                }
                else {
                    options.push({value: element.id, label: element.nombre});
                    groupedOptions.push({label: 'Area: ' + group, options: options});
                    options = [];
                }
            });
            setCarreras(groupedOptions);
            setSelectLoading(false)
        });
        // Get Bancos
        RestClient.GetRequest(AppUrl.Bancos).then((result) => {
            setBancos([]);
            result.forEach(element => {
                setBancos(prevState => [...prevState, {value: element.id, label: element.nombre}]);
            });
        });
        // Get Departamentos
        RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
            setDepartamentos(result);
        });
        // Get Puestos
        RestClient.GetRequest(AppUrl.Puestos).then((result) => {
            setPuestos(result);
        });
    }, []);

    // Get Administrativo by Id
    useEffect(() => {
        RestClient.GetRequest(AppUrl.AdministrativoById + administrativo.id).then((result) => {
            result.usuario_id !== null && setUsuarioId(parseInt(result.usuario_id));
            result.email !== null && setEmail(result.email);
            setPersonaId(result.persona_id);
            setNombre(result.nombre);
            setApellido(result.apellido);
            setFechaNacimiento(result.fecha_nacimiento);
            setSexo(result.sexo);
            setNacionalidad(result.nacionalidad);
            setNoCelular(result.no_celular);
            setRFC(result.rfc);
            setCURP(result.curp);
            setEstadoCivil(result.estado_civil);
            setDireccion(result.direccion);
            setCodigoPostal(result.codigo_postal);
            setColonia(result.colonia);
            setMunicipio(result.municipio);
            setCarreraId(parseInt(result.carrera_id));
            setCarrera(result.carrera);
            setNoSeguro(result.no_seguro);
            setTipoSangre(result.tipo_sangre);
            setEscolaridadId(parseInt(result.escolaridad_id));
            setAlergias(result.alergias);
            setNombreContacto(result.nombre_contacto);
            setTelefonoContacto(result.telefono_contacto);
            setBancoId(parseInt(result.banco_id));
            setNoCuenta(result.no_cuenta);
            setDepartamentoId(parseInt(result.departamento_id));
            setPuestoId(parseInt(result.puesto_id));
            setFechaIngreso(result.fecha_ingreso);
            setFechaBaja(result.fecha_baja);
            setEstatus(result.estatus);
            setLoaded(true);
        });
    }, [administrativo.id]);

    function isNumericInput (event) {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    function isModifierKey (event) {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    function enforceFormat (event) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };

    function formatToPhone (event) {
        if(isModifierKey(event)) {return;}
    
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
    
        if(input.length > 6){event.target.value = `(${areaCode}) ${middle}-${last}`;}
        else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
        else if(input.length > 0){event.target.value = `(${areaCode}`;}
    };

    function formatPhoneNumber (phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{
            // Domicilio errors
            direccion: '', codigo_postal: '', colonia: '', municipio: '',
            // Persona errors
            nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
            // Administrativo errors
            rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: '', carrera_id: '', banco_id: '', no_cuenta: '',
            departamento_id: '', puesto_id: '', nombre_contacto: '', telefono_contacto: '', fecha_ingreso: '', fecha_baja: '', estatus: '',
        }]);

        const data = new FormData();
        data.append('profile', 0);
        data.append('persona_id', personaId);
        // Domicilio info
        data.append('direccion', direccion);
        data.append('codigo_postal', codigo_postal);
        data.append('colonia', colonia);
        data.append('municipio', municipio);
        // Persona info 
        data.append('nombre', nombre);
        data.append('apellido', apellido);
        data.append('fecha_nacimiento', fecha_nacimiento);
        data.append('sexo', sexo);
        data.append('nacionalidad', nacionalidad);
        data.append('no_celular', no_celular);
        data.append('curp', curp);
        data.append('estado_civil', estado_civil);
        // Administrativo info
        data.append('rfc', rfc);
        data.append('no_seguro', no_seguro);
        data.append('tipo_sangre', tipo_sangre);
        data.append('alergias', alergias === null ? '' : alergias);
        data.append('escolaridad_id', escolaridad_id);
        data.append('carrera_id', carrera_id);
        data.append('banco_id', banco_id);
        data.append('no_cuenta', no_cuenta);
        data.append('departamento_id', departamento_id);
        data.append('puesto_id', puesto_id);
        data.append('nombre_contacto', nombre_contacto);
        data.append('telefono_contacto', telefono_contacto);
        data.append('fecha_ingreso', fecha_ingreso);
        data.append('fecha_baja', fecha_baja === null ? '' : fecha_baja);
        data.append('estatus', estatus);

        RestClient.PostRequest(AppUrl.UpdateAdministrativo + administrativo.id, data).then((result) => {
            setStatusResult(result.status);
            setProcessingRequest(false);
            setErrors(result.data);

            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    if (usersAccess.find(ua => ua.modulo_name === 'administrativos' && ua.permiso_name === 'update') === undefined) {
        return <Navigate to='/access-denied' />
    }
    if (statusResult === 200) {
        return <Navigate to='/administrativos' />
    }
    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Editar Administrativo</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Personal</p>
                    <span className='text-xs'>/</span>
                    <Link to='/administrativos' className='font-semibold hover:underline'>Administrativos</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Editar Administrativo</p>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 text-2xl p-4'>Editar Administrativo</h1>
                                    </div>
                                    {!loaded ?
                                        <div className='flex w-full justify-center bg-white'>
                                            <Loading />
                                        </div> :
                                    <form onSubmit={onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Persona</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNombre(e.target.value)} id='txtNombre' defaultValue={nombre} type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre(s)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nombre}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setApellido(e.target.value)} id='txtApellido' defaultValue={apellido} type="text" name="apellido" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtApellido" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Apellido(s)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.apellido}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setFechaNacimiento(e.target.value)} id='dateFechaNacimiento' defaultValue={fecha_nacimiento} type='date' name="fecha_nacimiento" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="dateFechaNacimiento" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha de Nacimiento</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.fecha_nacimiento}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setSexo(e.target.value)} id='selectSexo' name="sexo" defaultValue={sexo} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione un Sexo --</option>
                                                                <option value="nk">Desconocido</option>
                                                                <option value="m">Hombre</option>
                                                                <option value="f">Mujer</option>
                                                                <option value="na">No Aplicable</option>
                                                            </select>
                                                            <label htmlFor="selectSexo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Sexo</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.sexo}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <GlobeIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNacionalidad(e.target.value)} id='textNacionalidad' defaultValue={nacionalidad} type='text' name="nacionalidad" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="textNacionalidad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nacionalidad</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nacionalidad}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <IdentificationIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setCURP(e.target.value)} id='textCURP' defaultValue={curp} type='text' name="curp" maxLength={18} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="textCURP" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">CURP</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.curp}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <IdentificationIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setRFC(e.target.value)} id='textRFC' defaultValue={rfc} type='text' name="rfc" maxLength={13} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="textRFC" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">RFC</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.rfc}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <HeartIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setEstadoCivil(e.target.value)} id='selectEstadoCivil' name="estado_civil" defaultValue={estado_civil} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione un Estado Civil --</option>
                                                                <option value="1">Casado(a)</option>
                                                                <option value="2">Viudo(a)</option>
                                                                <option value="3">Separado(a)</option>
                                                                <option value="4">Divorciado(a)</option>
                                                                <option value="5">Soltero(a)</option>
                                                            </select>
                                                            <label htmlFor="selectEstadoCivil" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estado Civil</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.estado_civil}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setEscolaridadId(e.target.value)} id='selectEscolaridad' name="escolaridad_id" defaultValue={escolaridad_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel de Estudios --</option>
                                                                {escolaridades.map(item => {
                                                                    return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                })}
                                                            </select>
                                                            <label htmlFor="selectEscolaridad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Máximo de Estudios</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.escolaridad_id}</span>
                                                </div>
                                                <div className='' hidden={escolaridad_id < 6}>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <Select placeholder='--Seleccionar Carrera--'  
                                                                options={carreras} 
                                                                defaultValue={{label: carrera, value: carrera_id}}
                                                                menuPortalTarget={document.body} 
                                                                className='p-1'
                                                                onChange={(carrera) => setCarreraId(carrera.value)}
                                                                isLoading={selectLoading}
                                                                styles={{
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                    }),
                                                                }}
                                                            />
                                                            <label htmlFor="selectCarrera" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Carrera</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.carrera_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Médica</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                                <path d="M296 96C296 87.16 303.2 80 312 80H328C336.8 80 344 87.16 344 96V120H368C376.8 120 384 127.2 384 136V152C384 160.8 376.8 168 368 168H344V192C344 200.8 336.8 208 328 208H312C303.2 208 296 200.8 296 192V168H272C263.2 168 256 160.8 256 152V136C256 127.2 263.2 120 272 120H296V96zM408 0C447.8 0 480 32.24 480 72V80H568C607.8 80 640 112.2 640 152V440C640 479.8 607.8 512 568 512H71.98C32.19 512 0 479.8 0 440V152C0 112.2 32.24 80 72 80H160V72C160 32.24 192.2 0 232 0L408 0zM480 128V464H568C581.3 464 592 453.3 592 440V336H536C522.7 336 512 325.3 512 312C512 298.7 522.7 288 536 288H592V240H536C522.7 240 512 229.3 512 216C512 202.7 522.7 192 536 192H592V152C592 138.7 581.3 128 568 128H480zM48 152V192H104C117.3 192 128 202.7 128 216C128 229.3 117.3 240 104 240H48V288H104C117.3 288 128 298.7 128 312C128 325.3 117.3 336 104 336H48V440C48 453.3 58.74 464 71.98 464H160V128H72C58.75 128 48 138.7 48 152V152zM208 464H272V400C272 373.5 293.5 352 320 352C346.5 352 368 373.5 368 400V464H432V72C432 58.75 421.3 48 408 48H232C218.7 48 208 58.75 208 72V464z"/>
                                                            </svg>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNoSeguro(e.target.value)} id='txtNoSeguro' defaultValue={no_seguro} maxLength={15} type="text" name="no_seguro" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtNoSeguro" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número de Seguro Médico</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.no_seguro}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                <path d="M16 319.1C16 245.9 118.3 89.43 166.9 19.3C179.2 1.585 204.8 1.585 217.1 19.3C265.7 89.43 368 245.9 368 319.1C368 417.2 289.2 496 192 496C94.8 496 16 417.2 16 319.1zM112 319.1C112 311.2 104.8 303.1 96 303.1C87.16 303.1 80 311.2 80 319.1C80 381.9 130.1 432 192 432C200.8 432 208 424.8 208 416C208 407.2 200.8 400 192 400C147.8 400 112 364.2 112 319.1z"/>
                                                            </svg>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <select onChange={(e) => setTipoSangre(e.target.value)} id='selectTipoSangre' name="tipo_sangre" defaultValue={tipo_sangre} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                                <option value="0" disabled className='text-gray-400'>-- Seleccione un Tipo de Sangre --</option>
                                                                <option value="A+">A+</option>
                                                                <option value="A-">A-</option>
                                                                <option value="B+">B+</option>
                                                                <option value="B-">B-</option>
                                                                <option value="O+">O+</option>
                                                                <option value="O-">O-</option>
                                                                <option value="AB+">AB+</option>
                                                                <option value="AB-">AB-</option>
                                                            </select>
                                                            <label htmlFor="selectTipoSangre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo de Sangre</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.tipo_sangre}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 flex justify-center items-center">
                                                            <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path d="M448 96c-17.67 0-32 14.33-32 32v112C416 248.8 408.8 256 400 256s-15.93-7.164-15.93-16L384 64c0-17.67-14.33-32-32-32s-32 14.33-32 32l.0498 176c0 8.836-7.219 16-16.06 16s-15.95-7.164-15.95-16L288 32c0-17.67-14.33-32-32-32S224 14.33 224 32l.0729 208C224.1 248.8 216.8 256 208 256S192.1 248.8 192.1 240L192 64c0-17.67-14.33-32-32-32S128 46.33 128 64v279.4L68.28 283.7C60.47 275.9 50.23 272 40 272C18.68 272 0 289.2 0 312c0 10.23 3.906 20.47 11.72 28.28l113.1 113.1C162.6 491.2 212.9 512 266.3 512H304c97.05 0 176-78.95 176-176V128C480 110.3 465.7 96 448 96zM192 416c-8.836 0-16-7.164-16-16C176 391.2 183.2 384 192 384s16 7.162 16 16C208 408.8 200.8 416 192 416zM256 448c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C272 440.8 264.8 448 256 448zM256 352c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C272 344.8 264.8 352 256 352zM320 384c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C336 376.8 328.8 384 320 384zM352 448c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C368 440.8 360.8 448 352 448zM384 352c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C400 344.8 392.8 352 384 352z"/>
                                                            </svg>
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <textarea onChange={(e) => setAlergias(e.target.value)} id='taAlergias' defaultValue={alergias} type="text" name="alergias" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                                            <label htmlFor="taAlergias" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Alergias</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.alergias}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Domicilio</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HomeIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setDireccion(e.target.value)} id='txtDireccion' defaultValue={direccion} type="text" name="direccion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtDireccion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Dirección</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.direccion}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HashtagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setCodigoPostal(e.target.value)} id='txtCodigoPostal' defaultValue={codigo_postal} type="text" name="codigo_postal" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtCodigoPostal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Código Postal</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.codigo_postal}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <LocationMarkerIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setColonia(e.target.value)} id='txtColonia' defaultValue={colonia} type="text" name="colonia" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtColonia" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Colonia</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.colonia}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <LibraryIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setMunicipio(e.target.value)} id='txtMunicipio' defaultValue={municipio} type="text" name="municipio" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtMunicipio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Municipio</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.municipio}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Contacto</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <DeviceMobileIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNoCelular(formatPhoneNumber(e.target.value))} defaultValue={no_celular} onKeyDown={enforceFormat} onKeyUp={formatToPhone} id='telNumCel' type="tel" name="no_celular" maxLength={15} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="telNumCel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número Celular</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.no_celular}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <UserCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNombreContacto(e.target.value)} id='txtNombreContacto' defaultValue={nombre_contacto} type="text" name="nombre_contacto" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombreContacto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre de Contacto de Emergencia</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.nombre_contacto}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <PhoneIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setTelefonoContacto(formatPhoneNumber(e.target.value))} defaultValue={telefono_contacto} onKeyDown={enforceFormat} onKeyUp={formatToPhone} id='telTelefonoContacto' type="tel" name="telefono_contacto" maxLength={15} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="telTelefonoContacto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Teléfono de Contacto de Emergencia</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.telefono_contacto}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Administrativa</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className='mt-4'>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <LibraryIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <Select placeholder='--Seleccionar Banco--'  
                                                                options={bancos} 
                                                                defaultValue={bancos.length > 0 && bancos.find(banco => banco.value === banco_id)}
                                                                menuPortalTarget={document.body} 
                                                                className='p-1'
                                                                onChange={(banco) => setBancoId(banco.value)}
                                                                isLoading={selectLoading}
                                                                styles={{
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                                                                    }),
                                                                }}
                                                            />
                                                            <label htmlFor="selectBanco" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Banco</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.banco_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <HashtagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setNoCuenta(e.target.value)} id='txtNoCuenta' defaultValue={no_cuenta} type="text" name="no_cuenta" maxLength={34} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtNoCuenta" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número de Cuenta Bancaria</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.no_cuenta}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => setDepartamentoId(e.target.value)} id='selectPuesto' name="departamento_id" value={departamento_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Departamento --</option>
                                                                    {departamentos.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectDepartamento" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Departamento</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'departamentos') !== undefined &&
                                                            <Link to={'/departamentos/editar/' + departamento_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                <PencilAltIcon className='h-5' />
                                                            </Link>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.departamento_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group my-1">
                                                            <div className="w-1/12 items-center flex justify-center mx-0.5">
                                                                <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <select onChange={(e) => setPuestoId(e.target.value)} id='selectPuesto' name="puesto_id" value={puesto_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione Puesto --</option>
                                                                    {puestos.map(item => {
                                                                        return <option key={item.id} value={item.id}>{item.nombre}</option>
                                                                    })}
                                                                </select>
                                                                <label htmlFor="selectPuesto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Puesto</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'puestos') !== undefined &&
                                                            <Link to={'/puestos/editar/' + puesto_id} title='Editar' className='p-3 my-1 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                                <PencilAltIcon className='h-5' />
                                                            </Link>}
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.puesto_id}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setFechaIngreso(e.target.value)} id='dateFechaIngreso' defaultValue={fecha_ingreso} type='date' name="fecha_ingreso" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="dateFechaIngreso" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha de Ingreso</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.fecha_ingreso}</span>
                                                </div>
                                                <div className='-translate-y-2'>
                                                    <h5 className='md:text-sm text-xs text-gray-400 text-light font-normal mb-1'>Aún trabaja aquí</h5>
                                                    <div className="min-h-[1.5rem] mb-0.5 block pl-2">
                                                        <input onChange={() => setEstatus(prevState =>(!prevState))} className='accent-accent-2' type="checkbox" checked={estatus} style={{ transform: 'scale(1.5)' }} />
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.estatus}</span>
                                                </div>
                                                <div className='-translate-y-2' hidden={estatus}>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => setFechaBaja(e.target.value)} id='dateFechaBaja' defaultValue={fecha_baja} type='date' name="fecha_baja" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                                            <label htmlFor="dateFechaBaja" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha de Baja</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{errors.fecha_baja}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mt-4 mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Usuario</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    {usuario_id === null ?
                                                    <div className='w-full inline-flex items-center gap-4'>
                                                        <p className='italic'>Aún no cuenta con usuario</p>
                                                        {usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined && 
                                                        <Link to={'/usuarios/crear-con-admin/' + administrativo.id} role='button' className='bg-accent-1 text-white p-2 rounded-lg font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                            Crear Usuario
                                                        </Link>}
                                                    </div> :
                                                    <div className="w-full inline-flex gap-4">
                                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                                            <div className="w-1/12 pt-2 flex justify-center mx-0.5">
                                                                <UserCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                            </div>
                                                            <div className="relative z-0 w-full group">
                                                                <input disabled id='txtUsuario' defaultValue={email} type="text" name="usuario_id" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                                <label htmlFor="txtUsuario" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Usuario</label>
                                                            </div>
                                                        </div>
                                                        {usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'usuarios') !== undefined && 
                                                        <Link to={'/usuarios/editar/' + usuario_id} title='Editar' className='p-3 bg-accent-1 flex items-center rounded-full shadow-lg text-white text-sm font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                                                            <PencilAltIcon className='h-5' />
                                                        </Link>}
                                                    </div>}
                                                    <span className='text-sm text-red-500'>{errors.usuario_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" hidden={processingRequest} className="text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/administrativos' hidden={processingRequest} className={`${!processingRequest && 'flex'} items-center text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center`}>Cancelar</Link>
                                        </div>
                                    </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default EditarAdministrativoPage