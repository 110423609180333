import { AcademicCapIcon, BriefcaseIcon, CalendarIcon, DeviceMobileIcon, GlobeIcon, HashtagIcon, HeartIcon, HomeIcon, IdentificationIcon, LibraryIcon, LocationMarkerIcon, MailIcon, PhoneIcon, UserCircleIcon, UserGroupIcon, UserIcon, UsersIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid';
import React, { Component } from 'react'
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import Loading from '../Loading';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { colorAccent2 } from '../CustomTableComponents';

const selectStyle = {
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? colorAccent2 : state.isFocused ? '#DEEBFF' : 'white',
    }),
}

class DatosPanel extends Component {

    state = {
        // Domicilio states
        direccion: '', codigo_postal: '', colonia: '', municipio: '',
        // Persona states
        persona_id: 0, nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: 0,
        // User Type states
        rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: 0, carrera_id: 0,
        nombre_contacto: '', telefono_contacto: '', parentesco_id: 0, email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '',
        // Request/Form states
        loaded: false, statusResult: 0, processingRequest: false, selectLoading: true, activeSection: 'Persona',
        errors: [{
            // Domicilio errors
            direccion: '', codigo_postal: '', colonia: '', municipio: '',
            // Persona errors
            nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
            // User Type errors
            rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: '', carrera_id: '',
            nombre_contacto: '', telefono_contacto: '', parentesco_id: '', email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '',
        }],
        escolaridades: [],
        carreras: [],
        parentescos: [],
    }

    componentDidMount() {
        // Load Info per section
        if (this.props.user_type === 'administrativos') {
            RestClient.GetRequest(AppUrl.AdministrativoById + this.props.id).then((result) => {
                this.setState({
                    // Persona states
                    persona_id: result.persona_id, nombre: result.nombre, apellido: result.apellido, fecha_nacimiento: result.fecha_nacimiento, sexo: result.sexo, nacionalidad: result.nacionalidad, no_celular: result.no_celular, curp: result.curp, estado_civil: result.estado_civil,
                    // Domicilio states
                    direccion: result.direccion, codigo_postal: result.codigo_postal, colonia: result.colonia, municipio: result.municipio,
                    // Administrativo states
                    rfc: result.rfc, no_seguro: result.no_seguro, tipo_sangre: result.tipo_sangre, alergias: result.alergias, escolaridad_id: result.escolaridad_id, carrera_id: parseInt(result.carrera_id),
                    nombre_contacto: result.nombre_contacto, telefono_contacto: result.telefono_contacto,
                    loaded: true
                });
            });
        } else if (this.props.user_type === 'tutores') {
            RestClient.GetRequest(AppUrl.TutorById + this.props.id).then((result) => {
                this.setState({
                    // Persona states
                    persona_id: result.persona_id, nombre: result.nombre, apellido: result.apellido, fecha_nacimiento: result.fecha_nacimiento, sexo: result.sexo, nacionalidad: result.nacionalidad, no_celular: result.no_celular, curp: result.curp, estado_civil: result.estado_civil,
                    // Domicilio states
                    direccion: result.direccion, codigo_postal: result.codigo_postal, colonia: result.colonia, municipio: result.municipio,
                    // Tutor states
                    parentesco_id: result.parentesco_id, email_personal: result.email_personal, num_hijos: result.num_hijos, ocupacion: result.ocupacion, 
                    escolaridad_id: result.escolaridad_id, tel_trabajo: result.tel_trabajo, 
                    loaded: true
                });
            });
        }
        
        // Get Escolaridades
        RestClient.GetRequest(AppUrl.Escolaridades).then((result) => {
            this.setState({ escolaridades: result });
        });
        // Get Carreras
        RestClient.GetRequest(AppUrl.Carreras).then((result) => {
            var options = [];
            var groupedOptions = [];
            result.forEach((element, index) => {
                var group = element.area;
                if ((index + 1) !== result.length && group === result[index + 1].area) {
                    options.push({ value: element.id, label: element.nombre });
                }
                else {
                    options.push({ value: element.id, label: element.nombre });
                    groupedOptions.push({ label: 'Area: ' + group, options: options });
                    options = [];
                }
            });
            this.setState({ carreras: groupedOptions });
            this.setState({ selectLoading: false });
        });
        // Get Parentescos
        RestClient.GetRequest(AppUrl.Parentescos).then((result) => {
            this.setState({ parentescos: result });
        });
    }

    isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };

    enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    formatToPhone = (event) => {
        if (this.isModifierKey(event)) { return; }

        const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const areaCode = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        if (input.length > 6) { event.target.value = `(${areaCode}) ${middle}-${last}`; }
        else if (input.length > 3) { event.target.value = `(${areaCode}) ${middle}`; }
        else if (input.length > 0) { event.target.value = `(${areaCode}`; }
    };

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    renderUserTypeSection = (user_type) => {
        switch (user_type) {
            case 'administrativos':
                var carrera = { label: '', value: '' };
                this.state.carreras.forEach(element => {
                    var c = element.options.find(o => o.value === this.state.carrera_id);
                    if (c) {
                        carrera = c;
                    }
                });
                return <>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                        <div className='md:w-1/3'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] items-center flex justify-center">
                                    <IdentificationIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ rfc: e.target.value })} id='textRFC' defaultValue={this.state.rfc} type='text' name="rfc" maxLength={13} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="textRFC" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">RFC</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.rfc}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] items-center flex justify-center">
                                    <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <select onChange={(e) => this.setState({ escolaridad_id: e.target.value })} id='selectEscolaridad' name="escolaridad_id" value={this.state.escolaridad_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                        <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel de Estudios --</option>
                                        {this.state.escolaridades.map(item => {
                                            return <option key={item.id} value={item.id}>{item.nombre}</option>
                                        })}
                                    </select>
                                    <label htmlFor="selectEscolaridad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Máximo de Estudios</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.escolaridad_id}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] items-center flex justify-center">
                                    <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <Select placeholder='--Seleccionar Carrera--'
                                        options={this.state.carreras}
                                        menuPortalTarget={document.body}
                                        className='p-1'
                                        onChange={(carrera) => this.setState({ carrera_id: carrera.value })}
                                        isLoading={this.state.selectLoading}
                                        defaultValue={carrera}
                                        styles={selectStyle}
                                    />
                                    <label htmlFor="selectCarrera" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Carrera</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.carrera_id}</span>
                        </div>
                    </div>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 md:mt-2 mt-8 w-full'>
                        <div className='md:w-1/2'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 pt-2 flex justify-center">
                                    <UserCircleIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ nombre_contacto: e.target.value })} id='txtNombreContacto' defaultValue={this.state.nombre_contacto} type="text" name="nombre_contacto" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtNombreContacto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre de Contacto de Emergencia</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.nombre_contacto}</span>
                        </div>
                        <div className='md:w-1/2 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 pt-2 flex justify-center">
                                    <PhoneIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ telefono_contacto: this.formatPhoneNumber(e.target.value) })} defaultValue={this.state.telefono_contacto} onKeyDown={this.enforceFormat} onKeyUp={this.formatToPhone} id='telTelefonoContacto' type="tel" name="telefono_contacto" maxLength={15} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="telTelefonoContacto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Teléfono de Contacto de Emergencia</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.telefono_contacto}</span>
                        </div>
                    </div>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 md:mt-2 mt-8 w-full'>
                        <div className='md:w-1/3'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path d="M296 96C296 87.16 303.2 80 312 80H328C336.8 80 344 87.16 344 96V120H368C376.8 120 384 127.2 384 136V152C384 160.8 376.8 168 368 168H344V192C344 200.8 336.8 208 328 208H312C303.2 208 296 200.8 296 192V168H272C263.2 168 256 160.8 256 152V136C256 127.2 263.2 120 272 120H296V96zM408 0C447.8 0 480 32.24 480 72V80H568C607.8 80 640 112.2 640 152V440C640 479.8 607.8 512 568 512H71.98C32.19 512 0 479.8 0 440V152C0 112.2 32.24 80 72 80H160V72C160 32.24 192.2 0 232 0L408 0zM480 128V464H568C581.3 464 592 453.3 592 440V336H536C522.7 336 512 325.3 512 312C512 298.7 522.7 288 536 288H592V240H536C522.7 240 512 229.3 512 216C512 202.7 522.7 192 536 192H592V152C592 138.7 581.3 128 568 128H480zM48 152V192H104C117.3 192 128 202.7 128 216C128 229.3 117.3 240 104 240H48V288H104C117.3 288 128 298.7 128 312C128 325.3 117.3 336 104 336H48V440C48 453.3 58.74 464 71.98 464H160V128H72C58.75 128 48 138.7 48 152V152zM208 464H272V400C272 373.5 293.5 352 320 352C346.5 352 368 373.5 368 400V464H432V72C432 58.75 421.3 48 408 48H232C218.7 48 208 58.75 208 72V464z" />
                                    </svg>
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ no_seguro: e.target.value })} id='txtNoSeguro' defaultValue={this.state.no_seguro} maxLength={15} type="text" name="no_seguro" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtNoSeguro" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número de Seguro Médico</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.no_seguro}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 items-center flex justify-center">
                                    <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path d="M16 319.1C16 245.9 118.3 89.43 166.9 19.3C179.2 1.585 204.8 1.585 217.1 19.3C265.7 89.43 368 245.9 368 319.1C368 417.2 289.2 496 192 496C94.8 496 16 417.2 16 319.1zM112 319.1C112 311.2 104.8 303.1 96 303.1C87.16 303.1 80 311.2 80 319.1C80 381.9 130.1 432 192 432C200.8 432 208 424.8 208 416C208 407.2 200.8 400 192 400C147.8 400 112 364.2 112 319.1z" />
                                    </svg>
                                </div>
                                <div className="relative z-0 w-full group">
                                    <select onChange={(e) => this.setState({ tipo_sangre: e.target.value })} id='selectTipoSangre' name="tipo_sangre" value={this.state.tipo_sangre} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                        <option value="0" disabled className='text-gray-400'>-- Seleccione un Tipo de Sangre --</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                    </select>
                                    <label htmlFor="selectTipoSangre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Tipo de Sangre</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.tipo_sangre}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 flex justify-center items-center">
                                    <svg className='h-6 w-6 fill-gray-400 group-focus-within:fill-accent-1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M448 96c-17.67 0-32 14.33-32 32v112C416 248.8 408.8 256 400 256s-15.93-7.164-15.93-16L384 64c0-17.67-14.33-32-32-32s-32 14.33-32 32l.0498 176c0 8.836-7.219 16-16.06 16s-15.95-7.164-15.95-16L288 32c0-17.67-14.33-32-32-32S224 14.33 224 32l.0729 208C224.1 248.8 216.8 256 208 256S192.1 248.8 192.1 240L192 64c0-17.67-14.33-32-32-32S128 46.33 128 64v279.4L68.28 283.7C60.47 275.9 50.23 272 40 272C18.68 272 0 289.2 0 312c0 10.23 3.906 20.47 11.72 28.28l113.1 113.1C162.6 491.2 212.9 512 266.3 512H304c97.05 0 176-78.95 176-176V128C480 110.3 465.7 96 448 96zM192 416c-8.836 0-16-7.164-16-16C176 391.2 183.2 384 192 384s16 7.162 16 16C208 408.8 200.8 416 192 416zM256 448c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C272 440.8 264.8 448 256 448zM256 352c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C272 344.8 264.8 352 256 352zM320 384c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C336 376.8 328.8 384 320 384zM352 448c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C368 440.8 360.8 448 352 448zM384 352c-8.836 0-16-7.164-16-16c0-8.838 7.164-16 16-16s16 7.162 16 16C400 344.8 392.8 352 384 352z" />
                                    </svg>
                                </div>
                                <div className="relative z-0 w-full group">
                                    <textarea onChange={(e) => this.setState({ alergias: e.target.value })} id='taAlergias' defaultValue={this.state.alergias} type="text" name="alergias" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                    <label htmlFor="taAlergias" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Alergias</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.alergias}</span>
                        </div>
                    </div>
                </>
            case 'tutores':
                return <>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                        <div className='md:w-1/3'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] items-center flex justify-center">
                                    <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <select onChange={(e) => this.setState({ parentesco_id: e.target.value })} id='selectParentesco' name="parentesco_id" value={this.state.parentesco_id} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                        <option value="0" disabled className='text-gray-400'>-- Seleccione un Parentesco --</option>
                                        {this.state.parentescos.map(item => {
                                            return <option key={item.id} value={item.id}>{item.nombre}</option>
                                        })}
                                    </select>
                                    <label htmlFor="selectParentesco" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Parentesco con el almuno(a)</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.parentesco_id}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ email_personal: e.target.value })} id='emailEmailPersonal' defaultValue={this.state.email_personal} type="email" name="email_personal" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="emailEmailPersonal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Correo Personal</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.email_personal}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] items-center flex justify-center">
                                    <UserGroupIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ num_hijos: e.target.value })} id='numberNumHijos' defaultValue={this.state.num_hijos} type='number' name="num_hijos" min={0} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="numberNumHijos" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número de Hijos</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.num_hijos}</span>
                        </div>
                    </div>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 md:mt-2 mt-8 w-full'>
                        <div className='md:w-1/3'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ ocupacion: e.target.value })} id='txtOcupacion' defaultValue={this.state.ocupacion} type="text" name="ocupacion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                    <label htmlFor="txtOcupacion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Ocupación (Opcional)</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.ocupacion}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <PhoneIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => this.setState({ tel_trabajo: this.formatPhoneNumber(e.target.value) })} defaultValue={this.state.tel_trabajo} onKeyDown={this.enforceFormat} onKeyUp={this.formatToPhone} id='telTelTrabajo' type='tel' name="tel_trabajo" maxLength={16} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " />
                                    <label htmlFor="telTelTrabajo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Teléfono Trabajo (Opcional)</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.tel_trabajo}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] items-center flex justify-center">
                                    <AcademicCapIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <select onChange={(e) => this.setState({ escolaridad_id: e.target.value })} id='selectEscolaridad' name="escolaridad_id" value={this.state.escolaridad_id} className="capitalize block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                        <option value="0" disabled className='text-gray-400'>-- Seleccione Nivel de Estudios --</option>
                                        {this.state.escolaridades.map(item => {
                                            return <option key={item.id} value={item.id}>{item.nombre}</option>
                                        })}
                                    </select>
                                    <label htmlFor="selectEscolaridad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nivel Máximo de Estudios</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{this.state.errors.escolaridad_id}</span>
                        </div>
                    </div>
                </>
            default:
                break;
        }
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, 
            errors: [{
                // Domicilio errors
                direccion: '', codigo_postal: '', colonia: '', municipio: '',
                // Persona errors
                nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
                // User Type errors
                rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: '', carrera_id: '',
                nombre_contacto: '', telefono_contacto: '', parentesco_id: '', email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '',
            }] 
        });

        const data = new FormData();
        data.append('profile', 1);
        // Domicilio info
        data.append('direccion', this.state.direccion);
        data.append('codigo_postal', this.state.codigo_postal);
        data.append('colonia', this.state.colonia);
        data.append('municipio', this.state.municipio);
        // Persona info 
        data.append('persona_id', this.state.persona_id);
        data.append('nombre', this.state.nombre);
        data.append('apellido', this.state.apellido);
        data.append('fecha_nacimiento', this.state.fecha_nacimiento);
        data.append('sexo', this.state.sexo);
        data.append('nacionalidad', this.state.nacionalidad);
        data.append('no_celular', this.state.no_celular);
        data.append('curp', this.state.curp);
        data.append('estado_civil', this.state.estado_civil);
        // User Type info
        if (this.props.user_type === 'administrativos') {
            data.append('rfc', this.state.rfc);
            data.append('no_seguro', this.state.no_seguro);
            data.append('tipo_sangre', this.state.tipo_sangre);
            data.append('alergias', this.state.alergias === null ? '' : this.state.alergias);
            data.append('escolaridad_id', this.state.escolaridad_id);
            data.append('carrera_id', this.state.carrera_id);
            data.append('nombre_contacto', this.state.nombre_contacto);
            data.append('telefono_contacto', this.state.telefono_contacto);
            data.append('estatus', true);

            RestClient.PostRequest(AppUrl.UpdateAdministrativoProfile + this.props.id, data).then((result) => {
                this.setState({ processingRequest: false, errors: result.data, statusResult: result.status });
                if (result.status === 200) {
                    toast.success(result.message);
                    this.setState({
                        errors: [{
                            // Domicilio errors
                            direccion: '', codigo_postal: '', colonia: '', municipio: '',
                            // Persona errors
                            nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
                            // User Type errors
                            rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: '', carrera_id: '',
                            nombre_contacto: '', telefono_contacto: '', parentesco_id: '', email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '',
                        }] 
                    });
                }
                else {
                    console.log(result);
                    toast.error(result.message);
                }
            });
        } else if (this.props.user_type === 'tutores') {
            data.append('parentesco_id', this.state.parentesco_id);
            data.append('email_personal', this.state.email_personal);
            data.append('num_hijos', this.state.num_hijos);
            data.append('ocupacion', this.state.ocupacion === null ? '' : this.state.ocupacion);
            data.append('tel_trabajo', this.state.tel_trabajo === null ? '' : this.state.tel_trabajo);
            data.append('escolaridad_id', this.state.escolaridad_id);

            RestClient.PostRequest(AppUrl.UpdateTutor + this.props.id, data).then((result) => {
                this.setState({ processingRequest: false, errors: result.data, statusResult: result.status });
                if (result.status === 200) {
                    toast.success(result.message);
                    this.setState({
                        errors: [{
                            // Domicilio errors
                            direccion: '', codigo_postal: '', colonia: '', municipio: '',
                            // Persona errors
                            nombre: '', apellido: '', fecha_nacimiento: '', sexo: '', nacionalidad: '', no_celular: '', curp: '', estado_civil: '',
                            // User Type errors
                            rfc: '', no_seguro: '', tipo_sangre: '', alergias: '', escolaridad_id: '', carrera_id: '',
                            nombre_contacto: '', telefono_contacto: '', parentesco_id: '', email_personal: '', num_hijos: '', ocupacion: '', tel_trabajo: '',
                        }] 
                    });
                }
                else {
                    console.log(result);
                    toast.error(result.message);
                }
            });
        }
        
    }

    render() {
        return (
            <div className='flex-grow'>
                {/* Panel Body */}
                <div className='p-6 space-y-6'>
                    <h2 className='text-2xl tracking-[-.01em] text-slate-800 font-bold mb-6'>Datos Personales</h2>
                    {/* Persona */}
                    <section className='md:w-11/12'>
                        <button type='button' onClick={() => this.setState({ activeSection: 'Persona' })} className='hover:underline inline-flex items-center text-xl tracking-[-.01em] text-slate-800 font-bold mb-1'>
                            Persona
                            <ChevronRightIcon className={`${this.state.activeSection === 'Persona' ? 'rotate-90' : 'rotate-0'} h-6 mt-1 duration-300`} />
                        </button>
                        {!this.state.loaded ?
                            <div className='flex w-full justify-center bg-white'>
                                <Loading />
                            </div> : this.state.activeSection === 'Persona' &&
                            <>
                                <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                                    <div className='md:w-1/3'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-[12%] pt-2 flex justify-center">
                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input id='txtNombre' onChange={(e) => this.setState({ nombre: e.target.value })} defaultValue={this.state.nombre} type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre(s)</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.nombre}</span>
                                    </div>
                                    <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-[12%] pt-2 flex justify-center">
                                                <UserIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input id='txtApellido' onChange={(e) => this.setState({ apellido: e.target.value })} defaultValue={this.state.apellido} type="text" name="apellido" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="txtApellido" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Apellidos</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.apellido}</span>
                                    </div>
                                    <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-[12%] pt-2 flex justify-center">
                                                <CalendarIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ fecha_nacimiento: e.target.value })} id='dateFechaNacimiento' defaultValue={this.state.fecha_nacimiento} type='date' name="fecha_nacimiento" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="dateFechaNacimiento" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Fecha de Nacimiento</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.fecha_nacimiento}</span>
                                    </div>
                                </div>
                                <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 md:mt-2 mt-8 w-full'>
                                    <div className='md:w-1/3'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-[12%] pt-2 flex justify-center">
                                                <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <select onChange={(e) => this.setState({ sexo: e.target.value })} id='selectSexo' name="sexo" value={this.state.sexo} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione un Sexo --</option>
                                                    <option value="nk">Desconocido</option>
                                                    <option value="m">Hombre</option>
                                                    <option value="f">Mujer</option>
                                                    <option value="na">No Aplicable</option>
                                                </select>
                                                <label htmlFor="selectSexo" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Sexo</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.sexo}</span>
                                    </div>
                                    <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-[12%] pt-2 flex justify-center">
                                                <GlobeIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ nacionalidad: e.target.value })} id='textNacionalidad' defaultValue={this.state.nacionalidad} type='text' name="nacionalidad" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="textNacionalidad" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nacionalidad</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.nacionalidad}</span>
                                    </div>
                                    <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-[12%] pt-2 flex justify-center">
                                                <IdentificationIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ curp: e.target.value })} id='textCURP' defaultValue={this.state.curp} type='text' name="curp" maxLength={18} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="textCURP" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">CURP</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.curp}</span>
                                    </div>
                                </div>
                                <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 md:mt-2 mt-8 w-full'>
                                    <div className='md:w-1/2'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <HeartIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <select onChange={(e) => this.setState({ estado_civil: e.target.value })} id='selectEstadoCivil' name="estado_civil" value={this.state.estado_civil} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required>
                                                    <option value="0" disabled className='text-gray-400'>-- Seleccione un Estado Civil --</option>
                                                    <option value="1">Casado(a)</option>
                                                    <option value="2">Viudo(a)</option>
                                                    <option value="3">Separado(a)</option>
                                                    <option value="4">Divorciado(a)</option>
                                                    <option value="5">Soltero(a)</option>
                                                </select>
                                                <label htmlFor="selectEstadoCivil" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Estado Civil</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.estado_civil}</span>
                                    </div>
                                    <div className='md:w-1/2 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <DeviceMobileIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ no_celular: this.formatPhoneNumber(e.target.value) })} defaultValue={this.state.no_celular} onKeyDown={this.enforceFormat} onKeyUp={this.formatToPhone} id='telNumCel' type="tel" name="no_celular" maxLength={15} className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="telNumCel" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Número Celular</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.no_celular}</span>
                                    </div>
                                </div>
                            </>}
                    </section>
                    {/* Domicilio */}
                    <section className='md:w-11/12'>
                        <button type='button' onClick={() => this.setState({ activeSection: 'Domicilio' })} className='hover:underline inline-flex items-center text-xl tracking-[-.01em] text-slate-800 font-bold mb-1'>
                            Domicilio
                            <ChevronRightIcon className={`${this.state.activeSection === 'Domicilio' ? 'rotate-90' : 'rotate-0'} h-6 mt-1 duration-300`} />
                        </button>
                        {this.state.activeSection === 'Domicilio' && !this.state.loaded ?
                            <div className='flex w-full justify-center bg-white'>
                                <Loading />
                            </div> : this.state.activeSection === 'Domicilio' &&
                            <>
                                <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                                    <div className='md:w-1/2'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <HomeIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ direccion: e.target.value })} id='txtDireccion' defaultValue={this.state.direccion} type="text" name="direccion" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="txtDireccion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Dirección</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.direccion}</span>
                                    </div>
                                    <div className='md:w-1/2 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <HashtagIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ codigo_postal: e.target.value })} id='txtCodigoPostal' defaultValue={this.state.codigo_postal} type="text" name="codigo_postal" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="txtCodigoPostal" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Código Postal</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.codigo_postal}</span>
                                    </div>
                                </div>
                                <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 md:mt-2 mt-8 w-full'>
                                    <div className='md:w-1/2'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <LocationMarkerIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ colonia: e.target.value })} id='txtColonia' defaultValue={this.state.colonia} type="text" name="colonia" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="txtColonia" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Colonia</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.colonia}</span>
                                    </div>
                                    <div className='md:w-1/2 md:-translate-y-8 -translate-y-0'>
                                        <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                            <div className="w-1/12 pt-2 flex justify-center">
                                                <LibraryIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input onChange={(e) => this.setState({ municipio: e.target.value })} id='txtMunicipio' defaultValue={this.state.municipio} type="text" name="municipio" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                <label htmlFor="txtMunicipio" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Municipio</label>
                                            </div>
                                        </div>
                                        <span className='text-sm text-red-500'>{this.state.errors.municipio}</span>
                                    </div>
                                </div>
                            </>}
                    </section>
                    {/* User Type Info */}
                    <section className='md:w-11/12'>
                        <button type='button' onClick={() => this.setState({ activeSection: this.props.user_type })} className='hover:underline inline-flex items-center text-xl tracking-[-.01em] text-slate-800 font-bold mb-1'>
                            <span className='capitalize'>{this.props.user_type}</span>
                            <ChevronRightIcon className={`${this.state.activeSection === this.props.user_type ? 'rotate-90' : 'rotate-0'} h-6 mt-1 duration-300`} />
                        </button>
                        {this.state.activeSection === this.props.user_type && !this.state.loaded ?
                            <div className='flex w-full justify-center bg-white'>
                                <Loading />
                            </div> : this.state.activeSection === this.props.user_type &&
                            this.renderUserTypeSection(this.props.user_type)}
                    </section>
                </div>
                {/* Panel Footer */}
                <footer>
                    <div className='flex flex-col px-6 py-5 border-t border-slate-200'>
                        <div className='flex self-end'>
                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </button>
                            <button type='button' onClick={this.onFormSubmit} hidden={this.state.processingRequest || !this.state.loaded} className='text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>Guardar Cambios</button>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

}

export default DatosPanel