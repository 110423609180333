import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { CogIcon, FilterIcon, PencilAltIcon, XIcon } from '@heroicons/react/solid';
import ReactModal from 'react-modal';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
import Select from 'react-select';
import RespuestaComponent from '../form-controls/RespuestaComponent';
import { Node } from 'slate';
import { jsPDF } from "jspdf";
import { AddButton, CustomLoader, DeleteButton, EditButton, FilterComponent, NoDataComponent, customStyles } from '../CustomTableComponents';


const FilterColumns = ({ onCheckChange, selectedColumns }) => {
    const [open, setOpen] = React.useState(false);
    const [newColumns, setNewColumns] = React.useState(selectedColumns);
    const [clase, setClase] = React.useState('');

    React.useMemo(() => {
        const filterMenu = document.getElementById("divFilterMenu");
        const wrapper = document.getElementsByClassName('sc-dmRaPn')[0];
        if (filterMenu && wrapper) {
            document.addEventListener("click", (event) => {
                const isClickInside = filterMenu.contains(event.target);
                if (!isClickInside) {
                    setOpen(false);
                    setNewColumns(selectedColumns);
                    wrapper.classList.add(clase);
                }
            });
        }
    }, [clase, selectedColumns]);

    function handleOpen(value) {
        setOpen(value);
        const wrapper = document.getElementsByClassName('sc-dmRaPn')[0];
        if (value === false) {
            setNewColumns(selectedColumns);
            wrapper.classList.add(clase);
        }
        else {
            // save clases
            setClase(wrapper.classList[1]);
            wrapper.classList.remove(wrapper.classList[1]);
        }
    }

    function handleCheck(checkbox) {
        if (checkbox.checked) {
            setNewColumns(prevState => ([...prevState, checkbox.name]));
        }
        else {
            setNewColumns(prevState => (prevState.filter(c => c !== checkbox.name)));
        }
    }
    
    function saveNewColumns() {
        onCheckChange(newColumns);
        setOpen(false);
        // Save custom settings locally
        localStorage.setItem(
            'custom-peticiones-columns',
            newColumns
        );
        toast.success('Se guardaron tus configuraciones localmente');
        const wrapper = document.getElementsByClassName('sc-dmRaPn')[0];
        wrapper.classList.add('gelpCx');
    }

    return (
        <div id='divFilterMenu' className="inline-block text-left">
            <span className="rounded-md shadow-sm">
                <button onClick={() => handleOpen(!open)} className={`${open ? 'text-accent-2' : 'text-gray-300 hover:text-white'}`} type="button" title='Filtrar Columnas' aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                    <span className="sr-only">Filtrar Columnas</span>
                    <FilterIcon className='w-5 h-5 fill-current' />
                </button>
            </span>
            <div id='divFilterMenuBody' className={`dropdown-menu absolute ${!open && 'invisible opacity-0 -translate-y-2 scale-95'} -translate-x-1 origin-bottom transform transition-all duration-300 z-10`}>
                <div className={`origin-bottom mt-2 w-48 divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                    <div className="p-2">
                        <span className='text-black'>Selector de columnas</span>
                    </div>
                    <div className="p-2">
                        <ul className='text-gray-800 w-full flex flex-col space-y-1'>
                            <li className='w-full'>
                                <input type="checkbox" name="id" id="id" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'id')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="id">ID</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="titulo" id="titulo" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'titulo')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="titulo">Título</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="descripcion" id="descripcion" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'descripcion')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="descripcion">Descripción</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="categoria" id="categoria" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'categoria')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="categoria">Categoría</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="subcategoria" id="subcategoria" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'subcategoria')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="subcategoria">Subcategoría</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="estatus" id="estatus" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'estatus')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="estatus">Estatus</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="usuario" id="usuario" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'usuario')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="usuario">Solicitante</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="asignado" id="asignado" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'asignado')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="asignado">Asignado</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="departamento" id="departamento" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'departamento')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="departamento">Departamento</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="prioridad" id="prioridad" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'prioridad')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="prioridad">Prioridad</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="vence_en" id="vence_en" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'vence_en')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="vence_en">Vence En</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="colaboradores" id="colaboradores" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'colaboradores')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="colaboradores">Colaboradores</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="created_at" id="created_at" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'created_at')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="created_at">Fecha Registrado</label>
                            </li>
                            <li className='w-full'>
                                <input type="checkbox" name="updated_at" id="updated_at" onChange={(e) => handleCheck(e.target)} checked={newColumns.some(c => c === 'updated_at')} className='w-1/3 accent-accent-2' />
                                <label htmlFor="updated_at">Fecha Actualizado</label>
                            </li>
                        </ul>
                    </div>
                    <div className='p-2'>
                        <div className='w-full flex justify-around'>
                            <button type='button' onClick={saveNewColumns} className='bg-accent-1 p-2 rounded-2xl hover:bg-accent-2'>Guardar</button>
                            <button type='button' onClick={() => handleOpen(false)} className='bg-gray-400 p-2 rounded-2xl hover:bg-gray-500'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const integerSort = (rowA, rowB) => {
	const a = parseInt(rowA.id);
	const b = parseInt(rowB.id);

	return a - b;
};

const dateStort = (rowA, rowB) => {
    return new Date(rowA.vence_en) - new Date(rowB.vence_en);
}

function convertArrayOfObjectsToCSV(array) {
    let result;
    
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            if (key === 'descripcion') {
                let plainTextArray = JSON.parse(item[key]).map(n => Node.string(n));
                let plainTextConcat = "";
                if (plainTextArray.length > 1) {
                    plainTextArray.forEach(element => {
                        plainTextConcat += element;
                    });
                    result += plainTextConcat;
                } else {
                    result += plainTextArray[0];
                }
            } else {
                result += item[key];
            }

            ctr++;
            
        });
        result += lineDelimiter;
    });

    return result;
}

function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'peticiones.csv';

    link.setAttribute('href', 'data:text/csv;charset=UTF-8,\uFEFF' + encodeURIComponent(csv));
    link.setAttribute('download', filename);
    link.click();
    link.remove();
}

const ExportCSV = ({ onExport }) => {
    return <button type='button' onClick={e => onExport(e.target.value)} className='inline-flex justify-between bg-white rounded-lg shadow-md px-3 py-2 gap-2 hover:bg-accent-2 duration-300 items-center group'>
        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5 fill-accent-2 group-hover:fill-white duration-300' viewBox="0 0 384 512">
            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 224H96c22.1 0 40 17.9 40 40v8c0 8.8-7.2 16-16 16s-16-7.2-16-16v-8c0-4.4-3.6-8-8-8H80c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8H96c4.4 0 8-3.6 8-8v-8c0-8.8 7.2-16 16-16s16 7.2 16 16v8c0 22.1-17.9 40-40 40H80c-22.1 0-40-17.9-40-40V264c0-22.1 17.9-40 40-40zm72 46.4c0-25.6 20.8-46.4 46.4-46.4H216c8.8 0 16 7.2 16 16s-7.2 16-16 16H198.4c-7.9 0-14.4 6.4-14.4 14.4c0 5.2 2.8 9.9 7.2 12.5l25.4 14.5c14.4 8.3 23.4 23.6 23.4 40.3c0 25.6-20.8 46.4-46.4 46.4H168c-8.8 0-16-7.2-16-16s7.2-16 16-16h25.6c7.9 0 14.4-6.4 14.4-14.4c0-5.2-2.8-9.9-7.2-12.5l-25.4-14.5C160.9 302.4 152 287 152 270.4zM280 240v31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66V240c0-8.8 7.2-16 16-16s16 7.2 16 16v31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6V240c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
            </svg>
        <span className='text-accent-1 font-bold text-sm group-hover:text-white duration-300'>Exportar CSV</span>
    </button>
}

const ExportPDF = ({ onExport }) => {
    return <button type='button' onClick={e => onExport(e.target.value)} className='inline-flex justify-between bg-white rounded-lg shadow-md px-3 py-2 gap-2 hover:bg-accent-2 duration-300 items-center group'>
        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5 fill-accent-2 group-hover:fill-white duration-300' viewBox="0 0 384 512">
            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z"/></svg>
        <span className='text-accent-1 font-bold text-sm group-hover:text-white duration-300'>Exportar PDF</span>
    </button>
}

function createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
        result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 55,
            align: "center",
            padding: 0
        });
    }
    return result;
}

function downloadPDF(data) {
    var pdf = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
    var customKeys = localStorage.getItem('custom-peticiones-columns') === null ?
        ['id', 'titulo', 'estatus', 'usuario', 'departamento', 'created_at', 'updated_at'] :
        localStorage.getItem('custom-peticiones-columns').split(',');
    var headers = createHeaders(customKeys);
    // Convert nulls and RTxt
    const newData = data.map(d => {
        // Convert nulls into empty strings
        Object.values(d).forEach(val => {
            if (val === null) {
                d = {...d, [Object.keys(d).find(key => d[key] === val)]: ""};
            }
        });
        //Transform RTxt into plainText
        let plainTextArray = JSON.parse(d.descripcion).map(n => Node.string(n));
        if (plainTextArray.length > 1) {
            let plainTextConcat = "";
            plainTextArray.forEach(element => {
                plainTextConcat += element;
            });
            return {...d, descripcion: plainTextConcat};
        } else return {...d, descripcion: plainTextArray[0]};
        
    });
    // Convert Keys
    newData.forEach(d => {
        Object.defineProperty(d, 'usuario', Object.getOwnPropertyDescriptor(d, 'nombre'));
        d.usuario += ' ' + d.apellido;
        delete d['apellido'];
        delete d['nombre'];
        Object.defineProperty(d, 'departamento', Object.getOwnPropertyDescriptor(d, 'depto'));
        delete d['depto'];
        Object.defineProperty(d, 'asignado', Object.getOwnPropertyDescriptor(d, 'asignado_nombre'));
        d.asignado += ' ' + d.asignado_apellido;
        delete d['asignado_nombre'];
        delete d['asignado_apellido'];
    });
    pdf.table(1, 1, newData, headers);
    pdf.save("peticiones.pdf");
}
  
ReactModal.setAppElement('#root');

const PeticionesTable = ({ data, controls, hasUpdated, authUser, report = false }) => {

    const _data = [{}];
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [pending, setPending] = React.useState(true);
    const [filteredItems, setFilteredItems] = React.useState(_data);
    const [showModal, setShowModal] = React.useState(false);
    const [showModalDepto, setShowModalDepto] = React.useState(false);
    const [showModalUsuario, setShowModalUsuario] = React.useState(false);
    const [dataId, setDataId] = React.useState(0);
    const [processingRequest, setProcessingRequest] = React.useState(false);
    const [selectedColumns, setSelectedColumns] = React.useState(
        localStorage.getItem('custom-peticiones-columns') === null ?
        ['id', 'titulo', 'estatus', 'usuario', 'departamento', 'created_at', 'updated_at'] :
        localStorage.getItem('custom-peticiones-columns').split(',')
    );

    // eslint-disable-next-line
    handleOpenModal = handleOpenModal.bind(this);
    // eslint-disable-next-line
    handleCloseModal = handleCloseModal.bind(this);
    // eslint-disable-next-line
    handleOpenModalDepto = handleOpenModalDepto.bind(this);
    // eslint-disable-next-line
    handleEditModalDepto = handleEditModalDepto.bind(this);
    // eslint-disable-next-line
    handleCloseModalDepto = handleCloseModalDepto.bind(this);

    const actionsAdd = React.useMemo(() => <AddButton route={authUser?.user_type !== 'administrativos' ? '/peticiones/crear-panel' : '/peticiones/crear'} disabled={!controls.includes('create')} label='Nueva Petición' />, [authUser, controls]);

    const actionsExport = React.useMemo(() => {
        if (data && data.length > 0) {
            return <>
                <ExportCSV onExport={() => downloadCSV(data)} />
                <ExportPDF onExport={() => downloadPDF(data)} />
            </>
        }
    }, [data]);

    // Prevent scroll when Modal is open
    useEffect(() => {
        let html = document.querySelector('html');
        (showModalDepto || showModalUsuario) && (html.style.overflowY = 'hidden');
        !showModalDepto && !showModalUsuario && (html.style.overflowY = 'unset');
    }, [showModalDepto, showModalUsuario]);

    function handleOpenModal(dataId) {
        setShowModal(true);
        setDataId(dataId);   
    }
    function handleCloseModal() {
        setShowModal(false);
    }
    function handleOpenModalDepto(dataId) {
        setShowModalDepto(true);
        setDataId(dataId);   
    }
    function handleOpenModalUsuario(dataId) {
        setShowModalUsuario(true);
        setDataId(dataId);   
    }
    const [selectedDepto, setSelectedDepto] = React.useState({label: '', value: ''})
    function handleEditModalDepto(row) {
        setSelectedDepto({label: row.depto, value: row.departamento_id});
        setShowModalDepto(true);
        setDataId(row.id);   
    }
    const [selectedUser, setSelectedUser] = React.useState({label: '', value: ''})
    function handleEditModalUsuario(row) {
        setSelectedUser({label: row.asignado_nombre + ' ' + row.asignado_apellido, value: row.asignado_id});
        setShowModalUsuario(true);
        setDataId(row.id);   
    }
    function handleCloseModalDepto() {
        setShowModalDepto(false);
    }
    function handleCloseModalUsuario() {
        setShowModalUsuario(false);
    }

    function handleDelete() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        RestClient.GetRequest(AppUrl.DeletePeticion + dataId).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
            }
            else if (result.status === 204) {
                toast.warn(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
            handleCloseModal();
            //TO DO: Refresh page upon delete
        });
    }

    // Handles department asign
    const [departamento_id, setDepartamentoId] = React.useState(0);
    function asignarDepto() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('departamento_id', departamento_id);
        data.append('usuario_id', authUser.id);

        RestClient.PostRequest(AppUrl.AsignDepartamento + dataId, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                handleCloseModalDepto();
                hasUpdated();
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    // Handles user asign
    const [asignado_id, setAsignadoId] = React.useState(0);
    function asignarUsuario() {
        //Disable controls while request is processed
        setProcessingRequest(true);

        const data = new FormData();
        data.append('asignado_id', asignado_id);
        data.append('usuario_id', authUser.id);

        RestClient.PostRequest(AppUrl.AsignUsuario + dataId, data).then((result) => {
            setProcessingRequest(false);
            if (result.status === 200) {
                toast.success(result.message);
                handleCloseModalUsuario();
                hasUpdated();
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    // Get Departamentos
    const [departamentos, setDepartamentos] = React.useState([]);
    const [selectLoaded, setSelectLoaded] = React.useState(false);
    useEffect(()=> {
        RestClient.GetRequest(AppUrl.Departamentos).then((result) => {
            setDepartamentos([]);
            result.forEach(element => {
                setDepartamentos(prevState => [...prevState, {value: element.id, label: element.nombre}]);
            });
        });
        setSelectLoaded(true);
    }, []);
    // Get Usuarios
    const [usuarios, setUsuarios] = React.useState([]);
    useEffect(()=> {
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({label: depto, options: [{value: element.usuario_id, label: element.nombre + ' ' + element.apellido}]});
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({value: element.usuario_id, label: element.nombre + ' ' + element.apellido});
                }
            });
            setUsuarios(groupedOptions);
        });
    }, []);

    useEffect(() => {
        if (data) {
            setFilteredItems(data.filter(
                item => (item.id && item.id.toString().toLowerCase().includes(filterText.toLowerCase()))
                    || (item.titulo && item.titulo.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.descripcion && item.descripcion.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.categoria && item.categoria.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.subcategoria && item.subcategoria.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.apellido && item.apellido.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.depto && item.depto.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.asignado_nombre && item.asignado_nombre.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.asignado_apellido && item.asignado_apellido.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.estatus && item.estatus.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.prioridad && item.prioridad.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()))
                    || (item.updated_at && item.updated_at.toLowerCase().includes(filterText.toLowerCase()))
            ));
            setPending(false);
        }
    }, [data, filterText])

    const columns = [
        {
            name: <FilterColumns onCheckChange={setSelectedColumns} selectedColumns={selectedColumns} />,
            cell: row => 
                !report && (<div className="dropdown relative text-left">
                    <span className="rounded-md shadow-sm">
                        <button className="group max-w-xs rounded-full flex items-center text-sm focus:outline-none" type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items">
                            <span className="sr-only">Open table menu</span>
                            <CogIcon className='w-5 h-5 text-accent-1 hover:rotate-45 transition duration-300 group-focus:rotate-45' />
                        </button>
                    </span>
                    <div className={`dropdown-menu absolute invisible ${(row.id >= filteredItems.length - 1 && 'origin-bottom-left') || 'origin-top-left'} -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 z-10`}>
                        <div className={`left-4 translate-x-6 ${(row.id >= filteredItems.length - 1 && '-translate-y-24 origin-bottom-left') || 'origin-top-left'} mt-2 w-36  divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none z-50`} aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items" role="menu">
                            <div className="py-1">
                                <EditButton route={`/peticiones/editar/${row.id}`} itemId={row.id} disabled={!controls.includes('update')} />
                                <DeleteButton itemId={row.id} onClickDelete={() => handleOpenModal(row.id)} disabled={!controls.includes('delete')} />
                            </div>
                        </div>
                    </div>
                </div>),
            button: true,
            width: !report ? '80px' : '50px',
            allowOverflow: true,
        },
        {
            name: 'ID',
            selector: row => row.id,
            maxWidth: '80px',
            sortable: true,
            sortFunction: integerSort,
            omit: !selectedColumns.some(sc => sc === 'id')
        },
        {
            name: 'Título',
            selector: row => row.titulo,
            wrap: true,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'titulo')
        },
        {
            name: 'Descripción',
            selector: row => <RespuestaComponent value={row.descripcion} />,
            wrap: true,
            grow: 1.1,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'descripcion')
        },
        {
            name: 'Categoría',
            selector: row => row.categoria,
            wrap: true,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'categoria')
        },
        {
            name: 'Subcategoría',
            selector: row => row.subcategoria,
            wrap: true,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'subcategoria')
        },
        {
            name: 'Solicitante',
            selector: row => row.nombre + ' ' + row.apellido,
            wrap: true,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'usuario')
        },
        {
            name: 'Departamento',
            selector: row => row.depto, 
            cell: row => row.depto === null ? 
            <div className='flex-col mx-auto my-2 space-y-2'>
                <span className='italic text-gray-500'>(Sin asignar)</span>
                {controls.includes('update') && authUser.user_type === 'administrativos' &&
                <button id={'btnAsignDept' + row.id} onClick={() => handleOpenModalDepto(row.id)} type='button' className='block bg-accent-1 text-white p-2 rounded-xl font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                    Asignar Departamento
                </button>}
            </div> : 
            <div className='flex gap-2 items-center'>
                {controls.includes('update') && authUser.user_type === 'administrativos' &&
                <button id={'btnAsignDept' + row.id} onClick={() => handleEditModalDepto(row)} type='button' title='Cambiar Departamento'>
                    <PencilAltIcon className='bg-gray-200 p-1 rounded-full h-7 text-accent-1 hover:text-accent-2' />
                </button>}
                <span>{row.depto}</span>
            </div>,
            wrap: true,
            grow: 1.3,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'departamento')
        },
        {
            name: 'Asignado A',
            selector: row => row.asignado_nombre, 
            cell: row => row.asignado_id === null ? 
            <div className='flex-col mx-auto my-2 space-y-2'>
                <span className='italic text-gray-500'>(Sin asignar)</span>
                {controls.includes('update') && authUser.user_type === 'administrativos' &&
                <button id={'btnAsignUser' + row.id} onClick={() => handleOpenModalUsuario(row.id)} type='button' className='block bg-accent-1 text-white p-2 rounded-xl font-semibold hover:bg-accent-2 hover:-translate-y-1 transition duration-300'>
                    Asignar Usuario
                </button>}
            </div> : 
            <div className='flex gap-2 items-center'>
                {controls.includes('update') && authUser.user_type === 'administrativos' &&
                <button id={'btnAsignUser' + row.id} onClick={() => handleEditModalUsuario(row)} type='button' title='Cambiar Usuario'>
                    <PencilAltIcon className='bg-gray-200 p-1 rounded-full h-7 text-accent-1 hover:text-accent-2' />
                </button>}
                <span>{row.asignado_nombre + ' ' + row.asignado_apellido}</span>
            </div>,
            wrap: true,
            grow: 1.3,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'asignado')
        },
        {
            name: 'Estatus',
            selector: row => row.estatus,
            cell: row =>  
            <div className='inline-flex items-center gap-2'>
                <div className='w-6 h-6 m-1 rounded-lg shadow-md' style={{backgroundColor: row.color}}>
                    <div className="h-full w-full rounded-lg border border-accent-1 p-0"></div>
                </div>
                <span>{row.estatus}</span>
            </div>,
            wrap: true,
            grow: 1.3,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'estatus')
        },
        {
            name: 'Prioridad',
            selector: row => row.prioridad,
            cell: row => row.prioridad === null ? 
             <span className='italic text-gray-500'>(Sin asignar)</span> :
            <div className='inline-flex items-center gap-2'>
                <div className='w-6 h-6 m-1 rounded-lg shadow-md block' style={{backgroundColor: row.prioridad_color}}>
                    <div className="h-full w-full rounded-lg border border-accent-1 p-0"></div>
                </div>
                <span>{row.prioridad}</span>
            </div>,
            wrap: true,
            grow: 1.3,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'prioridad')
        },
        {
            name: 'Vence En',
            selector: row => row.vence_en,
            cell: row => row.vence_en === null ? 
            <span className='italic text-gray-500'>(Sin definir)</span> :
            <span>{row.vence_en}</span>,
            grow: 0.7,
            sortable: true,
            sortFunction: dateStort,
            omit: !selectedColumns.some(sc => sc === 'vence_en')
        },
        {
            name: 'Colaboradores',
            selector: row => row.colaboradores,
            cell: row => row.colaboradores === null ?
            <span className='italic text-gray-500'>(Sin colaboradores)</span> :
            <div>{row.colaboradores_nombres.map((nombre, index) => index !== row.colaboradores_nombres.length - 1 ? nombre + ', ' : nombre)}</div>,
            wrap: true,
            grow: 0.9,
            sortable: false,
            omit: !selectedColumns.some(sc => sc === 'colaboradores')
        },
        {
            name: 'Fecha Registrado',
            selector: row => row.created_at,
            wrap: true,
            grow: 0.8,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'created_at')
        },
        {
            name: 'Fecha Actualizado',
            selector: row => row.updated_at,
            wrap: true,
            grow: 0.8,
            sortable: true,
            omit: !selectedColumns.some(sc => sc === 'updated_at')
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                title='Peticiones'
                columns={columns}
                data={filteredItems}
                actions={report ? actionsExport : actionsAdd}
                customStyles={customStyles}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                striped
                progressPending={pending}
                progressComponent={<CustomLoader />}
                noDataComponent={<NoDataComponent />}
                
            />
            {/* Modal Asignar Depto */}
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModalDepto}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={() => handleCloseModalDepto()}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Asignar Departamento</div>
                        <button onClick={() => handleCloseModalDepto()} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-6">
                    <Select placeholder='--Seleccionar Departamento--' 
                        isLoading={!selectLoaded}
                        options={departamentos} 
                        menuPortalTarget={document.body} 
                        defaultValue={selectedDepto.value === '' ? null : selectedDepto}
                        onChange={(departamento) => setDepartamentoId(departamento.value)} 
                        styles={{ menuPortal: base => ({ 
                            ...base, 
                            zIndex: 9999 
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                            }), 
                        }} 
                    />                   
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={asignarDepto} type='button' hidden={processingRequest} className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Guardar
                        </button>
                        <button onClick={() => handleCloseModalDepto()} hidden={processingRequest} type='button' className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
            {/* Modal Asignar Usuario */}
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModalUsuario}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={handleCloseModalUsuario}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Asignar Usuario</div>
                        <button onClick={handleCloseModalUsuario} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-6">
                    <Select placeholder='--Seleccionar Usuario--' 
                        isLoading={usuarios.length === 0}
                        options={usuarios} 
                        menuPortalTarget={document.body} 
                        defaultValue={selectedUser.value === '' ? null : selectedUser}
                        onChange={(usuario) => setAsignadoId(usuario.value)} 
                        styles={{ menuPortal: base => ({ 
                            ...base, 
                            zIndex: 9999 
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#123CB8' : state.isFocused ? '#DEEBFF' : 'white',
                            }), 
                        }} 
                    />                   
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={asignarUsuario} type='button' hidden={processingRequest} className="bg-accent-1 hover:bg-accent-2 text-white font-bold py-2 px-4 rounded">
                            Guardar
                        </button>
                        <button onClick={handleCloseModalUsuario} hidden={processingRequest} type='button' className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
            {/* Modal Eliminar Peticion */}
            <ReactModal
                closeTimeoutMS={800}
                isOpen={showModal}
                contentLabel="Default Confirmation Modal"
                className='Modal'
                overlayClassName="Overlay"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <div className="flex flex-col items-start">
                    <div className="flex items-center w-full border-b-2">
                        <div className="text-gray-900 font-medium text-xl">Eliminar Petición</div>
                        <button onClick={handleCloseModal} type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-500 hover:text-white">
                            <XIcon className='w-5 h-5' />
                        </button>
                    </div>
                    <div className="border-b-2 w-full py-4">
                        <p>¿Estás seguro de que quieres eliminar esta petición?</p>
                    </div>
                    <div className="flex justify-end w-full mt-4 gap-4">
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-red-500 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button onClick={handleDelete} type='button' hidden={processingRequest} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                            Eliminar
                        </button>
                        <button onClick={handleCloseModal} type='button' hidden={processingRequest} className="bg-gray-500 hover:bg-gray-400 text-white font-semibold py-2 px-4 rounded">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};
export default PeticionesTable;