import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import EstudiantesTable from '../../components/estudiantes/EstudiantesTable';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
var CryptoJS = require("crypto-js");

class EstudiantesPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var _moduloPermissions = [];
        if (_usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'estudiantes') !== undefined) {
            _moduloPermissions.push('create');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'estudiantes') !== undefined) {
            _moduloPermissions.push('update');
        }
        if (_usersAccess.find(ua => ua.permiso_name === 'delete' && ua.modulo_name === 'estudiantes') !== undefined) {
            _moduloPermissions.push('delete');
        }
        this.state = {
            usersAccess: _usersAccess,
            moduloPermissions: _moduloPermissions,
            dataEstudiantes: null,
            filter: 'todos',
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Estudiantes).then((result) => {
            this.setState({ dataEstudiantes: result });
        });
    }

    handleFilterData = (filter) => {
        if (filter === 'todos' || filter === null || filter === undefined || filter === '') {
            return this.state.dataEstudiantes;
        }
        return this.state.dataEstudiantes.filter(e => e.nivel === filter);
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'estudiantes') === undefined) {
            return <Navigate to='/access-denied' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Estudiantes</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                    <>
                        <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                        <span className='text-xs'>/</span>
                    </>}
                    <p className='text-gray-400'>Personal</p>
                    <span className='text-xs'>/</span>
                    <Link to='/estudiantes' className='text-dark-purple font-semibold hover:underline'>Estudiantes</Link>
                </div>
                {/* Data Table And Filter */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex-col justify-center bg-white rounded shadow-lg'>
                        <div className='mx-12 mt-8 mb-8 bg-gray-100 rounded shadow-lg'>
                            <fieldset className="flex flex-col lg:flex-row w-full lg:items-center p-5 space-y-3 lg:space-y-0">
                                <div className="w-1/2">
                                    <h1 className="font-semibold">Filtrar por:</h1>
                                </div>                            
                                <div className='flex flex-col lg:flex-row w-full justify-start lg:gap-8 gap-4'>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioTodos" value="todos" defaultChecked/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioTodos">
                                            Todos
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioPreescolar" value="preescolar"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioPreescolar">
                                            Preescolar
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioPrimaria" value="primaria"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioPrimaria">
                                            Primaria
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioSecundaria" value="secundaria"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioSecundaria">
                                            Secundaria
                                        </label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input onClick={(e) => this.setState({ filter: e.target.value })} className="w-4 h-4 accent-blue-600" type="radio" name="filtrarTables" id="radioPreparatoria" value="preparatoria"/>
                                        <label className="font-semibold text-gray-600" htmlFor="radioPreparatoria">
                                            Preparatoria
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='mx-12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <EstudiantesTable data={this.handleFilterData(this.state.filter)} controls={this.state.moduloPermissions} 
                                    canCreateUser={this.state.usersAccess.find(ua => ua.permiso_name === 'create' && ua.modulo_name === 'usuarios') !== undefined} 
                                    canUpdateUser={this.state.usersAccess.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'usuarios') !== undefined} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EstudiantesPage;