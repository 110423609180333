import { BriefcaseIcon, LockClosedIcon, MailIcon } from '@heroicons/react/outline';
import { React, useState } from 'react';
import { useOutletContext  } from 'react-router-dom';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';

const PerfilPanel = () => {
    // Get, Set Auth user
    const context = useOutletContext();
    const user = context.user;
    const setUser = context.updateUser;
    // Panel Info
    const [foto_perfil, setFotoPerfil] = useState(undefined);
    const [current_password, setCurrentPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [processingRequest, setProcessingRequest] = useState(false);
    const [errors, setErrors] = useState([{current_password: '', password: '', rol_id: '', foto_perfil: ''}]);

    function handleFotoPerfil() {
        const input = document.getElementById('foto_perfil');
        input.click();
    }

    function handleChange(e) {
        setFotoPerfil(e.target.files[0]);
    }

    function onFormSubmit(e) {
        e.preventDefault();
        //Disable controls while request is processed
        setProcessingRequest(true);
        setErrors([{current_password: '', password: '', rol_id: '', foto_perfil: ''}]);

        const data = new FormData();
        data.append('email', user.email);
        data.append('current_password', current_password);
        data.append('new_password', new_password);
        data.append('password_confirmation', password_confirmation);
        data.append('foto_perfil[]', foto_perfil);

        RestClient.PostRequest(AppUrl.UpdateProfile + user.id, data).then((result) => {
            setProcessingRequest(false);
            setErrors(result.data);
            if (result.status === 200) {
                toast.success(result.message);
                setUser('updatedUser');
                setCurrentPassword('')
                setNewPassword('');
                setPasswordConfirmation('');
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }
    return (
        <div className='flex-grow'>
            {/* Panel Body */}
            <div className='p-6 space-y-6'>
                <h2 className='text-2xl tracking-[-.01em] text-slate-800 font-bold mb-6'>Mi Perfil</h2>
                {/* Picture */}
                <section>
                    <div className='flex items-center'>
                        <div className='mr-4'>
                            <img className='w-20 h-20 rounded-full' src={foto_perfil === undefined ? user !== null ? AppUrl.ProfilePics + user.foto_perfil : null : URL.createObjectURL(foto_perfil)} alt="Foto Perfil" />
                        </div>
                        <button type='button' onClick={handleFotoPerfil} className='rounded-md px-2 py-1.5 bg-accent-1 hover:bg-accent-2 text-white text-sm font-medium'>Cambiar</button>
                        <input id='foto_perfil' onChange={handleChange} name='foto_perfil' type='file' className="hidden" accept='.jpg,.jpeg,.png,.gif,.bmp,.webp' />
                    </div>
                    <span className='text-sm text-red-500'>{errors?.foto_perfil}</span>
                </section>
                {/* Account Details */}
                <section className='md:w-11/12'>
                    <h3 className='text-xl tracking-[-.01em] text-slate-800 font-bold mb-1'>Datos de la cuenta</h3>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                        <div className='md:w-1/2'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 pt-2 flex justify-center">
                                    <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input disabled id='txtEmail' defaultValue={user?.email} type="text" name="email" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtEmail" className="absolute left-2 text-base text-gray-400 duration-300 transform -translate-y-9 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9">Correo</label>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-1/2 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-1/12 pt-2 flex justify-center">
                                    <BriefcaseIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input disabled id='txtRole' defaultValue={user?.role} type="text" name="role" className="cursor-not-allowed block py-2.5 px-4 w-full text-sm text-gray-500 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtRole" className="absolute left-2 text-base text-gray-400 duration-300 transform -translate-y-9 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-9">Rol</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Password */}
                <section className='md:w-11/12'>
                    <h3 className='text-xl tracking-[-.01em] text-slate-800 font-bold mb-1'>Cambiar Contraseña</h3>
                    <div className='md:flex md:items-start space-y-8 md:space-x-6 space-x-0 mt-8 w-full'>
                        <div className='md:w-1/3'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => setCurrentPassword(e.target.value)} id='txtCurrentPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtCurrentPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Contraseña Actual</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{errors?.current_password}</span>
                        </div>

                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => setNewPassword(e.target.value)} id='txtNewPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtNewPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nueva Contraseña</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{errors?.password}</span>
                        </div>
                        <div className='md:w-1/3 md:-translate-y-8 -translate-y-0'>
                            <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group">
                                <div className="w-[12%] pt-2 flex justify-center">
                                    <LockClosedIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                </div>
                                <div className="relative z-0 w-full group">
                                    <input onChange={(e) => setPasswordConfirmation(e.target.value)} id='txtConfirmPassword' type="password" name="password" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                    <label htmlFor="txtConfirmPassword" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Confirmar Contraseña</label>
                                </div>
                            </div>
                            <span className='text-sm text-red-500'>{errors?.password}</span>
                        </div>
                    </div>
                </section>
            </div>
            {/* Panel Footer */}
            <footer>
                <div className='flex flex-col px-6 py-5 border-t border-slate-200'>
                    <div className='flex self-end'>
                        <button hidden={!processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </button>
                        <button type='button' onClick={onFormSubmit} hidden={processingRequest} className='text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>Guardar Cambios</button>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default PerfilPanel