import React, { useCallback, useMemo, useState } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

const RespuestaComponent = ({ value }) => {
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const [editor] = useState(() => withReact(createEditor()));

    const initialValue = useMemo(
        () =>
        value ? JSON.parse(value) : [
                {
                    type: 'paragraph',
                    children: [{ text: '' }],
                },
            ]
    , [value]);
    return (
        <Slate
            editor={editor}
            value={initialValue}>
            <Editable
                readOnly
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                className='text-gray-800'
            />
        </Slate>
    )
}

const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote className='border-l-2 pl-[10px] italic text-gray-400 font-normal' style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul className='list-disc' style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 className='text-[32px] font-extrabold' style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 className='text-2xl font-extrabold' style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li className='list-item ml-8' style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol className='list-decimal' style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

export default RespuestaComponent