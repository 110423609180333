import { BellIcon, CogIcon, UserCircleIcon, UserIcon } from '@heroicons/react/outline';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import ConfiguracionesPanel from '../../components/perfil/ConfiguracionesPanel';
import DatosPanel from '../../components/perfil/DatosPanel';
import PerfilPanel from '../../components/perfil/PerfilPanel';

const PerfilPage = (props) => {
    // Get Auth user
    const user = useOutletContext().user;
    // Use Params
    const config = useParams();
    // Panel Info
    const [activePanel, setActivePanel] = useState('Perfil');

    useEffect(() => {
      if (config && Object.keys(config).length > 0) {
        setActivePanel('Configuraciones');
      }
    }, [config])
    

    function renderPanel() {
        switch (activePanel) {
            case 'Perfil':
                return <PerfilPanel />
            case 'Datos':
                return <DatosPanel id={user.user_type_id} user_type={user.user_type} />
            case 'Configuraciones':
                return <ConfiguracionesPanel id={user.id} />
            default:
                return <PerfilPanel />
        }
    }

    return (
        <div className={`w-auto min-h-screen ${props.sidebar ? 'xl:ml-80 lg:ml-72 md:ml-64 sm:ml-60' : 'sm:ml-20 ml-8'} duration-300 p-12 sm:pt-24 pt-20 sm:pr-16 pr-0 bg-gray-100`}>
            {/* Page Title */}
            <div className='sm:px-0 px-3'>
                <div className='flex w-full'>
                    <h1 className='sm:text-xl text-lg font-medium'>Mi Perfil</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 sm:mt-2 space-x-1'>
                    <Link to='/perfil' className='text-dark-purple font-semibold hover:underline'>Perfil</Link>
                </div>
            </div>
            {/* Main Section */}
            <div className='w-full flex justify-center sm:mt-6 mt-3'>
                <div className='w-full bg-white rounded shadow-lg mb-8'>
                    <div className='flex flex-col md:flex-row md:-mr-px'>
                        {/* Sidebar */}
                        <div className='flex flex-nowrap overflow-x-scroll md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 min-w-[15rem] space-y-3'>
                            <div>
                                <ul className='flex flex-nowrap md:block mr-3 md:mr-0 md:space-y-2'>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <button type='button' disabled={user === null} onClick={() => setActivePanel('Perfil')} className={`hover:bg-blue-50 focus:outline-accent-1 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Perfil' && 'bg-blue-50'} group`}>
                                            <UserCircleIcon className={`group-hover:text-accent-1 w-5 h-5 flex-shrink-0 ${activePanel === 'Perfil' ? 'text-accent-1' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-1 text-sm font-medium ${activePanel === 'Perfil' ? 'text-accent-1' : 'text-slate-600'}`}>Mi Perfil</span>
                                        </button>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <button type='button' disabled={user === null} onClick={() => setActivePanel('Datos')} className={`hover:bg-blue-50 focus:outline-accent-1 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Datos' && 'bg-blue-50'} group`}>
                                            <UserIcon className={`group-hover:text-accent-1 w-5 h-5 flex-shrink-0 ${activePanel === 'Datos' ? 'text-accent-1' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-1 text-sm font-medium ${activePanel === 'Datos' ? 'text-accent-1' : 'text-slate-600'}`}>Datos Personales</span>
                                        </button>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <button type='button' disabled={user === null} onClick={() => setActivePanel('Configuraciones')} className={`hover:bg-blue-50 focus:outline-accent-1 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Configuraciones' && 'bg-blue-50'} group`}>
                                            <CogIcon className={`group-hover:text-accent-1 w-5 h-5 flex-shrink-0 ${activePanel === 'Configuraciones' ? 'text-accent-1' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-1 text-sm font-medium ${activePanel === 'Configuraciones' ? 'text-accent-1' : 'text-slate-600'}`}>Configuraciones</span>
                                        </button>
                                    </li>
                                    <li className='mr-0.5 md:mr-0 md:mb-0.5'>
                                        <Link to={'/notificaciones'} disabled={user === null} onClick={() => setActivePanel('Notificaciones')} className={`hover:bg-blue-50 focus:outline-accent-1 flex w-full items-center px-2.5 py-2 rounded whitespace-nowrap ${activePanel === 'Notificaciones' && 'bg-blue-50'} group`}>
                                            <BellIcon className={`group-hover:text-accent-1 w-5 h-5 flex-shrink-0 ${activePanel === 'Notificaciones' ? 'text-accent-1' : 'text-slate-400'} mr-2`} />
                                            <span className={`group-hover:text-accent-1 text-sm font-medium ${activePanel === 'Notificaciones' ? 'text-accent-1' : 'text-slate-600'}`}>Mis Notificaciones</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Panel */}
                        {!user ? <div className='flex w-full justify-center bg-white'>
                            <Loading />
                        </div> :
                        renderPanel()}
                    </div>
                </div>
            </div>
        </div>
    );


}

export default PerfilPage;