import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import AppUrl from '../../RestAPI/AppUrl';
import RestClient from '../../RestAPI/RestClient';
import { toast } from 'react-toastify';
import { BellIcon, DocumentTextIcon, MailIcon, UsersIcon } from '@heroicons/react/outline';
import Select from 'react-select';
import TextEditorInput from '../../components/form-controls/TextEditorInput';
import { Node } from 'slate';
import { colorAccent2 } from '../../components/CustomTableComponents';
var CryptoJS = require("crypto-js");

const selectStyle = {
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? colorAccent2 : state.isFocused ? '#DEEBFF' : 'white',
    }),
}

class CrearNotificacionCorreoPage extends Component {

    constructor() {
        super();
        /// Decript permissions
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem('usersAccess'), '3K6wpQQ6FubCmhTvXPS4CZ0CRCuvV6hu');
        var _usersAccess = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.state = {
            usersAccess: _usersAccess,
            nombre: '', descripcion: '', selectedUsers: [], asunto: '', mensaje: '', characterCount: 0,
            statusResult: 0, processingRequest: false, errors: [{ nombre: '', descripcion: '', selectedUsers: '', asunto: '', mensaje: '' }],
            usuarios: []
        }
    }

    componentDidMount() {
        RestClient.GetRequest(AppUrl.Administrativos).then((result) => {
            var groupedOptions = [];
            result.filter(a => a.usuario_id !== null).forEach(element => {
                var depto = element.depto;
                if (!groupedOptions.some(o => o.label === depto)) {
                    groupedOptions.push({ label: depto, options: [{ value: element.usuario_id, label: element.nombre + ' ' + element.apellido }] });
                }
                else {
                    groupedOptions.find(o => o.label === depto).options.push({ value: element.usuario_id, label: element.nombre + ' ' + element.apellido });
                }
            });
            this.setState({ usuarios: groupedOptions });
        });
    }

    handleMensaje = (value) => {
        let descripcionPlainText = '';
        let plainTextConcat = "";
        let plainTextArray = JSON.parse(value).map(n => Node.string(n));

        if (plainTextArray.length > 1) {
            plainTextArray.forEach(element => {
                plainTextConcat += element;
            });
            descripcionPlainText += plainTextConcat;
        } else {
            descripcionPlainText += plainTextArray[0];
        }
        this.setState({ mensaje: value, characterCount: descripcionPlainText.length });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        //Disable controls while request is processed and clear error messages
        this.setState({ processingRequest: true, errors: [{ nombre: '', descripcion: '', selectedUsers: '', asunto: '', mensaje: '' }] });

        const data = new FormData();
        data.append('nombre', this.state.nombre);
        data.append('descripcion', this.state.descripcion);
        data.append('selectedUsers', JSON.stringify(this.state.selectedUsers));
        data.append('asunto', this.state.asunto);
        data.append('mensaje', this.state.mensaje);

        RestClient.PostRequest(AppUrl.CreateNotificacionCorreo, data).then((result) => {
            this.setState({ processingRequest: false, statusResult: result.status, errors: result.data });
            if (result.status === 200) {
                toast.success(result.message);
            }
            else {
                console.log(result);
                toast.error(result.message);
            }
        });
    }

    render() {
        if (this.state.usersAccess.find(ua => ua.modulo_name === 'notificaciones correo' && ua.permiso_name === 'create') === undefined) {
            return <Navigate to='/access-denied' />
        }
        if (this.state.statusResult === 200) {
            return <Navigate to='/notificaciones-correo' />
        }
        return (
            <div className={`w-auto min-h-screen ${this.props.sidebar ? 'ml-80' : 'ml-20'} duration-300 p-12 pt-24 pr-16 bg-gray-100`}>
                {/* Page Title */}
                <div className='flex w-full'>
                    <h1 className='text-xl font-medium'>Crear Notificación por Correo</h1>
                </div>
                {/* Breadcrum Nav */}
                <div className='flex w-full text-sm text-gray-500 mt-2 space-x-1'>
                    {this.state.usersAccess.find(ua => ua.permiso_name === 'read' && ua.modulo_name === 'dashboard') !== undefined &&
                        <>
                            <Link to='/' className='font-semibold hover:underline'>Dashboard</Link>
                            <span className='text-xs'>/</span>
                        </>}
                    <p className='text-gray-400'>Automatización</p>
                    <span className='text-xs'>/</span>
                    <Link to='/notificaciones-correo' className='font-semibold hover:underline'>Notificaciones Correo</Link>
                    <span className='text-xs'>/</span>
                    <p className='text-dark-purple font-semibold'>Crear Notificación Correo</p>
                </div>
                {/* Main Section */}
                <div className='w-full flex justify-center mt-6'>
                    <div className='w-full flex justify-center bg-white rounded shadow-lg'>
                        <div className='w-11/12 mt-8 mb-8'>
                            <div className='w-full bg-gray-100 rounded-lg shadow-lg'>
                                <div className='bg-gray-50'>
                                    <div className='bg-white border'>
                                        <h1 className='text-accent-1 text-2xl p-4'>Registrar Notificación por Correo</h1>
                                    </div>
                                    <form onSubmit={this.onFormSubmit} className='mt-4'>
                                        <div className="md:inline-flex space-y-4 md:space-y-0 w-full p-4 text-gray-500 items-center mb-4">
                                            <h2 className="md:w-1/3 mx-auto max-w-sm text-accent-1 md:mb-0 mb-8">Información Notificación</h2>
                                            <div className="md:w-2/3 mx-auto max-w-sm space-y-8">
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group bg-white">
                                                        <div className="w-1/12 pt-2 flex justify-center">
                                                            <BellIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group">
                                                            <input onChange={(e) => this.setState({ nombre: e.target.value })} id='txtNombre' type="text" name="nombre" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                            <label htmlFor="txtNombre" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Nombre</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.nombre}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group bg-white">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <DocumentTextIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group flex items-center">
                                                            <div className='w-full border-l-2 border-gray-400 focus-within:border-accent-1 peer'>
                                                                <textarea onChange={(e) => this.setState({ descripcion: e.target.value })} id='taDescripcion' name="descripcion" className="block px-4 my-2 w-full text-sm text-gray-900 bg-transparent appearance-none focus:outline-none focus:ring-0 peer" placeholder=" " />
                                                            </div>
                                                            <label htmlFor="taDescripcion" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Descripción (Opcional)</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.descripcion}</span>
                                                </div>
                                                <div className=''>
                                                    <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group bg-white">
                                                        <div className="w-1/12 items-center flex justify-center">
                                                            <UsersIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                        </div>
                                                        <div className="relative z-0 w-full group flex items-center">
                                                            <Select placeholder='--Seleccionar Usuario(s)--'
                                                                id='selectUsuarios'
                                                                isMulti
                                                                options={this.state.usuarios}
                                                                menuPortalTarget={document.body}
                                                                className='p-1 w-full'
                                                                onChange={(usuario) => this.setState({ selectedUsers: usuario })}
                                                                isLoading={this.state.usuarios.length === 0}
                                                                styles={{
                                                                    ...selectStyle,
                                                                    menuPortal: base => ({
                                                                        ...base,
                                                                        zIndex: 9999
                                                                    }),
                                                                }}
                                                            />
                                                            <label htmlFor="selectUsuarios" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Notificar A</label>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm text-red-500'>{this.state.errors.selectedUsers}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <hr />
                                        <div className="md:flex-col space-y-4 w-full p-8 md:px-20  text-gray-500 items-center mb-4">
                                            <h5 className='text-accent-1 mb-8 max-w-sm'>Información Correo</h5>
                                            <div className=''>
                                                <div className="w-full inline-flex border-2 rounded border-gray-400 focus-within:border-accent-1 group bg-white">
                                                    <div className="w-1/12 pt-2 flex justify-center">
                                                        <MailIcon className='h-6 w-6 text-gray-400 group-focus-within:text-accent-1' />
                                                    </div>
                                                    <div className="relative z-0 w-full group">
                                                        <input onChange={(e) => this.setState({ asunto: e.target.value })} id='txtAsunto' type="text" name="asunto" className="block py-2.5 px-4 w-full text-sm text-gray-900 bg-transparent border-l-2 appearance-none border-gray-400 focus:outline-none focus:ring-0 focus:border-accent-1 peer" placeholder=" " required />
                                                        <label htmlFor="txtAsunto" className="absolute left-2 text-sm text-gray-400 duration-300 transform -translate-y-8 scale-75 top-2.5 -z-10 origin-[0] peer-focus:left-2 peer-focus:text-accent-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">Asunto</label>
                                                    </div>
                                                </div>
                                                <span className='text-sm text-red-500'>{this.state.errors.asunto}</span>
                                            </div>
                                            <div className=''>
                                                <h5 className='mb-2 text-sm text-gray-400 peer-focus-within:text-accent-1'>Mensaje</h5>
                                                <div className='bg-white px-4 py-2 border-2 rounded border-gray-400 focus-within:border-accent-1 peer'>
                                                    <TextEditorInput defaultValue={this.state.mensaje} placeholder='El mensaje que se enviara por correo...' handleDescripcion={(descripcionValue) => this.handleMensaje(descripcionValue)} />
                                                </div>
                                                <p className={`flex w-full justify-end text-xs ${this.state.characterCount > 500 && 'text-red-500 font-semibold'} text-gray-400`}>{this.state.characterCount}/500</p>
                                                <span hidden={this.state.characterCount <= 500} className='text-sm text-red-500'>¡Estas sobre el límite de caracteres permitidos!</span>
                                                <span className='text-sm text-red-500'>{this.state.errors.mensaje}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-4 flex justify-center gap-4 bg-white'>
                                            <button hidden={!this.state.processingRequest} type='button' disabled className='text-white bg-accent-2 rounded-lg w-full sm:w-auto px-5 py-2.5 text-center'>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </button>
                                            <button type="submit" disabled={this.state.characterCount > 500} hidden={this.state.processingRequest} className="disabled:bg-accent-2/70 text-white bg-accent-1 hover:bg-accent-2 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Guardar</button>
                                            <Link to='/notificaciones-correo' hidden={this.state.processingRequest} className="text-white bg-gray-500 hover:bg-gray-400 focus:ring-4 focus:ring-accent-2 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearNotificacionCorreoPage;