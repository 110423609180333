import React, { useEffect, useState } from 'react';

let overlay = document.getElementById("overlay");

// use to check if a file is being dragged
const hasFiles = ({ dataTransfer: { types = [] } }) =>
    types.indexOf("Files") > -1;

// use for dragenter and dragleave events.
// this is to know if the outermost parent is dragged over
// without issues due to drag events on its children
let counter = 0;

// use to store pre selected files
let FILES = {};

// Check if we are on a mobile device
let mobileDevice = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    mobileDevice = true;
} else {
    // false for not mobile device
    mobileDevice = false;
}

const ArchivosAdjuntosInput = (props) => {

    const [adjuntos, setAdjuntos] = useState([]);

    useEffect(() => {
        overlay = document.getElementById("overlay");
        FILES = {};
    }, []);

    // check if file is of type image and prepend the initialied
    // template to the target element
    function addFile(file) {
        const isImage = file.type.match("image.*"),
            objectURL = URL.createObjectURL(file);

        const clone = {
            fileName: file.name,
            id: objectURL,
            size: file.size > 1024
                ? file.size > 1048576
                    ? Math.round(file.size / 1048576) + "mb"
                    : Math.round(file.size / 1024) + "kb"
                : file.size + "b",
            isImage: isImage,
        }

        FILES[objectURL] = file;

        setAdjuntos(prevState => [...prevState, clone]);
        props.handleFiles(FILES);
    }

    function handleUploadFileButton() {
        // click the hidden input of type file if the visible button is clicked
        // and capture the selected files
        const hidden = document.getElementById("hidden-input");
        hidden.click();
        hidden.onchange = (e) => {
            for (const file of e.target.files) {
                addFile(file);
            }
        };
    }

    // reset counter and append file to gallery when file is dropped
    function dropHandler(ev) {
        ev.preventDefault();
        for (const file of ev.dataTransfer.files) {
            addFile(file);
            overlay.classList.remove("draggedover");
            counter = 0;
        }
    }

    // only react to actual files being dragged
    function dragEnterHandler(e) {
        e.preventDefault();
        if (!hasFiles(e)) {
            return;
        }
        ++counter && overlay.classList.add("draggedover");
    }

    function dragLeaveHandler(e) {
        1 > --counter && overlay.classList.remove("draggedover");
    }

    function dragOverHandler(e) {
        if (hasFiles(e)) {
            e.preventDefault();
        }
    }

    // event delegation to capture delete events
    // from the waste buckets in the file preview cards
    function handleDelete(btn) {
        const li = btn.dataset.target;
        delete FILES[li];
        setAdjuntos(adjuntos.filter(function (adj) {
            return adj.id !== btn.dataset.target;
        }));
        props.handleFiles(FILES);
    }



    return (
        /* <!-- component --> */
        <div className="">
            <main className="container mx-auto max-w-screen-lg h-full">
                {/* <!-- file upload modal --> */}
                <article aria-label="File Upload Modal" className="relative h-full flex flex-col bg-white shadow-lg rounded-md" onDrop={(e) => dropHandler(e)} onDragOver={(e) => dragOverHandler(e)} onDragLeave={(e) => dragLeaveHandler(e)} onDragEnter={(e) => dragEnterHandler(e)}>
                    {/* <!-- overlay --> */}
                    <div id="overlay" className="w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md">
                        <i>
                            <svg className="fill-current w-12 h-12 mb-3 text-blue-700" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                            </svg>
                        </i>
                        <p className="text-lg text-blue-700">Suelta archivo para subir</p>
                    </div>

                    {/* <!-- scroll area --> */}
                    <section className="h-full overflow-hidden p-8 w-full flex flex-col">
                        <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
                            <p className="mb-3 font-semibold text-gray-500 flex flex-wrap justify-center">
                                <span>Arrastra y suelta tus</span>&nbsp;<span>archivos aquí o</span>
                            </p>
                            <input id="hidden-input" type="file" multiple className="hidden" />
                            <button id="button" onClick={() => handleUploadFileButton()} type='button' className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
                                Subir un Archivo
                            </button>
                        </header>

                        <h1 hidden={adjuntos.length < 1} className="pt-8 pb-3 font-semibold text-lg text-gray-900">
                            Adjuntos
                        </h1>
                        <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
                            {adjuntos.map((adj, index) => (
                                <li key={index} id={adj.id} className="flex p-1 sm:w-1/2 w-full h-20">
                                    <article tabIndex="0" className={`group ${(adj.isImage && window.innerWidth > 640 && !mobileDevice) ? 'hasImage text-transparent hover:text-white' : ((adj.isImage && window.innerWidth <= 640) || (adj.isImage && mobileDevice)) ? 'text-white' : ''} w-full h-full rounded-md focus:outline-none focus:shadow-outline relative bg-gray-100 cursor-pointer shadow-sm`}>
                                        {adj.isImage && <img alt="upload preview" src={adj.id} className="w-full h-full sticky object-cover rounded-md bg-fixed" />}
                                        <section className={`flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3 ${mobileDevice && adj.isImage && 'bg-black/40'}`}>
                                            <h1 className={`flex-1 ${!adj.isImage && 'group-hover:text-blue-800'} text-xs`}>{adj.fileName}</h1>
                                            <div className="flex justify-between">
                                                <p className={`p-1 text-xs ${!adj.isImage && 'text-gray-700'}`}>Tamaño: {adj.size}</p>
                                                <button type='button' onClick={(e) => handleDelete(e.target)} data-target={adj.id} className={`ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md ${!adj.isImage && 'text-gray-800'}`}>
                                                    <svg className="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path className="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </section>
                                    </article>
                                </li>
                            ))}
                        </ul>
                    </section>
                </article>
            </main>
        </div>
    );
}

export default ArchivosAdjuntosInput